import { isFileBlob } from '@/utils'
import { FieldValidator } from 'formik'
import { IntlShape } from 'react-intl'
import { SafeParseReturnType, z } from 'zod'
import { asOptionalField } from '../validators.utils'
import { isAcceptedFileSize, isAcceptedFileType, isEmptyFileSize } from './file.validator.utils'
import { FormikInputConfiguration } from '@/types'

/**
 * Validate a file and return a list of errors
 * @returns stringified object with a list of errors
 */
export function fileValidator(
    intl: IntlShape,
    configuration?: FormikInputConfiguration,
    options?: any
): FieldValidator {
    const FileSchema = z.any().superRefine((value, ctx) => {
        const acceptedExtensions = options?.formattedAcceptedExtensions
        const acceptedSizeInMB = options?.formattedAcceptedSize

        switch (true) {
            case !isFileBlob(value): {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage({ id: 'app.common.form.validation.required' }),
                    params: {
                        code: z.ZodIssueCode.invalid_type,
                        acceptedSizeInMB,
                        acceptedExtensions
                    }
                })
                break
            }

            case isEmptyFileSize(value?.size): {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage({ id: 'app.common.form.validation.file.too_small' }),
                    params: {
                        code: z.ZodIssueCode.too_small,
                        acceptedSizeInMB,
                        acceptedExtensions
                    }
                })
                break
            }

            case !isAcceptedFileType(options.acceptedExtensions, value?.type): {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage({ id: 'app.common.form.validation.file.invalid_type' }),
                    params: {
                        code: z.ZodIssueCode.invalid_type,
                        acceptedSizeInMB,
                        acceptedExtensions
                    }
                })

                break
            }

            case !isAcceptedFileSize(options.acceptedSize, value?.size): {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage({ id: 'app.common.form.validation.file.too_big' }),
                    params: {
                        code: z.ZodIssueCode.too_big,
                        acceptedSizeInMB,
                        acceptedExtensions
                    }
                })

                break
            }
        }
    })

    return (value: File) => {
        let validationResult: SafeParseReturnType<typeof value, void>

        if (configuration?.isRequired) {
            validationResult = FileSchema.safeParse(value)
        } else {
            validationResult = asOptionalField(FileSchema).safeParse(value)
        }

        if (!validationResult.success) {
            return validationResult.error.message
        }
    }
}
