import {
    EntityDetailCellRelatedObjectsLinks,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers
} from '@/components'
import {
    BankCode,
    ConnectedAccountName,
    Direction,
    FormattedAmount,
    FormattedDate,
    FormattedDateTime,
    StatusLabel
} from '@/components/@misc'
import { Claim } from '@webapps/numeral-ui-core'
import { ClaimDetailsCustomRows } from './ClaimDetails.types'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { get } from 'lodash'
import { accountNumberFormatter, whitespaceFormatter } from '@/utils/@formatters'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'

export const CLAIM_DETAILS_ROWS: EntityDetailRow<Claim, ClaimDetailsCustomRows>[] = [
    'id',
    'created_at',
    'value_date',
    'type',
    'status',
    'connected_account_id',
    'related_objects',
    'related_payment_details',
    'metadata',
    'bank_data'
]

export const CLAIM_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<Claim, ClaimDetailsCustomRows> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    value_date: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    type: {
        cell(item) {
            return <FormattedMessage id={`api.claim.type.${item.type}`} />
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    related_objects: {
        cell(item) {
            return (
                <EntityDetailCellRelatedObjectsLinks
                    relatedClaimId={item.id}
                    relatedPaymentId={item.related_payment_id}
                    relatedPaymentType={item.related_payment_type}
                    relatedFileId={item.bank_data?.file_id}
                    showRelatedPaymentLink={true}
                    showRelatedReturnRequestsLink={true}
                    showRelatedInquiriesLink={true}
                />
            )
        }
    },
    related_payment_details: {
        nested: true,
        rows: [
            'related_payment.value_date',
            'related_payment.amount',
            'related_payment.direction',
            'related_payment.reference',

            'related_payment.originating_account.account_number',
            'related_payment.originating_account.holder_name',
            'related_payment.originating_account.bank_code',

            'related_payment.receiving_account.account_number',
            'related_payment.receiving_account.holder_name',
            'related_payment.receiving_account.bank_code',

            'related_payment.bank_data.message_id',
            'related_payment.bank_data.end_to_end_id',
            'related_payment.bank_data.transaction_id'
        ],
        rowCustomizers: {
            'related_payment.value_date': {
                label() {
                    return <FormattedMessage id="app.page.details.fields.value_date.label" />
                },
                cell(item) {
                    const value = get(item, 'related_payment.value_date')
                    return <FormattedDate value={value} />
                }
            },
            'related_payment.amount': {
                label() {
                    return <FormattedMessage id="app.page.details.fields.amount.label" />
                },
                cell(item) {
                    const amount = get(item, 'related_payment.amount')
                    const currency = get(item, 'related_payment.currency')

                    return <FormattedAmount amount={amount} currency={currency} />
                }
            },
            'related_payment.direction': {
                label() {
                    return <FormattedMessage id="app.page.details.fields.direction.label" />
                },
                cell(item) {
                    const direction = get(item, 'related_payment.direction')
                    return <Direction value={direction} />
                }
            },
            'related_payment.reference': {
                label() {
                    return <FormattedMessage id="app.page.details.fields.reference.label" />
                },
                cell(item) {
                    const reference = get(item, 'related_payment.reference', EMPTY_VALUE_PLACEHOLDER)
                    return <>{reference}</>
                }
            },
            //Originating account
            'related_payment.originating_account.account_number': {
                label() {
                    return <FormattedMessage id="app.page.details.fields.originating_account.account_number.label" />
                },
                cell(item) {
                    const accountNumber = get(item, 'related_payment.originating_account.account_number')

                    const formattedValue = accountNumberFormatter(accountNumber)
                    return <EntityDetailCellTextWithCopyButton value={formattedValue} formatter={whitespaceFormatter} />
                }
            },
            'related_payment.originating_account.holder_name': {
                label() {
                    return <FormattedMessage id="app.page.details.fields.originating_account.holder_name.label" />
                },
                cell(item) {
                    const holderName = get(
                        item,
                        'related_payment.originating_account.holder_name',
                        EMPTY_VALUE_PLACEHOLDER
                    )

                    return <>{holderName}</>
                }
            },
            'related_payment.originating_account.bank_code': {
                label() {
                    return <FormattedMessage id="app.page.details.fields.originating_account.bank_code.label" />
                },
                cell(item) {
                    const bankCode = get(item, 'related_payment.originating_account.bank_code')

                    return <BankCode value={bankCode} />
                }
            },
            //Receiving account
            'related_payment.receiving_account.account_number': {
                label() {
                    return <FormattedMessage id="app.page.details.fields.receiving_account.account_number.label" />
                },
                cell(item) {
                    const accountNumber = get(item, 'related_payment.receiving_account.account_number')

                    const formattedValue = accountNumberFormatter(accountNumber)
                    return <EntityDetailCellTextWithCopyButton value={formattedValue} formatter={whitespaceFormatter} />
                }
            },
            'related_payment.receiving_account.holder_name': {
                label() {
                    return <FormattedMessage id="app.page.details.fields.receiving_account.holder_name.label" />
                },
                cell(item) {
                    const holderName = get(
                        item,
                        'related_payment.receiving_account.holder_name',
                        EMPTY_VALUE_PLACEHOLDER
                    )

                    return <>{holderName}</>
                }
            },
            'related_payment.receiving_account.bank_code': {
                label() {
                    return <FormattedMessage id="app.page.details.fields.receiving_account.bank_code.label" />
                },
                cell(item) {
                    const bankCode = get(item, 'related_payment.receiving_account.bank_code')

                    return <BankCode value={bankCode} />
                }
            },
            'related_payment.bank_data.message_id': {
                cell(item) {
                    const endToEndId = get(item, 'related_payment.bank_data.message_id', EMPTY_VALUE_PLACEHOLDER)

                    return <>{endToEndId}</>
                }
            },
            'related_payment.bank_data.end_to_end_id': {
                cell(item) {
                    const endToEndId = get(item, 'related_payment.bank_data.end_to_end_id', EMPTY_VALUE_PLACEHOLDER)

                    return <>{endToEndId}</>
                }
            },
            'related_payment.bank_data.transaction_id': {
                cell(item) {
                    const transactionId = get(item, 'related_payment.bank_data.transaction_id', EMPTY_VALUE_PLACEHOLDER)

                    return <>{transactionId}</>
                }
            }
        }
    },
    metadata: {
        nested: true
    },
    bank_data: {
        nested: true
    }
}
