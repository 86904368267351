import { DetailPageLink, LinkWithFilterBy } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import {
    useNavigationRoutes,
    useQueryFindAllClaims,
    useQueryFindAllInquiries,
    useQueryFindAllReturnRequests,
    useQueryFindAllReturns
} from '@/hooks'
import { queryDataAggregation } from '@/utils'
import { Box, Stack } from '@chakra-ui/react'
import {
    ApiObjectTypeSchema,
    Claim,
    ClaimsServiceFindAllQueryOptions,
    InquiriesServiceFindAllQueryOptions,
    Inquiry,
    PaymentType,
    Return,
    ReturnRequest,
    ReturnRequestsServiceFindAllQueryOptions,
    ReturnsServiceFindAllQueryOptions,
    Uuid
} from '@webapps/numeral-ui-core'
import { ReactNode, useMemo } from 'react'
import { Nullable } from '@/types'

interface EntityDetailCellRelatedObjectsLinksProps {
    relatedAccountHolderId?: Nullable<Uuid>
    relatedInternalAccountId?: Nullable<Uuid>
    relatedExternalAccountId?: Nullable<Uuid>
    relatedPaymentId?: Nullable<Uuid>
    relatedPaymentType?: Nullable<PaymentType>
    relatedFileId?: Nullable<Uuid>
    relatedPaymentFileId?: Nullable<Uuid>
    relatedClaimId?: Nullable<Uuid>

    showRelatedPaymentLink?: boolean
    showRelatedReturnRequestsLink?: boolean
    showRelatedReturnsLink?: boolean
    showRelatedClaimsLink?: boolean
    showRelatedClaimLink?: boolean
    showRelatedInquiriesLink?: boolean
}

/**
 * @description Display a list of related object link
 *
 * @todo Once the API provide the related objects, workaround API calls done in the component should be removed
 * and relatedId should come directly from the props, removing as well the need for 'show..' props
 */
export const EntityDetailCellRelatedObjectsLinks: React.FC<EntityDetailCellRelatedObjectsLinksProps> = ({
    relatedAccountHolderId,
    relatedInternalAccountId,
    relatedExternalAccountId,
    relatedPaymentId,
    relatedPaymentType,
    relatedFileId,
    relatedPaymentFileId,
    relatedClaimId,

    showRelatedPaymentLink = false,
    showRelatedReturnRequestsLink = false,
    showRelatedReturnsLink = false,
    showRelatedClaimsLink = false,
    showRelatedClaimLink = false,
    showRelatedInquiriesLink = false
}) => {
    const { paths } = useNavigationRoutes()
    const inquiriesQuery = useQueryFindAllInquiries(
        { related_request_id: relatedClaimId as Uuid },
        {
            enabled: globalThis.Boolean(showRelatedInquiriesLink && relatedClaimId)
        }
    )
    const claimsQuery = useQueryFindAllClaims(
        { related_payment_id: relatedPaymentId as Uuid },
        {
            enabled: globalThis.Boolean(showRelatedClaimsLink && relatedPaymentId)
        }
    )
    const claimsData = useMemo(() => queryDataAggregation<Claim>(claimsQuery?.data), [claimsQuery])
    const returnRequestsQuery = useQueryFindAllReturnRequests(
        { related_payment_id: relatedPaymentId as Uuid },
        {
            enabled: globalThis.Boolean(showRelatedReturnRequestsLink && relatedPaymentId)
        }
    )
    const returnRequestsData = useMemo(
        () => queryDataAggregation<ReturnRequest>(returnRequestsQuery?.data),
        [returnRequestsQuery]
    )

    const returnsQuery = useQueryFindAllReturns(
        { related_payment_id: relatedPaymentId as Uuid },
        {
            enabled: globalThis.Boolean(showRelatedReturnsLink && relatedPaymentId)
        }
    )
    const inquiriesData = useMemo(() => queryDataAggregation<Inquiry>(inquiriesQuery?.data), [inquiriesQuery])
    const returnsData = useMemo(() => queryDataAggregation<Return>(returnsQuery?.data), [returnsQuery])

    const relatedLinks = useMemo<ReactNode>(() => {
        const willShowRelatedAccountHolderLink = !!relatedAccountHolderId
        const willShowRelatedInternalAccountLink = !!relatedInternalAccountId
        const willShowRelatedExternalAccountLink = !!relatedExternalAccountId
        const willShowRelatedFileLink = !!relatedFileId
        const willShowRelatedPaymentFileLink = !!relatedPaymentFileId
        const willShowRelatedPaymentLink = showRelatedPaymentLink && !!relatedPaymentId && !!relatedPaymentType
        const willShowRelatedReturnRequestsLink = showRelatedReturnRequestsLink && !!returnRequestsData.length
        const willShowRelatedReturnsLink = showRelatedReturnsLink && !!returnsData.length
        const willShowRelatedClaimsLink = showRelatedClaimsLink && !!claimsData.length
        const willShowRelatedClaimLink = showRelatedClaimLink && !!relatedClaimId
        const willShowRelatedInquiriesLink = showRelatedInquiriesLink && !!inquiriesData.length

        if (
            !willShowRelatedAccountHolderLink &&
            !willShowRelatedInternalAccountLink &&
            !willShowRelatedExternalAccountLink &&
            !willShowRelatedPaymentLink &&
            !willShowRelatedReturnRequestsLink &&
            !willShowRelatedReturnsLink &&
            !willShowRelatedFileLink &&
            !willShowRelatedPaymentFileLink &&
            !willShowRelatedClaimsLink &&
            !willShowRelatedClaimLink &&
            !willShowRelatedInquiriesLink
        ) {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        const links: Array<ReactNode> = []

        if (willShowRelatedAccountHolderLink) {
            links.push(
                <DetailPageLink
                    objectId={relatedAccountHolderId}
                    objectType={ApiObjectTypeSchema.enum.account_holder}
                />
            )
        }

        if (willShowRelatedInternalAccountLink) {
            links.push(
                <DetailPageLink
                    objectId={relatedInternalAccountId}
                    objectType={ApiObjectTypeSchema.enum.internal_account}
                />
            )
        }

        if (willShowRelatedExternalAccountLink) {
            links.push(
                <DetailPageLink
                    objectId={relatedExternalAccountId}
                    objectType={ApiObjectTypeSchema.enum.external_account}
                />
            )
        }

        if (willShowRelatedPaymentLink) {
            links.push(<DetailPageLink objectId={relatedPaymentId} objectType={relatedPaymentType} />)
        }

        if (willShowRelatedClaimLink) {
            links.push(<DetailPageLink objectId={relatedClaimId} objectType={ApiObjectTypeSchema.enum.claim} />)
        }

        if (willShowRelatedFileLink) {
            links.push(<DetailPageLink objectId={relatedFileId} objectType={ApiObjectTypeSchema.enum.file} />)
        }

        if (willShowRelatedPaymentFileLink) {
            links.push(
                <DetailPageLink
                    customLabelMessageKey="app.page.details.cells.related_objects.payment_file.link"
                    objectId={relatedPaymentFileId}
                    objectType={ApiObjectTypeSchema.enum.payment_file}
                />
            )
        }

        if (willShowRelatedReturnsLink) {
            links.push(
                <LinkWithFilterBy<ReturnsServiceFindAllQueryOptions>
                    labelKey="app.page.details.cells.related_objects.returns.link"
                    path={paths.PAYMENTS.RETURNS}
                    filterBy={{
                        related_payment_id: relatedPaymentId as Uuid
                    }}
                />
            )
        }

        if (willShowRelatedReturnRequestsLink) {
            links.push(
                <LinkWithFilterBy<ReturnRequestsServiceFindAllQueryOptions>
                    labelKey="app.page.details.cells.related_objects.return_requests.link"
                    path={paths.INVESTIGATIONS.RETURN_REQUESTS}
                    filterBy={{
                        related_payment_id: relatedPaymentId as Uuid
                    }}
                />
            )
        }

        if (willShowRelatedClaimsLink) {
            links.push(
                <LinkWithFilterBy<ClaimsServiceFindAllQueryOptions>
                    labelKey="app.page.details.cells.related_objects.claims.link"
                    path={paths.INVESTIGATIONS.CLAIMS}
                    filterBy={{
                        related_payment_id: relatedPaymentId as Uuid
                    }}
                />
            )
        }

        if (willShowRelatedInquiriesLink) {
            links.push(
                <LinkWithFilterBy<InquiriesServiceFindAllQueryOptions>
                    labelKey="app.page.details.cells.related_objects.inquiries.link"
                    path={paths.INVESTIGATIONS.INQUIRIES}
                    filterBy={{
                        related_request_id: relatedClaimId as Uuid
                    }}
                />
            )
        }

        return (
            <Stack gap="8px">
                {links.map((link, index) => (
                    <Box key={index}>{link}</Box>
                ))}
            </Stack>
        )
    }, [
        relatedAccountHolderId,
        relatedInternalAccountId,
        relatedExternalAccountId,
        relatedPaymentId,
        relatedPaymentType,
        relatedFileId,
        relatedPaymentFileId,
        relatedClaimId,

        relatedPaymentType,
        showRelatedPaymentLink,
        showRelatedClaimsLink,
        showRelatedReturnsLink,
        showRelatedReturnRequestsLink,
        showRelatedInquiriesLink,

        returnRequestsData.length,
        returnsData.length,
        claimsData.length,
        inquiriesData.length,
        paths
    ])

    return <>{relatedLinks}</>
}
