import { Form, FormPageFooter, InputWithCustomComponent } from '@/components'
import { CopyToClipboardButton, FormattedTextIntl, StatusLabel } from '@/components/@misc'
import { InputWithCopyToClipboard } from '@/components/Form/@components'
import { useNavigateWithLegalEntityID, useNavigationRoutes } from '@/hooks'
import { FormLabelProps } from '@chakra-ui/form-control'
import { Flex, FormControl, FormErrorMessage, FormLabel, InputProps, Textarea } from '@chakra-ui/react'
import { NumeralEvent, prettyPrintObject } from '@webapps/numeral-ui-core'
import { Field, FormikValues } from 'formik'
import { useCallback, useMemo, useRef } from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import { getEventFormInitialValues, getEventFormValidationSchemaWithIntl } from './Event.form.utils'

interface EventFormProps {
    data?: NumeralEvent
    disabled?: boolean
    readonly?: boolean
}

export const EventForm: React.FC<EventFormProps> = ({ data, readonly }) => {
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths } = useNavigationRoutes()
    const initialValues = useRef<NumeralEvent>(getEventFormInitialValues(data) as NumeralEvent).current
    const validationSchemaWithIntl = useMemo(() => {
        return getEventFormValidationSchemaWithIntl(intl)
    }, [intl])
    const onFormSubmit = useCallback(async (values: FormikValues) => {
        globalThis.console.log('EventForm', prettyPrintObject(values))
    }, [])
    const onFormCancel = useCallback(() => {
        navigateWithLegalEntityID(paths.DEVELOPERS.EVENTS)
    }, [navigateWithLegalEntityID, paths])
    const propsInputCommon = useMemo<InputProps | undefined>(() => {
        if (!readonly) {
            return
        }

        return {
            variant: 'flushed',
            readOnly: true,
            focusBorderColor: 'none'
        }
    }, [readonly])
    const propsFormLabelCommon = useMemo<FormLabelProps | undefined>(() => {
        if (!readonly) {
            return
        }

        return {
            variant: 'readonly'
        }
    }, [readonly])
    const prettifiedData = useRef<string>(prettyPrintObject(data)).current

    return (
        <Form<NumeralEvent>
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validationSchema={validationSchemaWithIntl}>
            {({ values, errors, touched, handleSubmit, resetForm, dirty }) => (
                <>
                    <FormControl isInvalid={!!errors.id && !!touched.id}>
                        <FormLabel htmlFor="id" {...propsFormLabelCommon}>
                            <FormattedTextIntl messageId={'app.developers.events.form.id.label'} />
                        </FormLabel>
                        <Field as={InputWithCopyToClipboard} id="id" name="id" {...propsInputCommon} />
                        <FormErrorMessage>{errors.id}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.created_at && !!touched.created_at}>
                        <FormLabel htmlFor="created_at" {...propsFormLabelCommon}>
                            <FormattedTextIntl messageId={'app.developers.events.form.created_at.label'} />
                        </FormLabel>
                        <Field as={InputWithCustomComponent} id="created_at" name="created_at" {...propsInputCommon}>
                            <FormattedDate value={values.created_at} />
                        </Field>
                        <FormErrorMessage>{errors.created_at}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.topic && !!touched.topic}>
                        <FormLabel htmlFor="topic" {...propsFormLabelCommon}>
                            <FormattedTextIntl messageId={'app.developers.events.form.topic.label'} />
                        </FormLabel>
                        <Field as={InputWithCustomComponent} id="topic" name="topic" {...propsInputCommon}>
                            {values.topic}
                        </Field>
                        <FormErrorMessage>{errors.topic}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.type && !!touched.type}>
                        <FormLabel htmlFor="type" {...propsFormLabelCommon}>
                            <FormattedTextIntl messageId={'app.developers.events.form.type.label'} />
                        </FormLabel>
                        <Field as={InputWithCustomComponent} id="type" name="type" {...propsInputCommon}>
                            {values.type}
                        </Field>
                        <FormErrorMessage>{errors.type}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.status && !!touched.status}>
                        <FormLabel htmlFor="status" {...propsFormLabelCommon}>
                            <FormattedTextIntl messageId={'app.developers.events.form.status.label'} />
                        </FormLabel>
                        <Field as={InputWithCustomComponent} id="status" name="status" {...propsInputCommon}>
                            <StatusLabel value={values.status} />
                        </Field>
                        <FormErrorMessage>{errors.status}</FormErrorMessage>
                    </FormControl>

                    <FormControl hidden={!readonly}>
                        <FormLabel {...propsFormLabelCommon}>
                            <Flex justifyContent="space-between">
                                <FormattedTextIntl messageId={'app.developers.events.form.data.label'} />
                                <CopyToClipboardButton value={prettifiedData} />
                            </Flex>
                        </FormLabel>
                        <Field
                            as={Textarea}
                            {...propsInputCommon}
                            value={prettifiedData}
                            background="gray.50"
                            paddingX="16px"
                            paddingY="8px"
                            borderRadius="var(--numeral-ui-primary-border-radius)"
                            borderColor="gray.200"
                            borderWidth="1px"
                            rows={25}
                        />
                    </FormControl>
                    {!readonly && (
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            onCancel={onFormCancel}
                            isDisabled={!dirty}
                            submitLabelMessageId={'app.common.form.actions.submit.label'}
                            showUnsavedChangesWarning={true}
                        />
                    )}
                </>
            )}
        </Form>
    )
}
