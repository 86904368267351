import { EntityAction } from '@/components'
import {
    APIValidationErrorNotificationBody,
    ConnectedAccountName,
    FormattedDate,
    If,
    Separator,
    SeparatorVariant
} from '@/components/@misc'
import {
    useMutationCreateInquiry,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePermissions,
    useQueryFindByIdClaim,
    useToasts
} from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import {
    ApiObjectTypeSchema,
    ClaimStatusSchema,
    InquiryCreateData,
    InquiryRelatedRequestType,
    Uuid
} from '@webapps/numeral-ui-core'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'
import { ClaimAcceptConfirmationModal } from '../'

export const ClaimHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { onAdd } = useToasts()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const location = useLocation()
    const { paths, relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdClaim(uuid)
    const mutationCreateInquiry = useMutationCreateInquiry(uuid, {
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const { INVESTIGATIONS } = paths
            const path = [INVESTIGATIONS.INQUIRIES, response.id, relativePaths.INVESTIGATIONS.DETAILS].join('/')

            navigateWithLegalEntityID(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: (
                    <FormattedMessage id="app.investigations.inquiries.create.form.validation.success.toast.title" />
                ),
                description: (
                    <FormattedMessage id="app.investigations.inquiries.create.form.validation.success.description" />
                )
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.investigations.inquiries.create.form.validation.error.toast.title" />,
                description: (
                    <APIValidationErrorNotificationBody<InquiryCreateData>
                        error={error}
                        messageId="app.investigations.inquiries.create.form.validation.error.toast.description"
                    />
                )
            })
        }
    })
    const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false)
    const onCloseAcceptModal = useCallback(() => {
        setIsAcceptModalOpen(false)
    }, [])
    const title = useMemo<ReactNode>(() => {
        if (!query.data) {
            return null
        }

        const hasType = globalThis.Boolean(query.data.type)
        const connectedAccountId = query.data?.connected_account_id
        const showConnectedAccount = hasPermission(PERMISSION.SETTINGS_VIEW_CONNECTED_ACCOUNTS) && !!connectedAccountId
        const hasValueDate = globalThis.Boolean(query.data?.value_date?.length)

        return (
            <>
                <If condition={hasType}>
                    <FormattedMessage
                        id="app.investigations.claims.details.title"
                        values={{
                            type: <FormattedMessage id={`api.claim.type.${query.data.type}`} />
                        }}
                    />
                </If>
                <If condition={showConnectedAccount}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <ConnectedAccountName connectedAccountId={connectedAccountId} asPlainText={true} />
                </If>
                <If condition={hasValueDate}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <FormattedDate value={query.data?.value_date} />
                </If>
            </>
        )
    }, [query, hasPermission])
    const actions = useMemo(() => {
        const buttons: EntityAction[] = []
        const canAcceptOrRejectClaim = query.data?.status === ClaimStatusSchema.enum.received
        const canCreateInquiries =
            query.data?.related_payment_type === ApiObjectTypeSchema.enum.payment_order &&
            query.data?.status === ClaimStatusSchema.enum.sent

        if (hasPermission(PERMISSION.INVESTIGATIONS_CREATE_INQUIRIES) && canCreateInquiries) {
            buttons.push({
                async onClick() {
                    mutationCreateInquiry.mutate({
                        related_request_id: query.data?.id as Uuid,
                        related_request_type: query.data?.object as InquiryRelatedRequestType,
                        connected_account_id: query.data?.connected_account_id
                    })
                },
                loadingText: intl.formatMessage({
                    id: 'app.common.loading'
                }),
                isLoading: mutationCreateInquiry.isPending,
                children: intl.formatMessage({ id: 'app.investigations.actions.send_inquiry.label' })
            })
        }

        // @todo Comment out for v2
        /*if (hasPermission(PERMISSION.INVESTIGATIONS_ACCEPT_CLAIMS)) {
            buttons.push({
                onClick() {
                    setIsAcceptModalOpen(true)
                },
                loadingText: intl.formatMessage({
                    id: 'app.common.loading'
                }),
                isDisabled: !canAcceptOrRejectClaim,
                isLoading: query.isPending,
                children: intl.formatMessage({ id: 'app.investigations.tabs.details.actions.accept_claim.label' })
            })
        }*/

        // @TODO Comment out for v2
        /*if (hasPermission(PERMISSION.INVESTIGATIONS_REJECT_CLAIMS)) {
            buttons.push({
                async onClick() {
                    const { INVESTIGATIONS } = relativePaths
                    const path = updateUUIDRelativeActionInPathname(location.pathname, INVESTIGATIONS.REJECT)

                    navigate(path)
                },
                color: 'red.500',
                isDisabled: !canAcceptOrRejectClaim,
                isLoading: query.isPending,
                children: intl.formatMessage({ id: 'app.investigations.tabs.details.actions.reject_claim.label' })
            })
        }*/

        return buttons
    }, [query, intl, navigateWithLegalEntityID, location, relativePaths, uuid, mutationCreateInquiry, hasPermission])

    return (
        <>
            <PageHeaderLayout actions={actions} title={title} isLoading={query.isLoading} />
            {/* @TODO Comment out for v2
            <If condition={hasPermission(PERMISSION.INVESTIGATIONS_ACCEPT_CLAIMS)}>
                <ClaimAcceptConfirmationModal uuid={uuid} isOpen={isAcceptModalOpen} onClose={onCloseAcceptModal} />
            </If>
            */}
        </>
    )
}
