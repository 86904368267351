import { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios'
import { chain, isNil, omitBy } from 'lodash'

export async function removeNilHeadersRequestInterceptor(config: InternalAxiosRequestConfig) {
    if (config.headers) {
        config.headers = chain(config.headers)
            .thru((headers) => {
                return omitBy(headers, (value) => {
                    return isNil(value) || value === ''
                })
            })
            .value() as AxiosRequestHeaders
    }
    return config
}
