import { WildcardSchema } from '@webapps/numeral-ui-core'

export const WEBHOOK_RULES_MODES = {
    ALL: WildcardSchema.value,
    INDIVIDUAL: 'individual'
}

export const WEBHOOK_ALL_RULES = {
    [WildcardSchema.value]: [WildcardSchema.value]
}
