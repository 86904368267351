import { Form, FormPageFooter, InputWithCopyToClipboard } from '@/components'
import { getWebhookRulesMode, Webhook, WEBHOOK_ALL_RULES, WEBHOOK_RULES_MODES } from '@/services'
import { FormControl, FormLabel, Input, Radio, RadioGroup } from '@chakra-ui/react'
import { noop } from 'lodash'
import { useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getWebhookFormInitialValues, webhookNameValidator } from './WebhookCreateOrEdit.form.utils'
import { If } from '@/components/@misc'
import { TextInput } from '@/components/@inputs'
import { areIndividualWebhookRulesEnabled } from '../../Webhooks.page.utils'
import { urlValidator } from '@/utils/@validators'
import { WebhookRulesInput } from '../WebhookRulesInput'
import { WebhookCreateOrEditFormProps } from './WebhookCreateOrEdit.form.types'

export const WebhookCreateOrEditForm: React.FC<WebhookCreateOrEditFormProps> = ({
    data,
    disabled,
    readonly,
    loading,
    onSubmit = noop
}) => {
    const [rulesMode, setRulesMode] = useState(getWebhookRulesMode(data?.rules))
    const isEditMode = useRef(globalThis.Boolean(data?.id)).current
    const initialValues = useMemo(() => getWebhookFormInitialValues(data), [data])
    const hasIndividualWebhookRulesEnabled = useMemo(() => areIndividualWebhookRulesEnabled(rulesMode), [rulesMode])
    const submitLabelMessageId = useMemo<string | undefined>(() => {
        return isEditMode ? undefined : 'app.developers.webhooks.form.actions.submit.label'
    }, [isEditMode])

    return (
        <Form<Webhook> initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, dirty, setFieldValue }) => {
                const isInputDisabled = isEditMode || loading || disabled
                const onToggleRulesMode = (value: string) => {
                    const hasIndividualWebhookRulesEnabled = areIndividualWebhookRulesEnabled(value)
                    const rulesValue = hasIndividualWebhookRulesEnabled ? Object.create(null) : WEBHOOK_ALL_RULES

                    setFieldValue('rules', rulesValue)
                    setRulesMode(value)
                }

                return (
                    <>
                        <If condition={isEditMode}>
                            <TextInput name="id" isDisabled={isInputDisabled} as={InputWithCopyToClipboard} />
                        </If>
                        <TextInput
                            name="name"
                            customPlaceholderKey="app.developers.webhooks.form.name.placeholder"
                            validator={webhookNameValidator}
                            isRequired={true}
                            isDisabled={loading}
                        />
                        <TextInput
                            name="url"
                            customPlaceholderKey="app.developers.webhooks.form.url.placeholder"
                            isDisabled={isInputDisabled}
                            validator={urlValidator}
                            isRequired={true}
                            as={isEditMode ? InputWithCopyToClipboard : Input}
                        />
                        <FormControl>
                            <FormLabel htmlFor="topicsFilterMode">
                                <FormattedMessage id="app.developers.webhooks.form.rules_mode.label" />
                            </FormLabel>
                            <RadioGroup
                                id="rulesMode"
                                name="rulesMode"
                                value={rulesMode}
                                onChange={onToggleRulesMode}
                                isDisabled={isEditMode}
                                display="flex"
                                gap="var(--numeral-ui-primary-spacing)">
                                <Radio value={WEBHOOK_RULES_MODES.ALL}>
                                    <FormattedMessage id="app.developers.webhooks.form.rules_mode.all.label" />
                                </Radio>
                                <Radio value={WEBHOOK_RULES_MODES.INDIVIDUAL}>
                                    <FormattedMessage id="app.developers.webhooks.form.rules_mode.individual.label" />
                                </Radio>
                            </RadioGroup>
                        </FormControl>

                        <If condition={hasIndividualWebhookRulesEnabled}>
                            <WebhookRulesInput
                                name="rules"
                                isReadOnly={readonly}
                                isDisabled={isEditMode}
                                isRequired={true}
                            />
                        </If>
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={loading || !dirty}
                            isLoading={loading}
                            submitLabelMessageId={submitLabelMessageId}
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
