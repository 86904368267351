import { EntityDetail, EntityDetailRowCustomizers, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdInternalAccount } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { getCustomFieldsRowCustomizers } from '@/pages/Common'
import { InternalAccountCustomRows } from './InternalAccountDetails.page.types'
import { PERMISSION } from '@/services'
import { ApiObjectTypeSchema, InternalAccount } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
    INTERNAL_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
    INTERNAL_ACCOUNT_DETAILS_ROWS
} from './InternalAccountDetails.page.const'

export const InternalAccountDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdInternalAccount(uuid)
    const rowCustomizers = useMemo(() => {
        return {
            ...INTERNAL_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
            custom_fields: getCustomFieldsRowCustomizers(query.data)
        } as EntityDetailRowCustomizers<InternalAccount, InternalAccountCustomRows>
    }, [query])

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.COUNTERPARTIES_VIEW_INTERNAL_ACCOUNTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.internal_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<InternalAccount, InternalAccountCustomRows>
                        data={query.data}
                        rows={INTERNAL_ACCOUNT_DETAILS_ROWS}
                        rowCustomizers={rowCustomizers}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
