import { RestrictedSection } from '@/components'
import { useMutationSendInvitation, useNavigateWithLegalEntityID, useNavigationRoutes, useToasts } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { UserInviteForm } from './@components'

export const UserInvitePage: React.FC = () => {
    const intl = useIntl()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.settings.users.add.title' }), [intl])
    const { onAdd } = useToasts()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { relativePaths, paths } = useNavigationRoutes()
    const mutation = useMutationSendInvitation({
        onSuccess(response) {
            const newUserId = response?.id

            if (!newUserId) {
                return
            }

            const path = [paths.SETTINGS.USERS, newUserId, relativePaths.SETTINGS.DETAILS].join('/')

            navigateWithLegalEntityID(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.settings.users.invite.success.toast.title" />,
                description: <FormattedMessage id="app.settings.users.invite.success.toast.description" />
            })
        },
        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.settings.users.invite.error.toast.title" />,
                description: <FormattedMessage id="app.settings.users.invite.error.toast.description" />
            })
        }
    })

    return (
        <PageLayout className="UserInvite">
            <PageHeaderLayout title={title} />
            <RestrictedSection permission={PERMISSION.SETTINGS_INVITE_USERS}>
                <UserInviteForm onSubmit={mutation.mutate} disabled={mutation.isPending} loading={mutation.isPending} />
            </RestrictedSection>
        </PageLayout>
    )
}
