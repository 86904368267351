import { If } from '@/components/@misc'
import { SPACE_SYMBOL } from '@/constants'
import { useNavigateWithLegalEntityID, useNavigationRoutes, usePermissions } from '@/hooks'
import { updateUUIDRelativeActionInPathname } from '@/providers'
import { PERMISSION } from '@/services'
import { Flex, Link, Text } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

export const EntityDetailLabelCustomFields: React.FC<{ permission?: PERMISSION }> = ({ permission }) => {
    const { hasPermission } = usePermissions()
    const hasEditPermissions = useMemo(() => {
        return permission ? hasPermission(permission) : false
    }, [hasPermission, permission])
    const location = useLocation()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { relativePaths } = useNavigationRoutes()
    const onEdit = useCallback(() => {
        const { SETTINGS } = relativePaths
        const path = updateUUIDRelativeActionInPathname(location.pathname, SETTINGS.EDIT)

        navigateWithLegalEntityID(path)
    }, [location, relativePaths, navigateWithLegalEntityID])

    return (
        <Flex>
            <Text whiteSpace="pre-wrap">
                <FormattedMessage id="app.page.details.fields.custom_fields.label" />
                {SPACE_SYMBOL}
            </Text>
            <If condition={hasEditPermissions}>
                <Link onClick={onEdit} marginX="4px">
                    <FormattedMessage id="app.page.details.fields.custom_fields.actions.edit.label" />
                </Link>
            </If>
        </Flex>
    )
}
