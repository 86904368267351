import ReactDOMServer from 'react-dom/server'
import { Nullable } from '@/types'

function stripHtml(html: string): string {
    return html?.replace(/<\/?[^>]+(>|$)/g, '').trim()
}

export function renderToString(element?: Nullable<React.JSX.Element | JSX.Element | string>): string {
    try {
        const htmlString = ReactDOMServer.renderToStaticMarkup(element)
        return stripHtml(htmlString)
    } catch (error) {
        console.error('Error rendering element:', error)
        return ''
    }
}
