import { FormikInput, Nullable } from '@/types'
import React from 'react'

export enum GROUP_ACCORDION_DEFAULT_WILDCARDS {
    ARRAY_WILDCARD = 'admin',
    RECORD_WILDCARD = '*'
}

export type GroupRecordType = Record<string, string[]>
export type GroupItemType = string | { toString(): string }
export type GroupAccordionSupportedInputType = Nullable<GroupItemType | GroupRecordType>

export type GroupOptions = {
    [key: string]: string[]
}

export type GroupStats = {
    [key: string]: { total: number; selected: number }
}

type GroupLabelBaseFormatterFunction = (
    group: string,
    selected: number,
    available: number
) => React.JSX.Element | string | undefined
type GroupOptionBaseFormatterFunction = (
    group: string,
    value: string,
    selected: number,
    available: number
) => React.JSX.Element | string | undefined

export interface GroupAccordionInputBaseFormatters {
    // Group level formatters:
    getGroupLabel?: GroupLabelBaseFormatterFunction
    getGroupCounterLabel?: GroupLabelBaseFormatterFunction
    // Group option level formatters:
    getGroupOptionLabel?: GroupOptionBaseFormatterFunction
    getGroupOptionDescription?: GroupOptionBaseFormatterFunction
}

export interface GroupAccordionInputBaseProps<T extends GroupAccordionSupportedInputType>
    extends GroupAccordionInputBaseFormatters {
    value?: Nullable<T[] | T>
    options?: GroupAccordionSupportedInputType
    groupOrder?: string[]
    wildcardSymbol?: string

    optionsFilter?(option: string, index: number): boolean
}

export type GroupAccordionInputProps<T extends GroupAccordionSupportedInputType> = GroupAccordionInputBaseProps<T> &
    Omit<FormikInput, 'value'>

export type GroupAccordionInputTypeHookProps<T extends GroupAccordionSupportedInputType> =
    GroupAccordionInputProps<T> & {
        normalizedGroupOptions: GroupOptions
    }

export type GroupAccordionInputLabelFormattersHookProps = GroupAccordionInputBaseFormatters & {
    hasArrayShape?: boolean
}
