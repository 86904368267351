import { useCallback } from 'react'
import { useField } from 'formik'
import {
    GROUP_ACCORDION_DEFAULT_WILDCARDS,
    GroupAccordionInputTypeHookProps,
    GroupAccordionSupportedInputType,
    GroupOptions,
    GroupRecordType
} from '../../GroupAccordionInput.types'
import { anythingValidator } from '@/utils/@validators'
import { useIntl } from 'react-intl'
import { every, includes, size } from 'lodash'

function areAllGroupOptionsSelectedByWildcard(groupOptions: string[], wildcardSymbol: string) {
    return groupOptions?.includes(wildcardSymbol)
}

function areAllGroupsSelected(
    normalizedGroupOptions: GroupOptions,
    record: Record<any, any>,
    wildcardSymbol: string
): boolean {
    return (
        size(normalizedGroupOptions) - 1 === size(record) && every(record, (value) => includes(value, wildcardSymbol))
    )
}

export function useGroupAccordionInputRecord<T extends GroupAccordionSupportedInputType>({
    name,
    isDisabled,
    isReadOnly,
    isRequired,
    normalizedGroupOptions,
    validator = anythingValidator,
    wildcardSymbol = GROUP_ACCORDION_DEFAULT_WILDCARDS.RECORD_WILDCARD
}: GroupAccordionInputTypeHookProps<T>) {
    const intl = useIntl()
    const [field, meta, helpers] = useField<GroupRecordType>({
        name,
        readOnly: isReadOnly,
        disabled: isDisabled,
        validate: validator?.(intl, { isRequired })
    })
    const { setValue } = helpers
    const handleCheckboxChange = useCallback(
        (group: string, value: string) => {
            if (!field.value) {
                return
            }

            const validateFieldsWhileChanging = true
            const record = { ...field.value }
            const hasGlobalWildcard = record[wildcardSymbol]?.includes(wildcardSymbol)

            if (hasGlobalWildcard) {
                // Remove global wildcard, expand all groups
                const newRecord: GroupRecordType = Object.create(null)

                Object.entries(normalizedGroupOptions).forEach(([g, items]) => {
                    if (g === group) {
                        // For clicked group, add all values except the one being unchecked
                        newRecord[g] = items.filter((item) => item !== value)
                    } else {
                        // Keep wildcard for other groups
                        newRecord[g] = [wildcardSymbol]
                    }
                })

                setValue(newRecord, validateFieldsWhileChanging)
                return
            }

            // Handle normal group selection
            const groupItems = normalizedGroupOptions[group] || []
            const currentValues = new Set(record[group] || [])

            if (currentValues.has(wildcardSymbol)) {
                // If group had wildcard, expand to all values except unchecked one
                record[group] = groupItems.filter((item) => item !== value)
            } else {
                if (currentValues.has(value)) {
                    currentValues.delete(value)
                } else {
                    currentValues.add(value)
                    // If all items are now selected, convert to wildcard
                    if (currentValues.size === groupItems.length) {
                        record[group] = [wildcardSymbol]

                        setValue(record, validateFieldsWhileChanging)
                        return
                    }
                }
                record[group] = [...currentValues]
            }

            if (record[group]?.length === 0) {
                delete record[group]
            }

            setValue(record, validateFieldsWhileChanging)
        },
        [field.value, wildcardSymbol]
    )

    const handleGroupCheckboxChange = useCallback(
        (group: string) => {
            if (!field.value) {
                return
            }

            const validateFieldsWhileChanging = true
            let record = { ...field.value }
            const hasGlobalWildcard = record[wildcardSymbol]?.includes(wildcardSymbol)

            // Remove global wildcard, keep other groups as wildcard
            if (hasGlobalWildcard) {
                const newRecord: GroupRecordType = Object.create(null)

                Object.entries(normalizedGroupOptions).forEach(([groupOption]) => {
                    if (groupOption !== group) {
                        newRecord[groupOption] = [wildcardSymbol]
                    }
                })

                setValue(newRecord, validateFieldsWhileChanging)
                return
            }

            switch (true) {
                case areAllGroupsSelected(normalizedGroupOptions, record, wildcardSymbol): {
                    record = { [wildcardSymbol]: [wildcardSymbol] }
                    break
                }
                case areAllGroupOptionsSelectedByWildcard(record[group], wildcardSymbol): {
                    delete record[group]
                    break
                }

                default: {
                    record[group] = [wildcardSymbol]
                }
            }

            setValue(record, validateFieldsWhileChanging)
        },
        [field.value, normalizedGroupOptions, wildcardSymbol]
    )

    return {
        field,
        meta,
        //
        handleCheckboxChange,
        handleGroupCheckboxChange
    }
}
