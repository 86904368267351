import { EntityAction } from '@/components'
import { If, FormattedDate, Separator, SeparatorVariant } from '@/components/@misc'
import { useMutationDownloadByIdFile, usePermissions, useQueryFindByIdFile } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { prettyPrintCasedWords } from '@/utils'
import { Text } from '@chakra-ui/react'
import { saveFile } from '@webapps/numeral-ui-core'
import { ReactNode, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

export const FileDetailsHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdFile(uuid)
    const mutation = useMutationDownloadByIdFile(uuid)
    const title = useMemo<ReactNode>(() => {
        if (!query.data) {
            return null
        }

        const hasFileCategory = globalThis.Boolean(query.data.category)
        const hasCreatedAtDate = globalThis.Boolean(query.data.created_at)

        return (
            <>
                <If condition={hasFileCategory}>
                    <Text as="span">{prettyPrintCasedWords(query.data.category)}</Text>
                </If>
                <If condition={hasCreatedAtDate}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <FormattedDate value={query.data.created_at} />
                </If>
            </>
        )
    }, [query, hasPermission])
    const actions = useMemo<EntityAction[]>(() => {
        return hasPermission(PERMISSION.FILES_DOWNLOAD_FILES)
            ? [
                  {
                      async onClick() {
                          const fileData = await mutation.mutateAsync()
                          const fileName = query.data?.filename || query.data?.id

                          if (fileData && fileName) {
                              saveFile(fileData, fileName)
                          }
                      },
                      loadingText: intl.formatMessage({
                          id: 'app.files.tabs.details.actions.download.loading.label'
                      }),
                      isLoading: mutation.isPending,
                      children: intl.formatMessage({ id: 'app.files.tabs.details.actions.download.label' })
                  }
              ]
            : []
    }, [query, intl, mutation, hasPermission])

    return <PageHeaderLayout actions={actions} title={title} isLoading={query.isLoading} />
}
