import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { Claim, ClaimCreateData, ClaimRejectData, Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { AxiosError } from 'axios'
import { APIError } from '@/types'

export function useMutationRejectByIdClaim(
    id?: Uuid,
    options?: MutationOptions<Claim | void, AxiosError<APIError<ClaimRejectData>>, ClaimRejectData>
) {
    const { selectedUserAccess } = useAuth()
    const { claimsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<Claim | void, AxiosError<APIError<ClaimRejectData>>, ClaimRejectData>({
        ...options,
        mutationKey: [claimsService.url, { id }],
        mutationFn(data) {
            return claimsService.rejectClaim(id as string, data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            queryClient.setQueryData([claimsService.url, { id }], args[0])

            await queryClient
                .invalidateQueries({
                    queryKey: [claimsService.url]
                })
                .catch(noop)
        }
    })
}
