import {
    EntityCellReturnDirection,
    EntityDetailCellRelatedObjectsLinks,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers
} from '@/components'
import { ConnectedAccountName, FormattedDateTime, SepaReturnReasonDescription, StatusLabel } from '@/components/@misc'
import { ReturnRequest } from '@webapps/numeral-ui-core'
import { ReturnRequestDetailsCustomRows } from './ReturnRequestDetails.types'

export const RETURN_REQUEST_DETAILS_ROWS: EntityDetailRow<ReturnRequest, ReturnRequestDetailsCustomRows>[] = [
    'id',
    'created_at',
    'direction',
    'connected_account_id',
    'return_reason',
    'status',
    'status_details',
    'related_objects',
    'metadata',
    'bank_data'
]

export const RETURN_REQUEST_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    ReturnRequest,
    ReturnRequestDetailsCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    direction: {
        cell(item) {
            return <EntityCellReturnDirection value={item.related_payment_type} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    return_reason: {
        cell(item) {
            return <SepaReturnReasonDescription sepaReturnReason={item.return_reason} />
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    related_objects: {
        cell(item) {
            return (
                <EntityDetailCellRelatedObjectsLinks
                    relatedClaimId={item.related_payment_id}
                    relatedPaymentId={item.related_payment_id}
                    relatedPaymentType={item.related_payment_type}
                    relatedFileId={item.bank_data?.file_id}
                    showRelatedPaymentLink={true}
                    showRelatedReturnsLink={true}
                    showRelatedInquiriesLink={true}
                />
            )
        }
    },
    metadata: {
        nested: true
    },
    bank_data: {
        nested: true
    }
}
