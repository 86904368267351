import { RestrictedSection } from '@/components'
import { APIValidationErrorNotificationBody } from '@/components/@misc'
import { useMutationCreateExpectedPayment, useNavigateWithLegalEntityID, useNavigationRoutes, useToasts } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers/ToastsProvider'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Box } from '@chakra-ui/react'
import { ConnectedAccount, ExpectedPayment } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { ExpectedPaymentForm } from './@components'

export const ExpectedPaymentCreatePage: React.FC = () => {
    const intl = useIntl()
    const location = useLocation()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.payments.expected_payments.create.title' }),
        [intl]
    )
    const data = useMemo<ConnectedAccount[] | undefined>(() => {
        const state: any = location.state /* @todo Workaround for `location.state` type check*/
        return state?.data
    }, [location])
    const mutation = useMutationCreateExpectedPayment({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const { PAYMENTS } = paths
            const path = [PAYMENTS.EXPECTED_PAYMENTS, response.id, relativePaths.PAYMENTS.DETAILS].join('/')

            navigateWithLegalEntityID(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                isClosable: true,
                title: (
                    <FormattedMessage id="app.payments.expected_payments.create.form.validation.success.toast.title" />
                ),
                description: (
                    <FormattedMessage id="app.payments.expected_payments.create.form.validation.success.toast.description" />
                )
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                isClosable: true,
                title: (
                    <FormattedMessage id="app.payments.expected_payments.create.form.validation.error.toast.title" />
                ),
                description: (
                    <APIValidationErrorNotificationBody<ExpectedPayment>
                        error={error}
                        messageId="app.payments.expected_payments.create.form.validation.error.toast.description"
                    />
                )
            })
        }
    })

    return (
        <PageLayout className="ExpectedPaymentCreate">
            <PageHeaderLayout title={title} showBackButton={true} />
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.PAYMENTS_CREATE_EXPECTED_PAYMENTS}>
                <Box marginTop="8px">
                    <ExpectedPaymentForm
                        onSubmit={mutation.mutate}
                        disabled={mutation.isPending}
                        loading={mutation.isPending}
                        data={data}
                    />
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
