import { FieldValidator, FormikValues } from 'formik'
import { IntlShape } from 'react-intl'
import { z } from 'zod'
import { FormikInputConfiguration } from '@/types'
import { chain } from 'lodash'

const MIN_LENGTH_VALUE = 1
const MinValueStringSchema = z.string().min(MIN_LENGTH_VALUE)
const MinValueArraySchema = z.array(z.any()).min(MIN_LENGTH_VALUE)
const MinValueRecordSchema = z.any().refine((object) => chain(object).size().gt(0).value())
const MinValueSchema = z.union([MinValueStringSchema, MinValueArraySchema, MinValueRecordSchema])

export function anythingValidator(
    intl: IntlShape,
    configuration?: FormikInputConfiguration,
    values?: FormikValues
): FieldValidator {
    return (value: string): string | void => {
        if (configuration?.isRequired && !MinValueSchema.safeParse(value).success) {
            return intl.formatMessage({ id: 'app.common.form.validation.required' })
        }
    }
}
