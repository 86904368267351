import React, { useMemo } from 'react'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Text
} from '@chakra-ui/react'
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl'
import { GROUPED_ACCORDION_FIELD_STYLE_BOX } from './GroupAccordionInput.const'
import { If } from '@/components/@misc'
import {
    GROUP_ACCORDION_DEFAULT_WILDCARDS,
    GroupAccordionInputProps,
    GroupAccordionSupportedInputType
} from './GroupAccordionInput.types'
import {
    computeGroupInputStats,
    extractGroupOptions,
    isGroupOptionDescriptionAvailable,
    isItemChecked,
    normalizeGroupOptions
} from './GroupAccordionInput.utils'
import { useGroupAccordionInputArray, useGroupAccordionInputRecord, useGroupAccordionLabelFormatters } from './@hooks'
import { GroupAccordionLoading } from '@/components/@inputs'
import { isEmpty } from 'lodash'
import { renderToString } from '@/utils'

export const GroupAccordionInput = <T extends GroupAccordionSupportedInputType>(props: GroupAccordionInputProps<T>) => {
    const intl = useIntl()
    const {
        name,
        options,
        isDisabled,
        isLoading,
        isRequired,
        groupOrder,
        wildcardSymbol,
        getGroupLabel,
        getGroupOptionLabel,
        getGroupCounterLabel,
        getGroupOptionDescription,
        optionsFilter
    } = props
    const normalizedGroupOptions = useMemo(
        () => normalizeGroupOptions(options, optionsFilter),
        [options, optionsFilter]
    )
    const hasArrayShape = useMemo(() => globalThis.Array.isArray(options), [options])
    const computedWildcardSymbol = useMemo(() => {
        return wildcardSymbol || hasArrayShape
            ? GROUP_ACCORDION_DEFAULT_WILDCARDS.ARRAY_WILDCARD
            : GROUP_ACCORDION_DEFAULT_WILDCARDS.RECORD_WILDCARD
    }, [wildcardSymbol, hasArrayShape])

    const inputsProps = { normalizedGroupOptions, ...props }
    const { field, meta, handleCheckboxChange, handleGroupCheckboxChange } = hasArrayShape
        ? useGroupAccordionInputArray<T>(inputsProps)
        : useGroupAccordionInputRecord<T>(inputsProps)

    const {
        getFormattedGroupLabel,
        getFormattedGroupCounterLabel,
        getFormattedGroupOptionLabel,
        getFormattedGroupOptionDescription
    } = useGroupAccordionLabelFormatters({
        hasArrayShape,
        getGroupLabel,
        getGroupCounterLabel,
        getGroupOptionLabel,
        getGroupOptionDescription
    })

    const availableGroups = useMemo(() => extractGroupOptions(options, groupOrder), [options, groupOrder])
    const groupStats = useMemo(() => {
        return computeGroupInputStats(
            field.value,
            availableGroups,
            normalizedGroupOptions,
            computedWildcardSymbol,
            isItemChecked
        )
    }, [availableGroups, normalizedGroupOptions, field, computedWildcardSymbol])
    const isDataEmpty = useMemo(() => !isLoading && isEmpty(availableGroups), [isLoading, availableGroups])
    const propsBox = useMemo(() => {
        return {
            ...GROUPED_ACCORDION_FIELD_STYLE_BOX,
            borderWidth: isDataEmpty ? '0' : '1px'
        }
    }, [isDataEmpty])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl isInvalid={isInvalid}>
            <FormLabel>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Box {...propsBox}>
                <If condition={isLoading}>
                    <GroupAccordionLoading />
                </If>
                <If condition={!isLoading}>
                    <Accordion allowMultiple={true}>
                        {availableGroups.map((group, index) => {
                            const groupItems = normalizedGroupOptions[group] || []
                            const totalGroupCount = groupStats[group]?.total
                            const selectedGroupCount = groupStats[group]?.selected
                            const isGroupIndeterminate = selectedGroupCount > 0 && selectedGroupCount < totalGroupCount
                            const isGroupChecked = selectedGroupCount === totalGroupCount
                            const isFirstItem = index === 0
                            const propsAccordionItem = {
                                [isFirstItem ? 'borderTop' : 'borderBottom']: 'unset'
                            }
                            const groupLabel = getFormattedGroupLabel(group, selectedGroupCount, totalGroupCount)
                            const groupLabelString = renderToString(<IntlProvider {...intl}>{groupLabel}</IntlProvider>)

                            return (
                                <AccordionItem {...propsAccordionItem} key={group}>
                                    <AccordionButton display="flex" padding="16px" justifyContent="space-between">
                                        <Checkbox
                                            isChecked={isGroupChecked}
                                            isDisabled={isDisabled}
                                            isRequired={isRequired}
                                            isIndeterminate={isGroupIndeterminate}
                                            onChange={() => handleGroupCheckboxChange(group)}
                                            isInvalid={isInvalid}
                                            size="lg"
                                            onClick={(event) => event.stopPropagation()}>
                                            <Text
                                                marginLeft="8px"
                                                color="gray.800"
                                                fontWeight="semibold"
                                                pointerEvents="none"
                                                aria-label={groupLabelString}
                                                title={groupLabelString}>
                                                {groupLabel}
                                            </Text>
                                        </Checkbox>
                                        <Flex>
                                            <Text color="gray.500" marginX="8px" fontSize="14px">
                                                {getFormattedGroupCounterLabel(
                                                    group,
                                                    selectedGroupCount,
                                                    totalGroupCount
                                                )}
                                            </Text>
                                            <AccordionIcon color="gray.500" />
                                        </Flex>
                                    </AccordionButton>
                                    <AccordionPanel
                                        display="flex"
                                        gap="16px"
                                        flexDirection="column"
                                        marginLeft="16px"
                                        color="gray.800">
                                        {groupItems.map((item, index) => {
                                            const isChecked = isItemChecked(
                                                group,
                                                item,
                                                field.value,
                                                computedWildcardSymbol
                                            )
                                            const groupOptionLabel = getFormattedGroupOptionLabel(
                                                group,
                                                item,
                                                selectedGroupCount,
                                                totalGroupCount
                                            )
                                            const groupOptionDescription = getFormattedGroupOptionDescription(
                                                group,
                                                item,
                                                selectedGroupCount,
                                                totalGroupCount
                                            )
                                            const groupOptionLabelString = renderToString(
                                                <IntlProvider {...intl}>{groupOptionLabel}</IntlProvider>
                                            )
                                            const hasGroupOptionDescription =
                                                isGroupOptionDescriptionAvailable(groupOptionDescription)

                                            return (
                                                <Checkbox
                                                    key={index}
                                                    isChecked={isChecked}
                                                    isDisabled={isDisabled}
                                                    isRequired={isRequired}
                                                    onChange={() => handleCheckboxChange(group, item)}
                                                    isInvalid={isInvalid}
                                                    aria-label={groupOptionLabelString}
                                                    title={groupOptionLabelString}>
                                                    <Flex direction="column">
                                                        <Text>{groupOptionLabel}</Text>
                                                        <If condition={hasGroupOptionDescription}>
                                                            <Text color="gray.500" fontSize="sm">
                                                                {groupOptionDescription}
                                                            </Text>
                                                        </If>
                                                    </Flex>
                                                </Checkbox>
                                            )
                                        })}
                                    </AccordionPanel>
                                </AccordionItem>
                            )
                        })}
                    </Accordion>
                </If>
                <If condition={isDataEmpty}>
                    <Text color="gray.400">
                        <FormattedMessage id="app.common.no_data_available.title" />
                    </Text>
                </If>
            </Box>
            <If condition={isInvalid}>
                <Text color="red.500">{meta.error}</Text>
            </If>
        </FormControl>
    )
}
