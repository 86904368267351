import { Webhook } from '@/services'
import { chain } from 'lodash'
import { IntlShape } from 'react-intl'

export function getWebhookDeliveryModeLabelWithIntl(intl?: IntlShape) {
    return function getWebhookDeliveryModeLabel(webhook?: Webhook) {
        const hasBatchedEvents = chain(webhook).get('batching_size').gt(1).value()

        if (webhook?.fifo) {
            return hasBatchedEvents
                ? intl?.formatMessage({
                      id: 'app.developers.webhooks.form.delivery_mode.fifo.batched_events.label'
                  })
                : intl?.formatMessage({
                      id: 'app.developers.webhooks.form.delivery_mode.fifo.individual_events.label'
                  })
        }

        return hasBatchedEvents
            ? intl?.formatMessage({
                  id: 'app.developers.webhooks.form.delivery_mode.batched_events.label'
              })
            : intl?.formatMessage({
                  id: 'app.developers.webhooks.form.delivery_mode.individual_events.label'
              })
    }
}
