import { useCallback } from 'react'
import { isFunction } from 'lodash'
import { GroupAccordionInputLabelFormattersHookProps } from '../GroupAccordionInput.types'
import { composeGroupValueTuple } from '../GroupAccordionInput.utils'
import { EMPTY_CHAR_SYMBOL } from '@/constants'

export function useGroupAccordionLabelFormatters({
    hasArrayShape,
    getGroupCounterLabel,
    getGroupOptionLabel,
    getGroupOptionDescription,
    getGroupLabel
}: GroupAccordionInputLabelFormattersHookProps) {
    const getFormattedGroupCounterLabel = useCallback(
        (group: string, selected: number, available: number) => {
            if (isFunction(getGroupCounterLabel)) {
                return getGroupCounterLabel(group, selected, available)
            }
            return group
        },
        [getGroupCounterLabel]
    )
    const getFormattedGroupOptionLabel = useCallback(
        (group: string, value: string, selected: number, available: number) => {
            if (isFunction(getGroupOptionLabel)) {
                const groupValue = hasArrayShape ? composeGroupValueTuple(group, value) : value

                return getGroupOptionLabel(groupValue, value, selected, available)
            }
            return value
        },
        [getGroupOptionLabel, hasArrayShape]
    )
    const getFormattedGroupOptionDescription = useCallback(
        (group: string, value: string, selected: number, available: number) => {
            if (isFunction(getGroupOptionDescription)) {
                const groupValue = hasArrayShape ? composeGroupValueTuple(group, value) : value

                return getGroupOptionDescription(groupValue, value, selected, available)
            }
            return EMPTY_CHAR_SYMBOL
        },
        [getGroupOptionLabel, hasArrayShape]
    )
    const getFormattedGroupLabel = useCallback(
        (group: string, selected: number, available: number) => {
            if (isFunction(getGroupLabel)) {
                return getGroupLabel(group, selected, available)
            }
            return group
        },
        [getGroupLabel]
    )

    return {
        // Group level formatters
        getFormattedGroupCounterLabel,
        getFormattedGroupLabel,
        // Option level formatters
        getFormattedGroupOptionLabel,
        getFormattedGroupOptionDescription
    }
}
