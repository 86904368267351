import { EntityAction, EntityActionsMenu } from '@/components'
import { useNavigateWithLegalEntityID, useNavigationRoutes } from '@/hooks'
import { ButtonGroup, IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropsWithChildren, useMemo } from 'react'
import { useIntl } from 'react-intl'

interface PaymentOrderCreateActionContainerProps extends PropsWithChildren {
    disabled?: boolean
}

export const PaymentOrderCreateActionContainer: React.FC<PaymentOrderCreateActionContainerProps> = ({
    children,
    disabled
}) => {
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { relativePaths } = useNavigationRoutes()
    const actions = useMemo<EntityAction[]>(() => {
        return [
            {
                isDisabled: disabled,
                onClick() {
                    navigateWithLegalEntityID(relativePaths.UPLOAD)
                },
                children: intl.formatMessage({
                    id: 'app.payments.payment_orders.upload.title'
                })
            }
        ]
    }, [disabled, intl, navigateWithLegalEntityID, relativePaths.UPLOAD])

    return (
        <ButtonGroup isAttached={true} isDisabled={disabled}>
            {children}
            <Popover placement="bottom-end" isLazy={true}>
                <PopoverTrigger>
                    <IconButton icon={<FontAwesomeIcon icon={faChevronDown} size="xs" />} aria-label="" />
                </PopoverTrigger>
                <Portal>
                    <PopoverContent minWidth="180px" width="auto" boxShadow="lg">
                        <PopoverBody maxHeight="180px" overflowY="auto" paddingX="8px">
                            <EntityActionsMenu actions={actions} />
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        </ButtonGroup>
    )
}
