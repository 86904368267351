import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdClaim } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Claim } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { CLAIM_DETAILS_ROW_CUSTOMIZERS, CLAIM_DETAILS_ROWS } from './ClaimDetails.page.const'
import { ClaimDetailsCustomRows } from './ClaimDetails.types'
import { useMemo } from 'react'

export const ClaimDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdClaim(uuid)
    const isDataEmpty = useMemo(() => isEmpty(query?.data), [query])

    return (
        <PageLayout>
            <RestrictedSection feature={ENTITY_FEATURE.CLAIM} permission={PERMISSION.INVESTIGATIONS_VIEW_CLAIMS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.claim}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <EntityDetail<Claim, ClaimDetailsCustomRows>
                        data={query.data}
                        rows={CLAIM_DETAILS_ROWS}
                        rowCustomizers={CLAIM_DETAILS_ROW_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
