import { QueryFallback, RestrictedSection } from '@/components'
import {
    useMutationUpdateByIdExternalAccount,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useQueryFindByIdExternalAccount
} from '@/hooks'
import { PageLayout } from '@/layouts'
import { ExternalAccountHeader } from '@/pages'
import { updateUUIDRelativeActionInPathname } from '@/providers'
import { PERMISSION } from '@/services'
import { Box } from '@chakra-ui/react'
import { ApiObjectTypeSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import { ExternalAccountEditForm } from './@components'

export const ExternalAccountEditPage: React.FC = () => {
    const { uuid } = useParams()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const query = useQueryFindByIdExternalAccount(uuid)
    const mutation = useMutationUpdateByIdExternalAccount(uuid, {
        onSuccess() {
            const { ACCOUNTS } = relativePaths
            const path = updateUUIDRelativeActionInPathname(location.pathname, ACCOUNTS.DETAILS)

            navigateWithLegalEntityID(path)
        }
    })

    return (
        <PageLayout className="ExternalAccountEdit">
            <ExternalAccountHeader />
            <RestrictedSection permission={PERMISSION.COUNTERPARTIES_EDIT_EXTERNAL_ACCOUNTS}>
                <Box marginTop="8px">
                    <QueryFallback
                        objectType={ApiObjectTypeSchema.enum.external_account}
                        isLoading={query.isLoading}
                        isSuccess={query.isSuccess}
                        isError={query.isError || isEmpty(query?.data)}
                        isDataEmpty={isEmpty(query?.data)}>
                        <ExternalAccountEditForm
                            onSubmit={mutation.mutate}
                            disabled={mutation.isPending}
                            loading={mutation.isPending}
                            data={query.data}
                        />
                    </QueryFallback>
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
