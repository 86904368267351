import { Webhook, WEBHOOK_ALL_RULES, WEBHOOK_RULES_MODES, WebhookRules, WebhookStatus } from '@/services'
import { WebhookStatusSchema } from './Webhook.schema'
import { isEqualWith } from 'lodash'
import { isEmptyRecord } from '@/utils'
import { Nullable } from '@/types'

export function getWebhookStatusMapper(data?: Partial<Webhook>): WebhookStatus {
    return data?.enabled ? WebhookStatusSchema.enum.enabled : WebhookStatusSchema.enum.disabled
}

export function getWebhookRulesMode(webhookRules?: Nullable<WebhookRules>) {
    switch (true) {
        case isEqualWith(webhookRules, WEBHOOK_ALL_RULES):
        case isEmptyRecord(webhookRules): {
            return WEBHOOK_RULES_MODES.ALL
        }

        default: {
            return WEBHOOK_RULES_MODES.INDIVIDUAL
        }
    }
}
