import { useCallback } from 'react'
import { useField } from 'formik'
import {
    GROUP_ACCORDION_DEFAULT_WILDCARDS,
    GroupAccordionInputTypeHookProps,
    GroupAccordionSupportedInputType
} from '../../GroupAccordionInput.types'
import { composeGroupValueTuple } from '../../GroupAccordionInput.utils'
import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { anythingValidator } from '@/utils/@validators'
import { useIntl } from 'react-intl'

export function useGroupAccordionInputArray<T extends GroupAccordionSupportedInputType>({
    name,
    isDisabled,
    isReadOnly,
    isRequired,
    normalizedGroupOptions,
    validator = anythingValidator,
    wildcardSymbol = GROUP_ACCORDION_DEFAULT_WILDCARDS.ARRAY_WILDCARD
}: GroupAccordionInputTypeHookProps<T>) {
    const intl = useIntl()
    const [field, meta, helpers] = useField<string[]>({
        name,
        readOnly: isReadOnly,
        disabled: isDisabled,
        validate: validator?.(intl, { isRequired })
    })
    const { setValue } = helpers

    const handleCheckboxChange = useCallback(
        (group: string, value: string) => {
            if (!field.value) {
                return
            }

            const validateFieldsWhileChanging = true
            const composedGroupValue = composeGroupValueTuple(group, value)
            const currentSelected = [...field.value]
            const groupItems = normalizedGroupOptions[group] || []
            const hasWildcard = currentSelected.includes(composeGroupValueTuple(group, wildcardSymbol))

            switch (true) {
                /**
                 * @description
                 * Remove `wildcardSymbol` and add all values except the one being unchecked
                 */
                case hasWildcard: {
                    const computedValues: typeof field.value = [
                        ...currentSelected.filter(
                            (item) => !item.startsWith(composeGroupValueTuple(group, EMPTY_CHAR_SYMBOL))
                        ),
                        ...groupItems
                            .filter((item) => item !== value)
                            .map((item) => composeGroupValueTuple(group, item))
                    ]

                    setValue(computedValues, validateFieldsWhileChanging)
                    break
                }

                default: {
                    const index = currentSelected.indexOf(composedGroupValue)

                    if (index !== -1) {
                        currentSelected.splice(index, 1)
                    } else {
                        currentSelected.push(composedGroupValue)
                        // Check if all items are now selected
                        const allSelected = groupItems.every((item) => {
                            return item === value || currentSelected.includes(composeGroupValueTuple(group, item))
                        })

                        if (allSelected) {
                            const computedValues: typeof field.value = [
                                ...currentSelected.filter(
                                    (item) => !item.startsWith(composeGroupValueTuple(group, EMPTY_CHAR_SYMBOL))
                                ),
                                composeGroupValueTuple(group, wildcardSymbol)
                            ]

                            setValue(computedValues, validateFieldsWhileChanging)

                            return
                        }
                    }

                    setValue(currentSelected, validateFieldsWhileChanging)
                }
            }
        },
        [field.value, normalizedGroupOptions, wildcardSymbol]
    )
    const handleGroupCheckboxChange = useCallback(
        (group: string) => {
            if (!field.value) {
                return
            }

            const validateFieldsWhileChanging = true
            const composedWildcardGroupValue = composeGroupValueTuple(group, wildcardSymbol) // "group.admin"
            const composedWildcardGroupWithoutValue = composeGroupValueTuple(group, EMPTY_CHAR_SYMBOL) // "group."

            const groupItems = normalizedGroupOptions[group] || []
            const currentSelected = [...field.value]
            const hasWildcard = currentSelected.includes(composedWildcardGroupValue)
            const hasAllSelected =
                hasWildcard ||
                groupItems.every((item) => {
                    const composedGroupValue = composeGroupValueTuple(group, item)
                    return currentSelected.includes(composedGroupValue)
                })
            const allGroupValues = currentSelected.filter((item) => !item.startsWith(composedWildcardGroupWithoutValue))

            if (hasAllSelected) {
                setValue(allGroupValues, validateFieldsWhileChanging)
            } else {
                setValue([...allGroupValues, composedWildcardGroupValue], validateFieldsWhileChanging)
            }
        },
        [field.value, wildcardSymbol]
    )

    return {
        field,
        meta,
        //
        handleCheckboxChange,
        handleGroupCheckboxChange
    }
}
