import { IntlShape } from 'react-intl'
import { FieldValidator } from 'formik'
import { asOptionalField, sanitiseValidatorValue } from '../validators.utils'
import { SafeParseReturnType } from 'zod'
import { isEmpty } from 'lodash'
import { URLSchema } from './url.validator.const'
import { FormikInputConfiguration } from '@/types'

export function urlValidator(intl: IntlShape, configuration?: FormikInputConfiguration): FieldValidator {
    return (value: string) => {
        const sanitisedValue = sanitiseValidatorValue(value)
        let validationResult: SafeParseReturnType<typeof value, string | void>

        if (configuration?.isRequired) {
            validationResult = URLSchema.safeParse(sanitisedValue)
        } else {
            validationResult = asOptionalField(URLSchema).safeParse(sanitisedValue)
        }

        if (configuration?.isRequired && isEmpty(sanitisedValue)) {
            return intl.formatMessage({ id: 'app.common.form.validation.required' })
        }

        if (!validationResult.success) {
            return intl.formatMessage({ id: 'app.common.form.validation.url.invalid' })
        }
    }
}
