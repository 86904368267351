import { FieldValidator } from 'formik'
import { IntlShape } from 'react-intl'
import { SafeParseReturnType, z } from 'zod'
import { asOptionalField } from '../validators.utils'
import { FormikInputConfiguration } from '@/types'

export const emailValidator =
    (intl: IntlShape, configuration?: FormikInputConfiguration): FieldValidator =>
    (value) => {
        const schematic = z.string().email()
        let validationResult: SafeParseReturnType<typeof value, string | void>

        if (configuration?.isRequired) {
            validationResult = schematic.safeParse(value)
        } else {
            validationResult = asOptionalField(schematic).safeParse(value)
        }

        if (!validationResult.success) {
            return intl.formatMessage({ id: 'app.common.form.validation.email.invalid' })
        }
    }
