import { CloseButton, Flex, Link, Text } from '@chakra-ui/react'
import { ReactNode, useRef } from 'react'
import { If } from '@/components/@misc'

const BANNER_MESSAGE_LINK =
    'https://docs.numeral.io/changelog/strengthening-your-account-security-what-you-need-to-know'

interface MainLayoutBannerProps {
    isVisible?: boolean

    onClose?(): void
}

export const MainLayoutBanner: React.FC<MainLayoutBannerProps> = ({ isVisible, onClose }) => {
    const message = useRef<ReactNode>(
        <>
            Starting from March 3rd, multi-factor authentication (MFA) and strong passwords will be required. Learn more{' '}
            <Link href={BANNER_MESSAGE_LINK} target="_blank">
                here
            </Link>
            .
        </>
    ).current

    if (!isVisible) {
        return null
    }

    return (
        <Flex
            position="fixed"
            top="0"
            height="var(--numeral-ui-main-layout-banner-height, 44px)"
            zIndex="var(--numeral-ui-main-layout-banner-z-index, 8)"
            backgroundColor="blue.50"
            width="100vw"
            flex="1"
            justifyContent="space-between"
            alignContent="center"
            paddingX="8px"
            borderBottom="1px solid"
            borderBottomColor="gray.50">
            <Text color="gray.500" flex="1" alignSelf="center" noOfLines={1} textAlign="center">
                {message}
            </Text>
            <If condition={!!onClose}>
                <CloseButton alignSelf="center" position="relative" onClick={onClose} />
            </If>
        </Flex>
    )
}
