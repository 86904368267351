import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { FormikInputConfiguration, TupleFromObjectKeys, TupleFromObjectValues } from '@/types'
import { setTimeout } from '@/utils'
import { anythingValidator } from '@/utils/@validators/anything'
import { FieldValidator, FormikValues } from 'formik'
import { chain, identity, isString, noop } from 'lodash'
import { IntlShape } from 'react-intl'
import { ZodError, z } from 'zod'

const EMPTY_STRING_TO_UNDEFINED = z.literal(EMPTY_CHAR_SYMBOL).transform(noop)

export function parseZodErrorValidationMessage<T>(zodError?: ZodError<T>) {
    if (zodError && isString(zodError?.message)) {
        return globalThis.JSON.parse(zodError.message)?.[0]
    }
}

export function asOptionalField<T extends z.ZodTypeAny>(schema: T) {
    return schema.optional().or(EMPTY_STRING_TO_UNDEFINED)
}

export function sanitiseValidatorValue(value?: string | number): string {
    return chain<typeof value>(value).trim().escape().escapeRegExp().value()
}

export function isSameValueInterval<T>(values: TupleFromObjectValues<T>): boolean {
    const valueFrom = values?.[0]
    const valueTo = values?.[1]

    if (!valueFrom || !valueTo) {
        return false
    }

    return chain(valueFrom).isEqual(valueTo).value()
}

export function isDescendingInterval<T>(values: TupleFromObjectValues<T>): boolean {
    const valueFrom = values?.[0]
    const valueTo = values?.[1]

    if (!valueFrom || !valueTo) {
        return false
    }

    return chain(valueFrom).gt(valueTo).value()
}

export function intervalValidatorBuilder<T>(
    tuple: TupleFromObjectKeys<T>,
    validator = anythingValidator,
    transformer: <V>(value: V) => V = identity,
    wait = 300
) {
    const tupleWrapper = chain(tuple)
    const nameFrom = tupleWrapper.head().value() as string
    const nameTo = tupleWrapper.last().value() as string

    return (intl: IntlShape, configuration?: FormikInputConfiguration, values?: FormikValues): FieldValidator => {
        const isValueFromActive = configuration?.name === nameFrom
        const isValueToActive = configuration?.name === nameTo
        const labelFrom = intl.formatMessage({ id: `app.common.form.input.${nameFrom}.label` })
        const labelTo = intl.formatMessage({ id: `app.common.form.input.${nameTo}.label` })

        return (value: string) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const transformedValueFrom = transformer(values?.[nameFrom])
                    const transformedValueTo = transformer(values?.[nameTo])
                    const valueTuple = [transformedValueFrom, transformedValueTo]
                    const hasDescendingInterval = isDescendingInterval<T>(valueTuple)

                    switch (true) {
                        case isValueFromActive && hasDescendingInterval: {
                            return resolve(
                                intl.formatMessage(
                                    { id: 'app.common.form.validation.interval.ste.invalid' },
                                    { otherField: labelTo }
                                )
                            )
                        }

                        case isValueToActive && hasDescendingInterval: {
                            return resolve(
                                intl.formatMessage(
                                    { id: 'app.common.form.validation.interval.gte.invalid' },
                                    { otherField: labelFrom }
                                )
                            )
                        }

                        default: {
                            const validateFn = validator?.(intl, configuration, values)
                            const validationResult = validateFn(value)

                            return resolve(validationResult)
                        }
                    }
                }, wait)
            })
        }
    }
}
