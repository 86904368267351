import { APIValidationErrorNotificationBody } from '@/components/@misc'
import { useMutationCreateClaim, useNavigateWithLegalEntityID, useNavigationRoutes, useToasts } from '@/hooks'
import { TOAST_VARIANTS } from '@/providers'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    UseDisclosureProps
} from '@chakra-ui/react'
import {
    ClaimCreateData,
    ClaimRelatedPaymentType,
    ClaimType,
    IncomingPayment,
    PaymentOrder,
    Uuid
} from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

interface ClaimCreateConfirmationModalProps extends UseDisclosureProps {
    type: ClaimType
    payment?: PaymentOrder | IncomingPayment
}

export const ClaimCreateConfirmationModal: React.FC<ClaimCreateConfirmationModalProps> = ({
    type,
    payment,
    isOpen,
    onClose = noop
}) => {
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const cancelRef = useRef(null)
    const mutation = useMutationCreateClaim({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const path = [paths.INVESTIGATIONS.CLAIMS, response.id].join('/')

            navigateWithLegalEntityID(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.investigations.claims.create.form.validation.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.investigations.claims.create.form.validation.success.toast.description" />
                )
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.investigations.claims.create.form.validation.error.toast.title" />,
                description: (
                    <APIValidationErrorNotificationBody<ClaimCreateData>
                        error={error}
                        messageId="app.investigations.claims.create.form.validation.error.toast.description"
                    />
                )
            })
        }
    })
    const onCloseWithLoading = useCallback(() => {
        if (mutation.isPending) {
            return
        }

        onClose()
    }, [mutation, onClose])

    const onCreateClaim = useCallback(() => {
        const data: ClaimCreateData = {
            connected_account_id: payment?.connected_account_id as Uuid,
            related_payment_id: payment?.id as Uuid,
            related_payment_type: payment?.object as ClaimRelatedPaymentType,
            type
        }

        mutation.mutate(data)
    }, [mutation, payment, type])
    const formattedClaimType = <FormattedMessage id={`api.claim.type.${type}`} />

    return (
        <AlertDialog isOpen={isOpen as boolean} leastDestructiveRef={cancelRef} onClose={onCloseWithLoading}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <FormattedMessage
                            id="app.investigations.claims.create_modal.title"
                            values={{
                                type: formattedClaimType
                            }}
                        />
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <FormattedMessage
                            id="app.investigations.claims.create_modal.description"
                            values={{
                                type: formattedClaimType
                            }}
                        />
                    </AlertDialogBody>
                    <AlertDialogFooter gap="16px">
                        <Button
                            variant="secondary"
                            ref={cancelRef}
                            onClick={onCloseWithLoading}
                            disabled={mutation.isPending}>
                            <FormattedMessage id="app.common.actions.close" />
                        </Button>
                        <Button onClick={onCreateClaim} isLoading={mutation.isPending}>
                            <FormattedMessage id="app.investigations.claims.create_modal.button.primary.label" />
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
