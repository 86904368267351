import { Form, FormContainer, FormPageFooter } from '@/components'
import { TextInput, UserRoleInput } from '@/components/@inputs'
import { useNavigateWithLegalEntityID, useNavigationRoutes } from '@/hooks'
import { UserInviteRequest } from '@/services'
import { emailValidator } from '@/utils/@validators'
import { Box } from '@chakra-ui/react'
import { noop } from 'lodash'
import { useCallback, useRef } from 'react'
import { UserInviteFormStickyFooter, UserInviteRoleUserPermissions } from './@components'
import {
    propsFormContainer,
    propsRolesAndPermissionsContainerCommon,
    propsRolesAndPermissionsContainerRight
} from './UserInvite.form.const'
import { getUserInviteFormInitialValues, transformUserInviteFormData } from './UserInvite.form.utils'

interface UsersInviteFormProps {
    loading?: boolean
    disabled?: boolean
    readonly?: boolean
    onSubmit?(values: UserInviteRequest): void
}

export const UserInviteForm: React.FC<UsersInviteFormProps> = ({ loading, disabled, onSubmit = noop }) => {
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths } = useNavigationRoutes()
    const initialValues = useRef(getUserInviteFormInitialValues()).current
    const redirectToUserList = useCallback(() => {
        navigateWithLegalEntityID(paths.SETTINGS.USERS)
    }, [navigateWithLegalEntityID, paths])
    const onSubmitWithTransform = useCallback(
        (values: any) => {
            const transformedValues = transformUserInviteFormData(values)
            onSubmit(transformedValues)
        },
        [onSubmit]
    )

    return (
        <Form<UserInviteRequest> initialValues={initialValues} onSubmit={onSubmitWithTransform} maxWidth="unset">
            {({ values, dirty, handleSubmit }) => {
                return (
                    <>
                        <FormContainer>
                            <TextInput
                                name="email"
                                customPlaceholderKey="app.settings.users.invite.form.inputs.email.placeholder"
                                validator={emailValidator}
                                isRequired={true}
                                isDisabled={disabled}
                            />
                        </FormContainer>
                        <FormContainer {...propsFormContainer}>
                            <Box {...propsRolesAndPermissionsContainerCommon}>
                                <UserRoleInput name="role" isDisabled={disabled} isRequired={true} />
                            </Box>
                            {values.role && (
                                <Box
                                    {...propsRolesAndPermissionsContainerCommon}
                                    {...propsRolesAndPermissionsContainerRight}>
                                    <UserInviteRoleUserPermissions roleId={values.role} />
                                </Box>
                            )}
                        </FormContainer>
                        <UserInviteFormStickyFooter>
                            <FormPageFooter
                                onSubmit={handleSubmit}
                                submitLabelMessageId="app.settings.users.invite.form.actions.submit.label"
                                onCancel={redirectToUserList}
                                isDisabled={disabled || !dirty}
                                isLoading={loading}
                                showUnsavedChangesWarning={true}
                                margin="unset"
                            />
                        </UserInviteFormStickyFooter>
                    </>
                )
            }}
        </Form>
    )
}
