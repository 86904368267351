import { isFileBlob } from '@/utils'
import { InternalAxiosRequestConfig } from 'axios'

export async function blobToTextRequestInterceptor(config: InternalAxiosRequestConfig) {
    if (isFileBlob(config?.data)) {
        config.data = await config.data?.text()
    }

    return config
}
