import {
    GROUP_ACCORDION_DEFAULT_WILDCARDS,
    GroupAccordionSupportedInputType,
    GroupItemType,
    GroupOptions,
    GroupRecordType,
    GroupStats
} from './GroupAccordionInput.types'
import { chain, isArray, isObject } from 'lodash'
import { DOT_SYMBOL } from '@/constants'
import { Nullable } from '@/types'
import React, { ReactNode } from 'react'

export function isArrayItemChecked(
    group: string,
    value: string,
    values?: GroupItemType,
    wildcardSymbol?: string
): boolean {
    if (!isArray(values)) {
        return false
    }

    const composedGroupValue = composeGroupValueTuple(group, value)
    const composedWildcardGroupValue = composeGroupValueTuple(group, wildcardSymbol) // "group.admin"

    return values.includes(composedWildcardGroupValue) || values.includes(composedGroupValue)
}

export function isRecordItemChecked<T>(
    group: string,
    value: string,
    values?: GroupRecordType,
    wildcardSymbol?: string
): boolean {
    if (!isObject(values) || !wildcardSymbol) {
        return false
    }

    // Check global wildcard first (order is important)
    if (values?.[wildcardSymbol]?.includes(wildcardSymbol)) {
        return true
    }

    // Check group wildcard or specific value
    return (values[group]?.includes(wildcardSymbol) || values[group]?.includes(value)) ?? false
}

export function isItemChecked(
    group: string,
    value: string,
    values?: GroupAccordionSupportedInputType,
    wildcardSymbol?: string
): boolean {
    if (!values) {
        return false
    }

    switch (true) {
        case isArray(values): {
            return isArrayItemChecked(group, value, values as GroupItemType[], wildcardSymbol)
        }

        case isObject(values): {
            return isRecordItemChecked(group, value, values as GroupRecordType, wildcardSymbol)
        }

        default: {
            return false
        }
    }
}

export function isGroupAccordionInputWildcard(value: string, customWildCardSymbol?: string): boolean {
    switch (true) {
        case value === GROUP_ACCORDION_DEFAULT_WILDCARDS.ARRAY_WILDCARD:
        case value === GROUP_ACCORDION_DEFAULT_WILDCARDS.RECORD_WILDCARD:
        case value === customWildCardSymbol: {
            return true
        }

        default: {
            return false
        }
    }
}

export function extractGroupOptions(options?: GroupAccordionSupportedInputType, groupOrder?: string[]): string[] {
    let groups: string[] = []

    switch (true) {
        case isArray(options): {
            groups = [...new Set(options.map((item) => item.toString().split(DOT_SYMBOL)[0]))]
            break
        }

        case isObject(options): {
            groups = Object.keys(options)
            break
        }
    }

    if (!groupOrder) {
        return groups.sort()
    }

    return groupOrder.filter((group) => groups.includes(group))
}

export function normalizeGroupOptions(
    options?: Nullable<string | { toString(): string } | GroupRecordType> | undefined,
    optionsFilter?: (option: string, index: number) => boolean
): GroupOptions {
    switch (true) {
        case isArray(options): {
            return normalizeArrayInputOptions(options, optionsFilter)
        }

        case isObject(options): {
            return normalizeRecordInputOptions(options, optionsFilter)
        }

        default: {
            return Object.create(null)
        }
    }
}

export function normalizeArrayInputOptions(
    options?: GroupAccordionSupportedInputType,
    optionsFilter?: (option: string, index: number) => boolean
): GroupOptions {
    const groupedItems: GroupOptions = Object.create(null)

    if (!isArray(options)) {
        return groupedItems
    }

    const filteredOptions = optionsFilter ? options.filter(optionsFilter) : options
    return filteredOptions.reduce((acc, item) => {
        const [prefix, value] = item.toString().split(DOT_SYMBOL)
        if (!acc[prefix]) {
            acc[prefix] = []
        }
        acc[prefix].push(value)
        return acc
    }, groupedItems)
}

export function normalizeRecordInputOptions(
    options?: GroupAccordionSupportedInputType,
    optionsFilter?: (option: string, index: number) => boolean
): GroupOptions {
    const groupedItems: GroupOptions = Object.create(null)

    if (!isObject(options)) {
        return groupedItems
    }

    return Object.entries(options).reduce((acc, [group, items]) => {
        if (items) {
            acc[group] = optionsFilter ? items.filter(optionsFilter) : items
        }

        return acc
    }, groupedItems)
}

export function computeGroupInputStats(
    selectedValues?: GroupAccordionSupportedInputType,
    availableGroups?: string[],
    groupOptions?: GroupOptions,
    computedWildcardSymbol?: string,
    isItemCheckedPredicate?: (
        group: string,
        item: string,
        values: typeof selectedValues,
        wildcardSymbol: typeof computedWildcardSymbol
    ) => boolean
): GroupStats {
    if (!availableGroups) {
        return Object.create(null) as GroupStats
    }

    return availableGroups?.reduce(
        (acc, group) => {
            const groupItems = groupOptions?.[group] || []
            const total = groupItems.length
            const selected = groupItems.filter((item) =>
                isItemCheckedPredicate?.(group, item, selectedValues, computedWildcardSymbol)
            ).length

            acc[group] = {
                total: total ?? 0,
                selected: selected ?? 0
            }

            return acc
        },
        Object.create(null) as GroupStats
    )
}

export function composeGroupValueTuple(group?: string, value?: string) {
    return [group, value].join(DOT_SYMBOL)
}

export function isGroupOptionDescriptionAvailable(groupOptionDescription: React.JSX.Element | ReactNode): boolean {
    return chain(React.Children).invoke('count', groupOptionDescription).size().gt(0).value()
}
