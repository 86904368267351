import { getUserFullName, PERMISSION } from '@/services'
import { usePermissions, useQueryFindByIdUser } from '@/hooks'
import { Uuid } from '@webapps/numeral-ui-core'
import { useIntl } from 'react-intl'
import { useMemo } from 'react'
import { EMPTY_VALUE_PLACEHOLDER, SPACE_SYMBOL } from '@/constants'
import { DetailPageLink } from '@/components/@misc'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { Skeleton, Text } from '@chakra-ui/react'

interface TableCellAuditTrailDashboardAuthorProps {
    objectId: Uuid
}

export const TableCellAuditTrailDashboardAuthor: React.FC<TableCellAuditTrailDashboardAuthorProps> = ({ objectId }) => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdUser(objectId, {
        enabled: hasPermission(PERMISSION.SETTINGS_VIEW_USERS)
    })
    const fullName = useMemo<string>(() => {
        const emptyPlaceholder = intl.formatMessage({ id: 'app.table.cell.user_full_name_placeholder' })
        return getUserFullName(query.data, emptyPlaceholder)
    }, [intl, query.data])

    if (!query.data) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Skeleton isLoaded={!query.isLoading}>
            <Text noOfLines={1} title={fullName}>
                {fullName}
                {SPACE_SYMBOL}
                {'('}
                <DetailPageLink objectId={objectId} objectType={ApiObjectTypeExtendedSchema.enum.user}>
                    {query.data?.email}
                </DetailPageLink>
                {')'}
            </Text>
        </Skeleton>
    )
}
