import { Uuid } from '@webapps/numeral-ui-core'
import { useQueryFindByIdRole } from '@/hooks'
import { GroupAccordionReadonlyField } from '@/components/@inputs'
import { hasNonAdminPermissionFilter, PERMISSION, PERMISSIONS_GROUPS } from '@/services'
import { useIntl } from 'react-intl'
import { useMemo } from 'react'

export const UserInviteRoleUserPermissions: React.FC<{ roleId: Uuid }> = ({ roleId }) => {
    const intl = useIntl()
    const query = useQueryFindByIdRole(roleId)
    const availablePermissions = useMemo(() => Object.values(PERMISSION), [])
    const availablePermissionsGroups = useMemo(() => Object.values(PERMISSIONS_GROUPS), [])
    const getGroupLabel = (group: string) => {
        return intl.formatMessage({
            id: `app.settings.roles.form.permissions.groups.${group}`,
            defaultMessage: group
        })
    }
    const getGroupOptionLabel = (group: string, value: string, selected: number, available: number) => {
        return intl.formatMessage({
            id: `app.settings.roles.form.permissions.${group}`,
            defaultMessage: value
        })
    }
    const getGroupCounterLabel = (group: string, selected: number, available: number) => {
        return intl.formatMessage(
            {
                id: 'app.settings.roles.form.permissions.group_number_of_permissions.label',
                defaultMessage: group
            },
            {
                selected,
                available
            }
        )
    }

    return (
        <GroupAccordionReadonlyField<PERMISSION>
            name="permissions"
            value={query.data?.permissions}
            options={availablePermissions}
            groupOrder={availablePermissionsGroups}
            getGroupLabel={getGroupLabel}
            getGroupOptionLabel={getGroupOptionLabel}
            getGroupCounterLabel={getGroupCounterLabel}
            optionsFilter={hasNonAdminPermissionFilter}
            showLabelAsReadOnly={false}
        />
    )
}
