import { Text } from '@chakra-ui/react'
import { OptionProps } from 'react-select/dist/declarations/src/components/Option'
import { get } from 'lodash'
import { SelectOption } from '@/components/@misc/Select/@components'
import { InternalAccountType } from '@webapps/numeral-ui-core'
import { FormattedMessage } from 'react-intl'

export const InternalAccountTypeInputSelectOption: React.FC<OptionProps<InternalAccountType>> = ({
    children,
    innerRef,
    innerProps,
    ...props
}) => {
    const name = get(props, 'data.original.name')
    const value = get(props, 'value')

    return (
        <SelectOption innerRef={innerRef} innerProps={innerProps} {...props}>
            <Text color="gray.700" noOfLines={1} title={name}>
                {children}
            </Text>
            <Text color="gray.400" fontSize="small">
                <FormattedMessage
                    id={`app.counterparties.internal_accounts.create.form.inputs.type.${value}.description`}
                    defaultMessage={value}
                />
            </Text>
        </SelectOption>
    )
}
