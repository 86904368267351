import { COMMA_SYMBOL, DOT_SYMBOL } from '@/constants'
import { useCustomFormikField } from '@/hooks'
import { FormikInput } from '@/types'
import { amountValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel, Input, Tooltip } from '@chakra-ui/react'
import { CurrencyCode } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { noop } from 'lodash'
import { useCallback, useMemo } from 'react'
import { IMask, IMaskInput } from 'react-imask'
import { FormattedMessage, useIntl } from 'react-intl'
import { getAmountInputPlaceholder, getAmountScale } from './AmountInput.utils'

export const AmountInput: React.FC<FormikInput> = ({
    name,
    value,
    customPlaceholderKey,
    validator = amountValidator,
    isRequired = false,
    ...inputProps
}) => {
    const intl = useIntl()
    const { values } = useFormikContext<{ currency?: CurrencyCode }>()
    const [field, meta, helpers] = useCustomFormikField<string>({
        name,
        value,
        validate: validator?.(intl, { isRequired, name }, values)
    })
    const selectedCurrency: CurrencyCode | undefined = values['currency']

    const placeholder = useMemo<string>(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey })
        }

        return getAmountInputPlaceholder(intl, selectedCurrency, inputProps.placeholder)
    }, [customPlaceholderKey, intl, selectedCurrency, inputProps.placeholder])

    const tooltip = useMemo<string>(() => {
        return intl.formatMessage({ id: 'app.common.form.input.amount.tooltip.content' })
    }, [intl])

    const isDisabled = useMemo<boolean>(() => {
        return inputProps.isDisabled || !selectedCurrency
    }, [inputProps, selectedCurrency])

    const onAccept = useCallback(
        (value: string) => {
            if (value !== field.value) {
                helpers.setValue(value, true)
            }
        },
        [field.value, helpers]
    )

    const getIMaskInput = useCallback(
        (props: any) => {
            const amountScale = getAmountScale(selectedCurrency)
            return <IMaskInput {...props} scale={amountScale} />
        },
        [selectedCurrency]
    )

    return (
        <Tooltip key={name} isDisabled={!isDisabled} label={tooltip} placement="top" hasArrow={true}>
            <FormControl isInvalid={meta.touched && !!meta.error}>
                <FormLabel htmlFor={name}>
                    <FormattedMessage id={`app.common.form.input.${name}.label`} />
                </FormLabel>
                <Input
                    as={getIMaskInput} // Scale must be provided here, otherwise it gets intercepted by the Chakra Input
                    {...inputProps}
                    {...field}
                    id={name}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    isRequired={isRequired}
                    mask={IMask.MaskedNumber}
                    unmask={true}
                    min={0}
                    thousandsSeparator={COMMA_SYMBOL}
                    radix={DOT_SYMBOL}
                    mapToRadix={[COMMA_SYMBOL, DOT_SYMBOL]}
                    onChange={noop}
                    onAccept={onAccept}
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
        </Tooltip>
    )
}
