import { SearchIcon } from '@/components/@icons'
import { ParentPageLayout } from '@/layouts'
import {
    AuditTrailFilterByOption,
    AuditTrailPage,
    ClaimDetailsPage,
    ClaimHeader,
    ClaimRejectPage,
    ClaimsPage,
    EventsPage,
    InquiriesPage,
    InquiryDenyPage,
    InquiryDetailsHeader,
    InquiryDetailsPage,
    ReturnRequestDenyPage,
    ReturnRequestDetailsHeader,
    ReturnRequestDetailsPage,
    ReturnRequestsPage
} from '@/pages'
import { getNestedRoutesWithTabLayout, NavigationRoute } from '@/providers'
import { ENTITY_FEATURE } from '@/services'
import { PaymentType, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { Navigate } from 'react-router-dom'
import { NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS } from '../NavigationRoutesProvider.const'

function getCommonInvestigationRoutes(paymentType: PaymentType) {
    const { INVESTIGATIONS } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
    return [
        {
            title: 'app.investigations.tabs.events.title',
            path: INVESTIGATIONS.EVENTS,
            element: <EventsPage />
        },
        {
            title: 'app.investigations.tabs.audit_trail.title',
            path: INVESTIGATIONS.AUDIT_TRAIL,
            element: <AuditTrailPage filterBy={AuditTrailFilterByOption.OBJECT} />
        }
    ]
}

export function getInvestigationsNavigationRoutes(): NavigationRoute {
    const { UUID, INVESTIGATIONS, CANCEL, DENY, REJECT } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
    const returnRequestsRoutes = [
        {
            title: 'app.investigations.tabs.details.title',
            path: INVESTIGATIONS.DETAILS,
            element: <ReturnRequestDetailsPage />
        },
        ...getCommonInvestigationRoutes(PaymentTypeSchema.enum.return_request)
    ]
    const inquiriesRoutes = [
        {
            title: 'app.investigations.tabs.details.title',
            path: INVESTIGATIONS.DETAILS,
            element: <InquiryDetailsPage />
        },
        ...getCommonInvestigationRoutes(PaymentTypeSchema.enum.inquiry)
    ]
    const claimsRoutes = [
        {
            title: 'app.investigations.tabs.details.title',
            path: INVESTIGATIONS.DETAILS,
            element: <ClaimDetailsPage />
        },
        ...getCommonInvestigationRoutes(PaymentTypeSchema.enum.claim)
    ]

    return {
        title: 'app.investigations.title',
        path: 'investigations',
        configuration: {
            isGroup: true,
            /**
             * @description
             * @temporary
             * Applies if all nested sections are for financial institutions / remove otherwise.
             */
            isForFinancialInstitution: true
        },
        icon: <SearchIcon />,
        routes: [
            { index: true, element: <Navigate replace={true} to="return-requests" /> },
            {
                title: 'app.investigations.return_requests.title',
                path: 'return-requests',
                configuration: {
                    isForFinancialInstitution: true
                },
                element: <ParentPageLayout element={<ReturnRequestsPage />} />,
                routes: [
                    {
                        title: 'app.investigations.return_requests.deny.title',
                        path: CANCEL,
                        element: <ReturnRequestDenyPage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, returnRequestsRoutes, {
                        header: <ReturnRequestDetailsHeader />
                    })
                ]
            },
            {
                title: 'app.investigations.claims.title',
                path: 'claims',
                element: <ParentPageLayout element={<ClaimsPage />} />,
                configuration: {
                    isForFinancialInstitution: true,
                    feature: ENTITY_FEATURE.CLAIM
                },
                routes: [
                    {
                        title: 'app.investigations.claims.reject.title',
                        path: REJECT,
                        element: <ClaimRejectPage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, claimsRoutes, {
                        header: <ClaimHeader />
                    })
                ]
            },
            {
                title: 'app.investigations.inquiries.title',
                path: 'inquiries',
                configuration: {
                    isForFinancialInstitution: true
                },
                element: <ParentPageLayout element={<InquiriesPage />} />,
                routes: [
                    {
                        title: 'app.investigations.inquiries.deny.title',
                        path: DENY,
                        element: <InquiryDenyPage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, inquiriesRoutes, {
                        header: <InquiryDetailsHeader />
                    })
                ]
            }
        ]
    }
}
