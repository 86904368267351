import './Main.layout.scss'
import { useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { MainNavigation } from '@/components'
import { Box } from '@chakra-ui/react'
import classNames from 'classnames'
import { MAIN_LAYOUT_PORTAL_CONTAINER_ID, MAIN_LAYOUT_STORAGE_KEYS } from '@/layouts'
import { MainLayoutBanner, MainLayoutEnvironmentOverlay } from './@components'
import { useToggle } from '@/hooks'

export const MainLayout: React.FC = () => {
    const [hasBanner, onToggleBanner] = useToggle({
        initialState: true,
        storageKey: MAIN_LAYOUT_STORAGE_KEYS.BANNER_VISIBLE
    })
    const [isCollapsed, onToggleNav] = useToggle({
        initialState: false,
        storageKey: MAIN_LAYOUT_STORAGE_KEYS.NAV_COLLAPSED
    })
    const navContainerClassName = useMemo(() => {
        return classNames('MainLayout-NavigationContainer', {
            Banner: hasBanner
        })
    }, [hasBanner])
    const contentClassName = useMemo(() => {
        return classNames('MainLayout-Content', {
            Collapsed: isCollapsed,
            Banner: hasBanner
        })
    }, [isCollapsed, hasBanner])
    const portalContainerClassName = useMemo(() => {
        return classNames('MainLayout-Portal', {
            Collapsed: isCollapsed,
            Bottom: true
        })
    }, [isCollapsed])

    return (
        <>
            <Box className="MainLayout" data-testid="main-layout">
                <MainLayoutBanner isVisible={hasBanner} onClose={onToggleBanner} />
                <MainLayoutEnvironmentOverlay isCollapsed={isCollapsed} />
                <nav className={navContainerClassName} data-testid="main-layout-navigation-container">
                    <MainNavigation isCollapsed={isCollapsed} onToggleNavigation={onToggleNav} />
                </nav>
                <Box className={contentClassName}>
                    <Outlet />
                </Box>
            </Box>
            <Box
                className={portalContainerClassName}
                id={MAIN_LAYOUT_PORTAL_CONTAINER_ID}
                data-testid={MAIN_LAYOUT_PORTAL_CONTAINER_ID}
            />
        </>
    )
}
