import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { ChakraProps } from '@chakra-ui/system'
import { prettyPrintObject } from '@webapps/numeral-ui-core'
import { chain, identity } from 'lodash'
import { FormattedMessage } from 'react-intl'
import {
    EntityDetailRow,
    EntityDetailRowCustomizers,
    EntityRowCustomizer,
    EntityRowCustomizerDef
} from './EntityDetail.types'

export function getDefaultEntityDetailRowCustomizer<T, C = void>(
    dataKey: keyof T & keyof C
): EntityRowCustomizerDef<T, C> {
    return {
        label(item) {
            return <FormattedMessage id={`app.page.details.fields.${dataKey as string}.label`} />
        },
        cell(item) {
            const rawValueWrapper = chain(item?.[dataKey])
            const rawValue = rawValueWrapper.value()
            const normalisedObjectLikeRowData = normaliseObjectLikeRowData<T>(rawValue as T)

            const isRawValueObjectLike = rawValueWrapper.isObjectLike().value()
            const isRawValueBoolean = rawValueWrapper.isBoolean().value()
            const isRawValueString = rawValueWrapper.isString().value()
            const isEmpty = chain(normalisedObjectLikeRowData).isEmpty().value()

            switch (true) {
                case isRawValueObjectLike && isEmpty: {
                    return EMPTY_VALUE_PLACEHOLDER
                }

                case isRawValueObjectLike: {
                    return prettyPrintObject(normalisedObjectLikeRowData)
                }

                case isRawValueBoolean: {
                    return prettyPrintObject(rawValue)
                }

                case isRawValueString:
                default: {
                    return rawValueWrapper.value() as string
                }
            }
        }
    }
}

export function getEntityDetailRowsWithCustomizer<T, C = void>(
    data?: Partial<T>,
    rows?: (EntityDetailRow<T, C> | EntityDetailRow<T[keyof T], C[keyof C]>)[],
    customizers?:
        | EntityDetailRowCustomizers<T>
        | EntityDetailRowCustomizers<C>
        | EntityDetailRowCustomizers<T, C>
        | EntityDetailRowCustomizers<T[keyof T], C[keyof C]>,
    autogenerated = true
): EntityRowCustomizer<T>[] {
    const hasAutogeneratedData = !rows?.length && autogenerated && !!data
    const source = hasAutogeneratedData ? Object.keys(data) : rows
    const isArrayLike = chain(data).isArrayLike().value()

    const customizerMapper = (dataKey: keyof T & keyof C): EntityRowCustomizer<T, C> => {
        const rowCustomizer = Object.create(null)
        const defaultRowCustomizer = getDefaultEntityDetailRowCustomizer<T, C>(dataKey)
        const customizer = chain(customizers).get(dataKey).value()
        const mergedCustomizer = chain(defaultRowCustomizer).mergeWith(customizer).value()
        const label = chain(mergedCustomizer).invoke('label', data).value()
        let cell = chain(mergedCustomizer).invoke('cell', data).value()

        const cellWrapper = chain(cell)

        if (!cell || (cellWrapper.isObjectLike().value() && cellWrapper.isEmpty().value())) {
            cell = EMPTY_VALUE_PLACEHOLDER
        }

        if (customizer?.nested) {
            rowCustomizer.nested = customizer?.nested
        }

        if (customizer?.rows) {
            rowCustomizer.rows = customizer?.rows
        }

        if (customizer?.rowCustomizers) {
            rowCustomizer.rowCustomizers = customizer?.rowCustomizers
        }

        if (customizer?.autogenerated) {
            rowCustomizer.autogenerated = customizer?.autogenerated
        }

        rowCustomizer.label = isArrayLike ? undefined : hasAutogeneratedData ? dataKey : label

        rowCustomizer.cell = cell
        rowCustomizer.original = data ?? {}
        rowCustomizer.accessorKey = dataKey

        return rowCustomizer
    }

    // @ts-ignore TBD
    return source?.map(customizerMapper)
}

export function getRowLabelStyle(style?: ChakraProps): ChakraProps {
    return {
        fontWeight: 'medium',
        verticalAlign: 'baseline',
        color: 'gray.500',
        width: '300px',
        minWidth: '160px',
        ...style
    }
}

export function getRowCellStyle(style?: ChakraProps): ChakraProps {
    return {
        color: 'gray.800',
        ...style
    }
}

export function isNestedRowAndHasData<T>(row: EntityRowCustomizer<T>): boolean {
    const isCustomRow = !Object.prototype.hasOwnProperty.call(row.original, row?.accessorKey)
    const rowData = chain(row).get(`original.${row?.accessorKey}`).value()
    const normalisedData = normaliseObjectLikeRowData(rowData)
    return !!row?.nested && (isCustomRow || globalThis.Object.values(normalisedData).length > 0)
}

export function getRowTestIds<T>(row: EntityRowCustomizer<T>) {
    const accessorKey = row?.accessorKey as string
    return [`${accessorKey}__label`, `${accessorKey}__value`]
}

export function normaliseObjectLikeRowData<T>(data?: T) {
    const rowWrapper = chain<any>(data)

    if (rowWrapper.isArray().value()) {
        return rowWrapper.filter(identity).value()
    }

    if (rowWrapper.isObjectLike().value()) {
        return rowWrapper.pickBy(identity).value()
    }

    return Object.create({})
}
