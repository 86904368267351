import { QueryFallback, RestrictedSection } from '@/components'
import {
    useMutationRejectByIdClaim,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useQueryFindByIdClaim
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { updateUUIDRelativeActionInPathname } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ClaimStatusSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'
import { ClaimRejectForm } from './@components'

export const ClaimRejectPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.investigations.claims.reject.title' }), [intl])
    const query = useQueryFindByIdClaim(uuid)
    const mutation = useMutationRejectByIdClaim(uuid, {
        onSuccess() {
            const { INVESTIGATIONS } = relativePaths
            const path = updateUUIDRelativeActionInPathname(location.pathname, INVESTIGATIONS.DETAILS)

            navigateWithLegalEntityID(path)
        }
    })
    const isDataEmpty = useMemo(() => isEmpty(query?.data), [query])
    const isDisabled = useMemo<boolean>(() => {
        const canRejectClaim = query.data?.status === ClaimStatusSchema.enum.sent
        const isLoading = query.isLoading || mutation.isPending
        return !canRejectClaim || isLoading
    }, [query, mutation])

    return (
        <PageLayout className="ClaimReject">
            <PageHeaderLayout title={title} />
            <RestrictedSection feature={ENTITY_FEATURE.CLAIM} permission={PERMISSION.INVESTIGATIONS_VIEW_CLAIMS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.claim}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <ClaimRejectForm onSubmit={mutation.mutate} loading={mutation.isPending} disabled={isDisabled} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
