import { addDays, differenceInMonths } from 'date-fns'

/**
 * Converts a number of days into months using `date-fns`.
 * @returns The number of months between the start date and the calculated end date.
 */
export function daysToMonths(startDate: Date, days: number): number {
    const endDate = addDays(startDate, days)
    return differenceInMonths(endDate, startDate)
}
