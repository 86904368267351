import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { prettyPrintCasedWords } from '@/utils'
import { Tag } from '@chakra-ui/react'
import { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { StatusLabelProps } from './StatusLabel.types'
import { computeStatusLabelProps } from './StatusLabel.utils'

export const StatusLabel: React.FC<StatusLabelProps> = memo(({ value, ...props }) => {
    const intl = useIntl()
    const { labelId, color, backgroundColor } = useMemo(() => {
        return computeStatusLabelProps(value)
    }, [value])
    const formattedLabel = useMemo(() => {
        const prettyPrintedValue = prettyPrintCasedWords(value)

        if (labelId) {
            return intl.formatMessage({
                id: labelId,
                defaultMessage: prettyPrintedValue
            })
        }

        return prettyPrintedValue
    }, [intl, labelId, value])

    if (!value) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Tag
            title={formattedLabel}
            color={color}
            backgroundColor={backgroundColor}
            aria-label={formattedLabel}
            overflow="hidden"
            borderRadius="var(--numeral-ui-primary-border-radius)"
            {...props}>
            {formattedLabel}
        </Tag>
    )
})
