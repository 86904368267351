import { useMutationRevokeByIdAPIKey, useNavigateWithLegalEntityID, useNavigationRoutes } from '@/hooks'
import { APIKey } from '@/services'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

export function useAPIKeyActions(item?: APIKey) {
    const { uuid } = useParams()
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const revokeAPIKeyMutation = useMutationRevokeByIdAPIKey(uuid || item?.id)
    const isDisabled = useMemo(() => !item || !!item?.disabled_at, [item])
    const { relativePaths } = useNavigationRoutes()

    if (isDisabled) {
        return []
    }

    return [
        {
            onClick() {
                navigateWithLegalEntityID(relativePaths.DEVELOPERS.EDIT)
            },
            children: intl.formatMessage({
                id: 'app.developers.api_keys.edit.actions.edit.label'
            })
        },
        {
            onClick() {
                revokeAPIKeyMutation.mutate()
            },
            children: intl.formatMessage({
                id: 'app.developers.api_keys.edit.actions.disable.label'
            }),
            color: 'red.500',
            disabled: isDisabled || revokeAPIKeyMutation.isPending,
            isLoading: revokeAPIKeyMutation.isPending
        }
    ]
}
