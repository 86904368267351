import { useCallback, useEffect, useRef, useState } from 'react'
import { usePrevious } from '@/hooks'
import { StorageService } from '@/services'

/**
 * @description
 * Custom hook for managing global namespace toggle state with optional localStorage persistence
 */
export function useToggle({ initialState = false, storageKey = '' }): [boolean, () => void] {
    const isFirstMount = useRef(true)
    const previousStorageKey = usePrevious(storageKey)
    const [state, setState] = useState<boolean>(() => {
        if (!storageKey) {
            return initialState
        }

        try {
            const storedValue = StorageService.getItem(storageKey)
            return !storedValue ? initialState : JSON.parse(storedValue)
        } catch (error) {
            console.warn(`Error reading from storage for key ${storageKey}:`, error)
            return initialState
        }
    })
    const toggle = useCallback(() => {
        setState((prevState) => {
            const newState = !prevState

            if (storageKey) {
                try {
                    StorageService.setItem(storageKey, JSON.stringify(newState))
                } catch (error) {
                    console.warn(`Error writing to storage for key ${storageKey}:`, error)
                }
            }
            return newState
        })
    }, [storageKey])

    useEffect(() => {
        if (isFirstMount.current) {
            isFirstMount.current = false
            return
        }

        if (storageKey !== previousStorageKey && storageKey) {
            try {
                StorageService.setItem(storageKey, JSON.stringify(state))
            } catch (error) {
                console.warn(`Error updating storage for key change:`, error)
            }
        }
    }, [storageKey, state])

    return [state, toggle]
}
