import { GroupAccordionInputProps } from '@/components/@inputs/GroupAccordionInput'
import { WebhookRules } from '@/services'
import { WEBHOOK_EVENT_OPTIONS_ORDER } from './WebhookRulesInput.const'
import { GroupAccordionInput, GroupAccordionReadonlyField } from '@/components/@inputs'
import { prettyPrintCasedWords } from '@/utils'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQueryFindAllWebhookTopicsAndTypes } from '@/hooks'
import { useMemo } from 'react'
import {
    hasDeprecatedWebhookEventOptionLabelWithIntl,
    reorderWebhookEventOptionsWithData
} from './WebhookRulesInput.utils'
import { SPACE_SYMBOL } from '@/constants'
import { Flex, Tag } from '@chakra-ui/react'

export const WebhookRulesInput: React.FC<GroupAccordionInputProps<WebhookRules>> = (props) => {
    const intl = useIntl()
    const query = useQueryFindAllWebhookTopicsAndTypes()
    const getGroupLabel = (group: string, selected: number, available: number) => {
        const formattedGroupLabel = prettyPrintCasedWords(group)
        const hasDeprecatedGroupLabel = hasDeprecatedWebhookEventOptionLabelWithIntl(intl)(group)

        if (hasDeprecatedGroupLabel) {
            return (
                <Flex>
                    {formattedGroupLabel}
                    <Tag
                        marginX="calc(var(--numeral-ui-primary-spacing)/2)"
                        variant="subtle"
                        backgroundColor="gray.100">
                        <FormattedMessage
                            id={`app.developers.webhooks.form.rules.${group}.label`}
                            defaultMessage={SPACE_SYMBOL}
                        />
                    </Tag>
                </Flex>
            )
        }

        return formattedGroupLabel
    }
    const getGroupCounterLabel = (group: string, selected: number, available: number) => {
        return intl.formatMessage(
            {
                id: 'app.developers.webhooks.form.rules.group_number_of_rules.label',
                defaultMessage: group
            },
            {
                selected,
                available
            }
        )
    }
    const groupOrder = useMemo(() => {
        return reorderWebhookEventOptionsWithData(WEBHOOK_EVENT_OPTIONS_ORDER, query.data)
    }, [query.data])
    const propsGroupAccordion: GroupAccordionInputProps<WebhookRules> = {
        options: query.data,
        isLoading: query.isLoading,
        groupOrder,
        getGroupLabel,
        getGroupCounterLabel,
        ...props
    }

    const Component = props?.isReadOnly ? GroupAccordionReadonlyField : GroupAccordionInput

    return <Component<WebhookRules> {...propsGroupAccordion} />
}
