import { AmountSchema } from '@webapps/numeral-ui-core'
import { FieldValidator, FormikValues } from 'formik'
import { isNil } from 'lodash'
import { IntlShape } from 'react-intl'
import { SafeParseReturnType } from 'zod'
import { asOptionalField } from '../validators.utils'
import { augmentValidationMessageWithIntl } from './amount.validator.utils'
import { FormikInputConfiguration } from '@/types'

export function amountValidator(
    intl: IntlShape,
    configuration?: FormikInputConfiguration,
    values?: FormikValues
): FieldValidator {
    return (value: unknown) => {
        let parsedValue: unknown = parseFloat(value as string)
        parsedValue = Number.isNaN(parsedValue) ? value : parsedValue

        let validationResult: SafeParseReturnType<unknown, unknown>
        if (configuration?.isRequired) {
            validationResult = AmountSchema.safeParse(parsedValue)
        } else {
            validationResult = asOptionalField(AmountSchema).safeParse(parsedValue)
        }

        if (configuration?.isRequired && isNil(parsedValue)) {
            return intl.formatMessage({ id: 'app.common.form.validation.required' })
        }

        if (!validationResult.success) {
            return augmentValidationMessageWithIntl(intl, validationResult.error)
        }
    }
}
