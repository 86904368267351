import { LeftArrowIcon } from '@/components/@icons'
import { useNavigateWithLegalEntityID } from '@/hooks'
import { Flex, Link } from '@chakra-ui/react'
import { SyntheticEvent, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

export const PageHeaderLayoutBackButton: React.FC = () => {
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()

    const onClickBack = useCallback(
        (event: SyntheticEvent) => {
            navigateWithLegalEntityID(-1)
        },
        [navigateWithLegalEntityID]
    )

    return (
        <Flex color="numeralAccent.500" data-testid="page-header-layout-back-button">
            <Link textDecoration="none" lineHeight="16px" onClick={onClickBack}>
                <LeftArrowIcon marginRight="4px" />
                <FormattedMessage id="app.common.actions.back" />
            </Link>
        </Flex>
    )
}
