import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdIncomingPayment } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { IncomingPayment } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
    geIncomingPaymentEntityDetailRows,
    getIncomingPaymentEntityDetailRowCustomizers
} from './IncomingPaymentDetails.page.utils'
import { IncomingPaymentDetailsCustomRows } from './IncomingPaymentDetails.types'

export const IncomingPaymentDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdIncomingPayment(uuid)
    const rows = useMemo(() => {
        return geIncomingPaymentEntityDetailRows(query.data)
    }, [query])
    const rowCustomizers = useMemo(() => {
        return getIncomingPaymentEntityDetailRowCustomizers(query.data)
    }, [query])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.INCOMING_PAYMENT}
                permission={PERMISSION.PAYMENTS_VIEW_INCOMING_PAYMENTS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.incoming_payment}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<IncomingPayment, IncomingPaymentDetailsCustomRows>
                        data={query.data}
                        rows={rows}
                        rowCustomizers={rowCustomizers}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
