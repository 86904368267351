import { CrossIcon } from '@/components/@icons'
import { If } from '@/components/@misc'
import { DEFAULT_INPUT_TEXT_MAX_LENGTH, EMPTY_CHAR_SYMBOL, SPACE_SYMBOL } from '@/constants'
import { useCustomFormikField } from '@/hooks'
import { FormikInput } from '@/types'
import { anythingValidator } from '@/utils/@validators'
import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Text
} from '@chakra-ui/react'
import { chain, toArray } from 'lodash'
import { ReactElement, ReactNode, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

interface TextInputProps extends FormikInput {
    labelRightElement?: ReactElement
    inputLeftElement?: ReactElement
    inputRightElement?: ReactElement
    isClearable?: boolean
}

export const TextInput: React.FC<TextInputProps> = ({
    name,
    value,
    isErrorHidden,
    labelRightElement,
    inputLeftElement,
    inputRightElement,
    customLabelKey,
    customLabelValues,
    customPlaceholderKey,
    customPlaceholderValues,
    validator = anythingValidator,
    isRequired = false,
    isLabelDisplayed = true,
    isDisabled = false,
    isClearable = false,
    width = '100%',
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<typeof value>({
        name,
        value,
        validate: validator?.(intl, { name, isRequired, ...inputProps })
    })
    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.${name}.label`
        return intl.formatMessage({ id: labelKey }, customLabelValues)
    }, [name, customLabelKey, intl, customLabelValues])

    const placeholder = useMemo<string>(() => {
        const messageKey = customPlaceholderKey ?? `app.common.form.input.${name}.placeholder`
        return intl.formatMessage({ id: messageKey, defaultMessage: SPACE_SYMBOL }, customPlaceholderValues)
    }, [intl, name, customPlaceholderKey, customPlaceholderValues])

    const errorMessages = useMemo<ReactNode>(() => {
        if (isErrorHidden) {
            return null
        }

        const hasMultipleErrors = chain(meta.error).isArray().value()

        if (!hasMultipleErrors) {
            return meta.error
        }

        return (
            <Flex direction="column" gap="4px">
                {toArray(meta.error).map((message, key) => (
                    <Text key={key}>{message}</Text>
                ))}
            </Flex>
        )
    }, [meta, isErrorHidden])
    const isInvalid = useMemo(() => {
        return !isErrorHidden && meta.touched && !!meta.error
    }, [isErrorHidden, meta])

    const onReset = useCallback(() => {
        helpers.setValue(EMPTY_CHAR_SYMBOL)
    }, [helpers])

    return (
        <FormControl width={width} isInvalid={isInvalid}>
            <If condition={isLabelDisplayed}>
                <FormLabel htmlFor={name}>
                    {label}
                    <If condition={!!labelRightElement}>{labelRightElement}</If>
                </FormLabel>
            </If>
            <InputGroup>
                {!!inputLeftElement && <InputLeftElement>{inputLeftElement}</InputLeftElement>}
                <Input
                    maxLength={DEFAULT_INPUT_TEXT_MAX_LENGTH}
                    {...field}
                    {...inputProps}
                    id={name}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    isRequired={isRequired}
                    type="text"
                    aria-label={label}
                />
                <InputRightElement width="auto" display="flex" gap="8px" marginRight="8px">
                    <If condition={!!inputRightElement}>{inputRightElement}</If>
                    <If condition={isClearable && !!field.value}>
                        <CrossIcon
                            aria-label={intl.formatMessage({ id: 'app.common.actions.clear' })}
                            width="16px"
                            height="16px"
                            cursor="pointer"
                            color="gray.400"
                            _hover={{ color: 'numeralAccent.500' }}
                            onClick={onReset}
                        />
                    </If>
                </InputRightElement>
            </InputGroup>
            <If condition={!isErrorHidden}>
                <FormErrorMessage>{errorMessages}</FormErrorMessage>
            </If>
        </FormControl>
    )
}
