import { QueryFallback, RestrictedSection, Table, TableHeader, useTableRowNavigation } from '@/components'
import { useAPIQueryParams, useNavigationRoutes, useQueryFindAllClaims } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { queryDataAggregation } from '@/utils'
import { Claim, ClaimsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
    CLAIM_DOCUMENTATION_LINK,
    CLAIM_FILTERS,
    CLAIM_QUICK_FILTERS,
    CLAIM_TABLE_COLUMN_CUSTOMIZERS,
    CLAIM_TABLE_STATIC_STATE
} from './Claims.page.const'
import { QuickFilterProvider } from '@/providers'
import { ENTITY_FEATURE, PERMISSION } from '@/services'

export const ClaimsPage: React.FC = () => {
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.investigations.claims.title' }), [intl])
    const { search, filterBy } = useAPIQueryParams<ClaimsServiceFindAllQueryOptions>('search', {
        filterBy: {
            configuration: {
                quickFilters: CLAIM_QUICK_FILTERS,
                filters: CLAIM_FILTERS
            }
        }
    })

    const query = useQueryFindAllClaims({ ...search.parsedState, ...filterBy.parsedState })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const onTableRowClick = useTableRowNavigation<Claim>('id', relativePaths.INVESTIGATIONS.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection feature={ENTITY_FEATURE.CLAIM} permission={PERMISSION.INVESTIGATIONS_VIEW_CLAIMS}>
                <QuickFilterProvider<ClaimsServiceFindAllQueryOptions> filterBy={filterBy}>
                    <TableHeader<ClaimsServiceFindAllQueryOptions>
                        objectType={ApiObjectTypeExtendedSchema.enum.claim}
                        isLoading={query.isLoading}
                        filterBy={filterBy}
                        search={search}
                    />
                    <QueryFallback
                        objectType={ApiObjectTypeExtendedSchema.enum.claim}
                        isLoading={query.isLoading}
                        isSuccess={query.isSuccess}
                        isError={query.isError}
                        isDataEmpty={isDataEmpty}
                        isFilteringOrSearching={filterBy.isActive || search.isActive}
                        learnMoreLinkUrl={CLAIM_DOCUMENTATION_LINK}>
                        <Table<Claim>
                            data={data}
                            onRowClick={onTableRowClick}
                            onScrollToBottom={query.fetchNextPage}
                            isLoading={query.hasNextPage}
                            state={CLAIM_TABLE_STATIC_STATE}
                            columnCustomizers={CLAIM_TABLE_COLUMN_CUSTOMIZERS}
                        />
                    </QueryFallback>
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
