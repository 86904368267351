import { ConnectedAccount } from '@webapps/numeral-ui-core'
import { BULLET_SEPARATOR, SPACE_SYMBOL } from '@/constants'

export function getConnectedAccountsInputOptionLabel(item?: ConnectedAccount) {
    let label = ''
    const name = item?.name
    const bankName = item?.bank_name

    if (bankName) {
        label += bankName
    }

    if (name) {
        label += `${SPACE_SYMBOL}${BULLET_SEPARATOR}${SPACE_SYMBOL}${name}`
    }

    return label
}
