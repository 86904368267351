import {
    ConnectedAccountsService as PublicConnectedAccountsService,
    ConnectedAccountsServiceFindAllQueryOptions,
    extractResponseData,
    List,
    ServicesConfiguration
} from '@webapps/numeral-ui-core'
import { ConnectedAccountExpanded } from './ConnectedAccounts.service.types'

export class ConnectedAccountsService extends PublicConnectedAccountsService {
    async findAllExpanded(
        params?: Partial<ConnectedAccountsServiceFindAllQueryOptions>,
        config?: ServicesConfiguration
    ): Promise<List<ConnectedAccountExpanded>> {
        return this.httpClient
            .get<List<ConnectedAccountExpanded>>(`${super.url}/expanded`, { params, ...config })
            .then(extractResponseData)
    }

    async findAllBankNames(
        params?: Partial<Record<string, any>>,
        config?: ServicesConfiguration
    ): Promise<List<string>> {
        return this.httpClient
            .get<List<string>>(`${super.url}/bank_names`, { params, ...config })
            .then(extractResponseData)
    }
}
