import { useQueryFindAllRoles } from '@/hooks'
import { FormikInput } from '@/types'
import { isLastIndex, queryDataAggregation } from '@/utils'
import { anythingValidator } from '@/utils/@validators'
import {
    Box,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Radio,
    RadioGroup,
    SkeletonText,
    Text
} from '@chakra-ui/react'
import { useField } from 'formik'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { RoleName } from '@/pages/Settings/Roles/@components'
import { If } from '@/components/@misc'

export const UserRoleInput: React.FC<FormikInput> = ({
    name,
    isDisabled,
    isRequired = false,
    validator = anythingValidator
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField({
        name,
        validate: validator?.(intl, { isRequired })
    })
    const query = useQueryFindAllRoles()
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <SkeletonText noOfLines={2} isLoaded={query.isSuccess}>
                <Box borderWidth="1px" borderRadius="var(--numeral-ui-primary-border-radius)" marginBottom="8px">
                    <RadioGroup
                        name={name}
                        display="flex"
                        flexDirection="column"
                        isDisabled={isDisabled}
                        onChange={helpers.setValue}>
                        {data.map((option, index, array) => {
                            const hasLastIndex = isLastIndex(index, array)
                            return (
                                <>
                                    <Flex padding="22px" direction="column">
                                        <Radio value={option.id} key={index}>
                                            <Box paddingStart="22px">
                                                <Text color="gray.800" fontWeight="semibold">
                                                    <RoleName role={option} />
                                                </Text>
                                                <Text color="gray.600">{option.description}</Text>
                                            </Box>
                                        </Radio>
                                    </Flex>
                                    <If condition={!hasLastIndex}>
                                        <Divider />
                                    </If>
                                </>
                            )
                        })}
                    </RadioGroup>
                </Box>
            </SkeletonText>
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
