import { ApiObjectType, ApiObjectTypeSchema } from '@webapps/numeral-ui-core'

export const WEBHOOK_EVENT_OPTIONS_ORDER: ApiObjectType[] = [
    ApiObjectTypeSchema.enum.payment_order,
    ApiObjectTypeSchema.enum.incoming_payment,
    ApiObjectTypeSchema.enum.return,
    ApiObjectTypeSchema.enum.return_request,
    ApiObjectTypeSchema.enum.inquiry,
    ApiObjectTypeSchema.enum.balance,
    ApiObjectTypeSchema.enum.transaction,
    ApiObjectTypeSchema.enum.reconciliation,
    ApiObjectTypeSchema.enum.expected_payment,
    ApiObjectTypeSchema.enum.payment_capture,
    ApiObjectTypeSchema.enum.file,
    ApiObjectTypeSchema.enum.event,
    ApiObjectTypeSchema.enum.account_holder,
    ApiObjectTypeSchema.enum.external_account,
    ApiObjectTypeSchema.enum.internal_account,
    ApiObjectTypeSchema.enum.claim,
    ApiObjectTypeSchema.enum.direct_debit_mandate
]
