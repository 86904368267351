import { defineStyleConfig } from '@chakra-ui/react'

export const Input = defineStyleConfig({
    baseStyle: {
        field: {
            marginBottom: '8px',
            paddingRight: '8px',
            _disabled: {
                backgroundColor: 'gray.50',
                opacity: 1
            }
        }
    },
    variants: {
        outline: {
            field: {
                borderRadius: 'var(--numeral-ui-primary-border-radius)',
                backgroundColor: 'white'
            }
        },
        flushed: {
            field: {
                _focus: {
                    borderColor: 'chakra-border-color'
                }
            }
        }
    },
    defaultProps: {
        // @ts-ignore chakra doesn't officially support focus color override
        // https://github.com/chakra-ui/chakra-ui/issues/663
        focusBorderColor: 'numeralAccent.500'
    }
})
