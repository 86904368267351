import { DetailPageLink } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { AuditTrailAuthorType, AuditTrailAuthorTypeSchema } from '@/services'
import { Uuid } from '@webapps/numeral-ui-core'
import { FormattedMessage } from 'react-intl'
import { TableCellAuditTrailDashboardAuthor } from '../TableCellAuditTrailDashboardAuthor'

interface TableCellAuditTrailAuthorProps {
    authorId: Uuid
    authorType: AuditTrailAuthorType
}

export const TableCellAuditTrailAuthor: React.FC<TableCellAuditTrailAuthorProps> = ({ authorType, authorId }) => {
    switch (authorType) {
        case AuditTrailAuthorTypeSchema.enum.dashboard: {
            return <TableCellAuditTrailDashboardAuthor objectId={authorId} />
        }

        case AuditTrailAuthorTypeSchema.enum.api_key: {
            return (
                <DetailPageLink objectId={authorId} objectType={ApiObjectTypeExtendedSchema.enum.api_key}>
                    <FormattedMessage
                        id="app.common.link.view_object.label"
                        values={{ objectType: <FormattedMessage id="api.object.api_key" /> }}
                    />
                </DetailPageLink>
            )
        }

        default: {
            return EMPTY_VALUE_PLACEHOLDER
        }
    }
}
