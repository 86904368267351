import { TextInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import {
    hasUniqueCustomFieldKeyValues,
    isAddNewCustomFieldValueDisabled,
    transformCustomFieldNameToSnakeCase
} from '@/services'
import { FormikInput } from '@/types'
import { customFieldKeyValidator } from '@/utils/@validators'
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Stack } from '@chakra-ui/react'
import { CustomField, CustomFieldValue } from '@webapps/numeral-ui-core'
import { FieldArray, useField, useFormikContext } from 'formik'
import { chain, isString } from 'lodash'
import React, { ChangeEvent, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { customFieldValuesValidator } from '../../CustomField.form.utils'
import { CustomFieldFormKeyTooltip } from '../CustomFieldFormKeyTooltip'
import { CustomFieldValuesInputDuplicateKeyValues } from './CustomFieldValuesInputDuplicateKeyValues'

interface CustomFieldValuesInputProps extends Omit<FormikInput, 'value'> {
    value?: CustomFieldValue[]
}

export const CustomFieldValuesInput: React.FC<CustomFieldValuesInputProps> = ({
    name,
    value,
    isRequired,
    isDisabled = false,
    validator = customFieldValuesValidator
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField<CustomFieldValue[]>({
        name,
        value,
        validate: validator?.(intl, { isRequired })
    } as any)
    const { values, setFieldValue } = useFormikContext<CustomField>()
    const isEditMode = useMemo(() => globalThis.Boolean(values?.id), [values])
    const hasAddNewCustomFieldValueDisabled = useMemo(() => {
        return isAddNewCustomFieldValueDisabled(field.value)
    }, [field.value])
    const isInvalid = meta.touched && !!meta.error

    return (
        <>
            <FieldArray name={name}>
                {({ push, remove }) => {
                    const onAdd = () => {
                        push({ name: '', key: '' } as CustomFieldValue)
                    }

                    return (
                        <FormControl isInvalid={isInvalid}>
                            <Stack gap="8px" marginBottom="8px">
                                <Flex gap="16px" justifyContent="space-between" flex="1">
                                    <Flex flex="0.5">
                                        <FormLabel htmlFor={name}>
                                            <FormattedMessage id="app.common.form.input.name.label" />
                                        </FormLabel>
                                    </Flex>
                                    <Flex flex="0.5" marginLeft="-64px">
                                        <FormLabel htmlFor={name}>
                                            <FormattedMessage id="app.common.form.input.key.label" />
                                            <CustomFieldFormKeyTooltip />
                                        </FormLabel>
                                    </Flex>
                                </Flex>
                                {chain(values)
                                    .get('values')
                                    .map((item, index) => {
                                        const isKeyDisabled =
                                            isDisabled || (value?.some(({ key }) => key === item?.key) && isEditMode)
                                        const onChangeCustomFieldName = (event: ChangeEvent<HTMLInputElement>) => {
                                            const nameValue = event.target.value

                                            setFieldValue(`${name}.${index}.name`, nameValue)

                                            if (!isEditMode || !isKeyDisabled) {
                                                setFieldValue(
                                                    `${name}.${index}.key`,
                                                    transformCustomFieldNameToSnakeCase(nameValue)
                                                )
                                            }
                                        }

                                        const onRemove = () => {
                                            if (isKeyDisabled) {
                                                return
                                            }

                                            remove(index)
                                        }

                                        return (
                                            <Flex key={`${name}.${index}`} gap="8px" alignItems="flex-end">
                                                <Flex gap="16px" justifyContent="space-between" flex="1">
                                                    <TextInput
                                                        name={`${name}.${index}.name`}
                                                        customPlaceholderKey="app.settings.custom_fields.form.values.name.placeholder"
                                                        onChange={onChangeCustomFieldName}
                                                        isDisabled={isDisabled}
                                                        isRequired={isRequired}
                                                        isLabelDisplayed={false}
                                                    />
                                                    <TextInput
                                                        name={`${name}.${index}.key`}
                                                        customPlaceholderKey="app.settings.custom_fields.form.values.key.placeholder"
                                                        validator={customFieldKeyValidator}
                                                        isRequired={isRequired}
                                                        isDisabled={isKeyDisabled}
                                                        isLabelDisplayed={false}
                                                    />
                                                    <Button
                                                        variant="transparent"
                                                        onClick={onRemove}
                                                        isDisabled={isKeyDisabled}
                                                        color="red.500"
                                                        fontWeight="normal">
                                                        <FormattedMessage id="app.settings.custom_fields.form.values.actions.remove_value.label" />
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        )
                                    })
                                    .value()}
                                <Button
                                    onClick={onAdd}
                                    isDisabled={hasAddNewCustomFieldValueDisabled || isDisabled}
                                    width="92px">
                                    <FormattedMessage id="app.settings.custom_fields.form.values.actions.add_value.label" />
                                </Button>
                            </Stack>
                            <If condition={isString(meta.error)}>
                                <FormErrorMessage>{meta.error}</FormErrorMessage>
                            </If>
                        </FormControl>
                    )
                }}
            </FieldArray>
            <If condition={!hasUniqueCustomFieldKeyValues(values.values)}>
                <CustomFieldValuesInputDuplicateKeyValues name={name} />
            </If>
        </>
    )
}
