import { Webhook, WEBHOOK_ALL_RULES } from '@/services'
import { IntlShape } from 'react-intl'
import { FieldValidator } from 'formik'
import { asOptionalField, sanitiseValidatorValue } from '@/utils/@validators/validators.utils'
import { SafeParseReturnType, z } from 'zod'
import { isEmpty } from 'lodash'
import { DEFAULT_INPUT_TEXT_MIN_LENGTH, EMPTY_CHAR_SYMBOL } from '@/constants'
import { FormikInputConfiguration } from '@/types'

export function getWebhookFormInitialValues(data?: Partial<Webhook>): Webhook {
    return {
        name: EMPTY_CHAR_SYMBOL,
        url: EMPTY_CHAR_SYMBOL,
        rules: WEBHOOK_ALL_RULES,
        ...data
    }
}

export function webhookNameValidator(intl: IntlShape, configuration?: FormikInputConfiguration): FieldValidator {
    const WebhookNameSchema = z.string().trim()
    return (value: string) => {
        const sanitisedValue = sanitiseValidatorValue(value)
        let validationResult: SafeParseReturnType<typeof value, string | void>

        if (configuration?.isRequired) {
            validationResult = WebhookNameSchema.safeParse(sanitisedValue)
        } else {
            validationResult = asOptionalField(WebhookNameSchema).safeParse(sanitisedValue)
        }

        if (configuration?.isRequired && isEmpty(sanitisedValue)) {
            return intl.formatMessage(
                { id: 'app.developers.webhooks.form.name.validation.min.message' },
                {
                    size: DEFAULT_INPUT_TEXT_MIN_LENGTH
                }
            )
        }

        if (!validationResult.success) {
            return intl.formatMessage({ id: 'app.common.form.validation.url.invalid' })
        }
    }
}
