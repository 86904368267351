import { FormContainer, InputWithCopyToClipboard } from '@/components'
import { FormattedDateTime, If, ReadonlyField, StatusLabel } from '@/components/@misc'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { areIndividualWebhookRulesEnabled } from '../../Webhooks.page.utils'
import { WebhookRulesInput } from '../WebhookRulesInput'
import { getWebhookDeliveryModeLabelWithIntl } from './WebhookDetails.form.utils'
import { WebhookDetailsFormProps } from './WebhookDetails.form.types'
import { getWebhookRulesMode } from '@/services'

export const WebhookDetailsForm: React.FC<WebhookDetailsFormProps> = ({ data }) => {
    const intl = useIntl()
    const rulesMode = useMemo(() => getWebhookRulesMode(data?.rules), [data])
    const hasIndividualWebhookRulesEnabled = useMemo(() => areIndividualWebhookRulesEnabled(rulesMode), [rulesMode])
    const currentDeliveryModeLabel = useMemo(() => getWebhookDeliveryModeLabelWithIntl(intl)(data), [intl, data])

    return (
        <FormContainer>
            <FormControl>
                <FormLabel htmlFor="id" variant="readonly">
                    <FormattedMessage id="app.common.form.input.id.label" />
                </FormLabel>
                <InputWithCopyToClipboard
                    id="id"
                    name="id"
                    defaultValue={data?.id}
                    isReadOnly={true}
                    variant="flushed"
                    focusBorderColor="none"
                />
            </FormControl>
            <ReadonlyField name="created_at" value={<FormattedDateTime value={data?.created_at} />} />
            <ReadonlyField name="name" value={data?.name} />
            <ReadonlyField name="url" value={data?.url} />
            <ReadonlyField name="status" value={<StatusLabel value={data?.enabled ? 'enabled' : 'disabled'} />} />
            <If condition={hasIndividualWebhookRulesEnabled}>
                <WebhookRulesInput name="rules" value={data?.rules} isReadOnly={true} isDisabled={true} />
            </If>
            <FormControl>
                <FormLabel variant="readonly">
                    <FormattedMessage id="app.developers.webhooks.form.delivery_mode.label" />
                </FormLabel>
                {currentDeliveryModeLabel}
            </FormControl>
        </FormContainer>
    )
}
