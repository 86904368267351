import { EMPTY_STATE_IMAGES, EmptyState, EmptyStateProps } from '@/components'
import { useNavigateWithLegalEntityID, useNavigationRoutes } from '@/hooks'
import { PageLayout } from '@/layouts'
import { Flex } from '@chakra-ui/react'
import { memo, SyntheticEvent, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const MissingAccessPage: React.FC = memo(() => {
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths } = useNavigationRoutes()
    const emptyContentProps: EmptyStateProps = useMemo<EmptyStateProps>(() => {
        const title = intl.formatMessage({
            id: `app.missing_access.empty_state.title`
        })
        const description = intl.formatMessage({
            id: `app.missing_access.empty_state.description`
        })
        const propsButton = {
            onClick(event: SyntheticEvent) {
                navigateWithLegalEntityID(paths.HOME)
            },
            colorScheme: 'blue',
            fontWeight: 'normal',
            variant: 'link',
            textDecoration: 'underline',
            children: <FormattedMessage id="app.missing_access.action.go_back_home.label" />
        }

        return {
            title,
            description,
            propsButton,
            imageSrc: EMPTY_STATE_IMAGES.SETTINGS
        }
    }, [intl, navigateWithLegalEntityID, paths])

    return (
        <PageLayout className="LoginError">
            <Flex justifyContent="center" flexGrow="2" height="inherit">
                <EmptyState {...emptyContentProps} />
            </Flex>
        </PageLayout>
    )
})
