import { AlertMessage, Form, FormPageFooter } from '@/components'
import { DenyReasonInput } from '@/components/@inputs'
import { InquiryDenyData } from '@webapps/numeral-ui-core'
import { FormikValues } from 'formik'
import { noop } from 'lodash'
import { useCallback, useRef } from 'react'

interface InquiryDenyFormProps {
    loading?: boolean
    disabled?: boolean
    readonly?: boolean

    onSubmit?(data: InquiryDenyData): void
}

export const InquiryDenyForm: React.FC<InquiryDenyFormProps> = ({ disabled, loading, onSubmit = noop }) => {
    const initialValues = useRef<Partial<InquiryDenyData>>({
        reason: undefined
    }).current
    const onFormSubmit = useCallback(async (values: FormikValues) => {
        onSubmit(values)
    }, [])

    return (
        <Form<Partial<InquiryDenyData>> initialValues={initialValues} onSubmit={onFormSubmit}>
            {({ handleSubmit, dirty }) => {
                return (
                    <>
                        <AlertMessage
                            titleKey="app.investigations.inquiries.deny.alert.title"
                            descriptionKey="app.investigations.inquiries.deny.alert.description"
                            isClosable={false}
                        />
                        <DenyReasonInput
                            name="reason"
                            isPlaceholderDisplayed={true}
                            isRequired={true}
                            isDisabled={disabled}
                        />
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.investigations.inquiries.details.deny.form.actions.submit.label"
                        />
                    </>
                )
            }}
        </Form>
    )
}
