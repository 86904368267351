import { EntityDetailRow, EntityDetailRowCustomizers, EntityRowCustomizerDef } from '@/components'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import {
    PAYMENT_ORDER_ENTITY_DETAIL_ROW_CUSTOMIZERS,
    PAYMENT_ORDER_ENTITY_DETAIL_ROWS
} from './PaymentOrderDetails.page.const'
import { PaymentOrderDetailsCustomRows } from './PaymentOrderDetails.types'
import { getDirectDebitMandateRowCustomizers, isPaymentMandateEntityRowVisible } from '@/pages/Common/Payments'
import { getCustomFieldsRowCustomizers } from '@/pages/Common/CustomFields/CustomFields.utils'
import { PERMISSION } from '@/services'

export function getPaymentOrderEntityDetailRows(item?: PaymentOrder) {
    const rows = [...PAYMENT_ORDER_ENTITY_DETAIL_ROWS]

    if (!item) {
        return rows
    }

    if (isPaymentMandateEntityRowVisible(item)) {
        const directDebitMandateRow: EntityDetailRow<PaymentOrder, PaymentOrderDetailsCustomRows>[] = [
            'direct_debit_mandate'
        ]
        const relatedObjectsRowIndex = rows.findIndex((row) => row === 'related_objects')

        rows.splice(relatedObjectsRowIndex, 0, directDebitMandateRow as any)
    }

    return rows
}

export function getPaymentOrderEntityDetailRowCustomizers(
    item?: PaymentOrder
): EntityDetailRowCustomizers<PaymentOrder, PaymentOrderDetailsCustomRows> {
    const rowCustomizers: EntityDetailRowCustomizers<PaymentOrder, PaymentOrderDetailsCustomRows> = Object.create(
        PAYMENT_ORDER_ENTITY_DETAIL_ROW_CUSTOMIZERS
    )

    if (!item) {
        return rowCustomizers
    }

    if (isPaymentMandateEntityRowVisible(item)) {
        rowCustomizers.direct_debit_mandate = getDirectDebitMandateRowCustomizers(
            item
        ) as unknown as EntityRowCustomizerDef<PaymentOrder, PaymentOrderDetailsCustomRows>
    }

    rowCustomizers.custom_fields = getCustomFieldsRowCustomizers(
        item,
        PERMISSION.PAYMENTS_EDIT_PAYMENT_ORDERS
    ) as unknown as EntityRowCustomizerDef<PaymentOrder, PaymentOrderDetailsCustomRows>

    return Object.assign(rowCustomizers, PAYMENT_ORDER_ENTITY_DETAIL_ROW_CUSTOMIZERS)
}
