import {
    QueryFallback,
    RestrictedSection,
    Table,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import { useAPIQueryParams, useNavigateWithLegalEntityID, useNavigationRoutes, usePermissions } from '@/hooks'
import { useQueryFindAllInternalAccounts } from '@/hooks/api/internalAccounts'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import {
    ApiObjectTypeSchema,
    InternalAccount,
    InternalAccountsServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
    INTERNAL_ACCOUNT_DOCUMENTATION_LINK,
    INTERNAL_ACCOUNT_FILTERS,
    INTERNAL_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS,
    INTERNAL_ACCOUNT_TABLE_STATIC_STATE
} from './InternalAccounts.page.const'

export const InternalAccountsPage: React.FC = () => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.counterparties.internal_accounts.title' }),
        [intl]
    )
    const { search, filterBy } = useAPIQueryParams<InternalAccountsServiceFindAllQueryOptions>('search', {
        filterBy: { configuration: { filters: INTERNAL_ACCOUNT_FILTERS } }
    })
    const query = useQueryFindAllInternalAccounts({ ...search.parsedState, ...filterBy.parsedState })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const actions = useMemo(() => {
        const actions: TableHeaderActionProps[] = []

        if (hasPermission(PERMISSION.COUNTERPARTIES_CREATE_INTERNAL_ACCOUNTS)) {
            actions.push({
                onClick() {
                    navigateWithLegalEntityID(relativePaths.NEW)
                },
                children: intl.formatMessage({
                    id: 'app.counterparties.internal_accounts.create.form.actions.submit.label'
                })
            })
        }

        return actions
    }, [hasPermission, intl, navigateWithLegalEntityID, relativePaths.NEW])

    const onTableRowClick = useTableRowNavigation<InternalAccount>('id', relativePaths.COUNTERPARTIES.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection permission={PERMISSION.COUNTERPARTIES_VIEW_INTERNAL_ACCOUNTS}>
                <TableHeader<InternalAccountsServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.internal_account}
                    isLoading={query.isLoading}
                    search={search}
                    filterBy={filterBy}
                    actions={actions}
                />
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.internal_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}
                    isFilteringOrSearching={filterBy.isActive || search.isActive}
                    learnMoreLinkUrl={INTERNAL_ACCOUNT_DOCUMENTATION_LINK}
                    customEmptyStateDescriptionKey="app.counterparties.internal_accounts.empty_state.description">
                    <Table<InternalAccount>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        state={INTERNAL_ACCOUNT_TABLE_STATIC_STATE}
                        columnCustomizers={INTERNAL_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
