import { COMMA_SYMBOL, EMPTY_VALUE_PLACEHOLDER, SPACE_SYMBOL } from '@/constants'
import { memo, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { getWebhookRulesMode, WEBHOOK_RULES_MODES, WebhookRules } from '@/services'
import { Text, Tooltip } from '@chakra-ui/react'
import { chain } from 'lodash'
import { Nullable } from '@/types'

export const TableCellWebhookRules: React.FC<{ webhookRules?: Nullable<WebhookRules> }> = memo(({ webhookRules }) => {
    const rulesMode = useMemo(() => getWebhookRulesMode(webhookRules), [webhookRules])

    switch (rulesMode) {
        case WEBHOOK_RULES_MODES.ALL: {
            return <FormattedMessage id="All" />
        }

        case WEBHOOK_RULES_MODES.INDIVIDUAL: {
            const wrappedWebhookRulesKeys = chain(webhookRules).keys().join(`${COMMA_SYMBOL}${SPACE_SYMBOL}`)
            const tooltip = wrappedWebhookRulesKeys.value()
            const truncated = wrappedWebhookRulesKeys.truncate({ length: 35 }).value()

            return (
                <Tooltip label={tooltip} hasArrow={true}>
                    <Text noOfLines={1} maxWidth="100%">
                        {truncated}
                    </Text>
                </Tooltip>
            )
        }

        default: {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }
    }
})
