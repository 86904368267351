import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

interface ReadonlyFieldProps {
    name?: string
    customLabelKey?: string
    value: ReactNode
}

export const ReadonlyField: React.FC<ReadonlyFieldProps> = ({ name, customLabelKey, value }) => {
    const labelKey = useMemo<string>(
        () => customLabelKey || `app.common.form.input.${name}.label`,
        [customLabelKey, name]
    )

    if (!name) {
        return null
    }

    return (
        <FormControl data-testid={name}>
            <FormLabel htmlFor={labelKey} variant="readonly">
                <FormattedMessage id={labelKey} />
            </FormLabel>
            <Input as="div" variant="flushed" display="flex" height="auto" padding="6px 0" alignItems="center">
                {value || EMPTY_VALUE_PLACEHOLDER}
            </Input>
        </FormControl>
    )
}
