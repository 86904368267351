import { Select } from '@/components/@misc'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import {
    computePaymentOrderCurrency,
    computePaymentPropertiesByServiceName,
    generatePaymentOrderDefaultDirectDebitMandate
} from '@/services'
import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { PaymentOrder, ServiceName } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getAvailablePaymentOrderTypesFromConnectedAccountServiceNames } from './PaymentOrderTypeDirectionInput.utils'

type PaymentOrderTypeDirectionInput = FormikInput & {
    isFirstOptionPreselected?: boolean
}

export const PaymentOrderTypeDirectionInput: React.FC<PaymentOrderTypeDirectionInput> = ({
    name,
    value,
    isFirstOptionPreselected = false,
    ...inputProps
}) => {
    const intl = useIntl()
    const { setFieldValue, getFieldProps, getFieldMeta } = useFormikContext<PaymentOrder>()
    const { value: connectedAccountId } = getFieldProps('connected_account_id')
    const { value: accountNumber } = getFieldProps('receiving_account.account_number')

    const meta = getFieldMeta(name)
    const hasConnectedAccountId = useMemo<boolean>(() => globalThis.Boolean(connectedAccountId), [connectedAccountId])
    const query = useQueryFindByIdConnectedAccount(connectedAccountId, {
        enabled: hasConnectedAccountId
    })
    const data = useMemo(() => {
        return getAvailablePaymentOrderTypesFromConnectedAccountServiceNames(query.data?.services_activated)
    }, [query])
    const [serviceName, setServiceName] = useState<ServiceName>()
    const isDisabled = useMemo<boolean>(() => {
        return query.isLoading || !hasConnectedAccountId
    }, [query, hasConnectedAccountId])
    const onChange = useCallback(
        (serviceName: ServiceName) => {
            const { type, direction } = computePaymentPropertiesByServiceName(serviceName)
            const directDebitMandate = generatePaymentOrderDefaultDirectDebitMandate(type, direction)
            const currency = computePaymentOrderCurrency(type)

            setFieldValue('type', type)
            setFieldValue('currency', currency)
            setFieldValue('direction', direction)

            setFieldValue('requested_execution_date', '')
            setFieldValue('retry_details.payment_retry_rule_id', '')
            setFieldValue('purpose', '')

            setFieldValue('direct_debit_mandate', directDebitMandate)
            setFieldValue('receiving_account.account_number', accountNumber, true)

            setServiceName(serviceName)
        },
        [setFieldValue, accountNumber]
    )
    const isInvalid = meta.touched && !!meta.error

    useEffect(() => {
        if (isFirstOptionPreselected && data?.length > 0) {
            onChange(data?.[0] as ServiceName)
        }
    }, [isFirstOptionPreselected, query.data])

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Select
                {...inputProps}
                id={name}
                name={name}
                isDisabled={isDisabled}
                isInvalid={isInvalid}
                isLoading={query.isLoading}
                onChange={onChange}
                options={data}
                value={serviceName}
                getOptionLabel={(item) =>
                    intl.formatMessage({ id: `app.payments.payment_orders.create.form.inputs.type.${item}.label` })
                }
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
