import { FilterInputs, TableCellShortDate, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import {
    ClaimRelatedPaymentTypeInput,
    ClaimStatusInput,
    ClaimTypeInput,
    ConnectedAccountsInput,
    DateInput,
    TextInput
} from '@/components/@inputs'
import { ConnectedAccountName, DetailPageLink, FormattedTextIntl, StatusLabel } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { uuidValidator } from '@/utils/@validators'
import { ApiObjectType, Claim, ClaimsServiceFindAllQueryOptions, ClaimStatusSchema } from '@webapps/numeral-ui-core'
import { APIQueryParamQuickFilters } from '@/hooks'

export const CLAIM_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/claim-object`

export const CLAIM_TABLE_STATIC_STATE: TableStateWithEntity<Claim> = {
    columnOrder: Object.freeze(['created_at', 'type', 'connected_account_id', 'related_payment_type', 'status'])
}

export const CLAIM_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<Claim> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    type: {
        cell(info) {
            return <FormattedTextIntl messageId={`api.claim.type.${info.getValue()}`} />
        }
    },
    connected_account_id: {
        size: COLUMN_WIDTH_RATIO.CONNECTED_ACCOUNT,
        cell(info) {
            return <ConnectedAccountName connectedAccountId={info.getValue()} asPlainText={true} />
        }
    },
    related_payment_type: {
        cell(info) {
            const { related_payment_id } = info.row.original

            return (
                <DetailPageLink objectId={related_payment_id as string} objectType={info.getValue() as ApiObjectType} />
            )
        }
    },
    status: {
        cell(info) {
            return <StatusLabel value={info.getValue()} />
        }
    }
}

export const CLAIM_QUICK_FILTERS: APIQueryParamQuickFilters<ClaimsServiceFindAllQueryOptions> = {
    status: [ClaimStatusSchema.enum.received]
}

export const CLAIM_FILTERS: FilterInputs<ClaimsServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    type: <ClaimTypeInput name="type" />,
    status: <ClaimStatusInput name="status" />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    related_payment_type: <ClaimRelatedPaymentTypeInput name="related_payment_type" />,
    related_payment_id: <TextInput name="related_payment_id" validator={uuidValidator} />,
    file_id: <TextInput name="file_id" validator={uuidValidator} />,
    originating_account_number: <TextInput name="originating_account_number" />,
    receiving_account_number: <TextInput name="receiving_account_number" />
}
