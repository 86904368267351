import { QueryFallback, RestrictedSection } from '@/components'
import {
    useMutationUpdateByIdAPIKey,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useQueryFindByIdAPIKey,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { APIKeyForm } from './@components'

export const APIKeyEditPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { onAdd } = useToasts()
    const { paths } = useNavigationRoutes()

    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.developers.api_keys.edit.title' }), [intl])
    const query = useQueryFindByIdAPIKey(uuid)
    const mutation = useMutationUpdateByIdAPIKey(uuid, {
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const path = [paths.DEVELOPERS.API_KEYS, response.id].join('/')

            navigateWithLegalEntityID(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.developers.api_keys.edit.success.toast.title" />,
                description: <FormattedMessage id="app.developers.api_keys.edit.success.toast.description" />
            })
        },
        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.developers.api_keys.edit.error.toast.title" />,
                description: <FormattedMessage id="app.developers.api_keys.edit.error.toast.description" />
            })
        }
    })

    return (
        <PageLayout className="APIKeyEdit">
            <PageHeaderLayout title={title} />
            <RestrictedSection permission={PERMISSION.DEVELOPERS_CONFIGURE_API_KEYS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.api_key}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <APIKeyForm data={query.data} onSubmit={mutation.mutate} disabled={mutation.isPending} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
