import { FormSection } from '@/components'
import { RetryRuleInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { useActivatedFeatures } from '@/hooks'
import { ENTITY_FEATURE } from '@/services'
import { Flex, Switch, Tooltip } from '@chakra-ui/react'
import {
    Direction,
    PaymentOrder,
    PaymentOrderType,
    PaymentRetryRulesServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { EMPTY_CHAR_SYMBOL } from '@/constants'

const PAYMENT_RETRY_RULE_ID_NAME = 'retry_details.payment_retry_rule_id'

export interface PaymentOrderRetryRuleSectionProps {
    isDisabled?: boolean
    isSectionDisplayed: boolean
    isSectionExpanded?: boolean
    selectedPaymentOrderType?: PaymentOrderType
    selectedDirection?: Direction
}

export const PaymentOrderRetryRuleSection: React.FC<PaymentOrderRetryRuleSectionProps> = ({
    isDisabled,
    isSectionDisplayed,
    isSectionExpanded,
    selectedPaymentOrderType,
    selectedDirection
}) => {
    const intl = useIntl()
    const { isFeatureActivated } = useActivatedFeatures()
    const { getFieldProps, setFieldValue } = useFormikContext<PaymentOrder>()
    const { value: type } = getFieldProps('type')
    const { value: direction } = getFieldProps('direction')

    const [isExpanded, setIsExpanded] = useState(isSectionExpanded)
    const onToggleAutomatedRetrySection = useCallback(() => {
        setIsExpanded((prevState) => {
            return !prevState
        })
    }, [])

    const isRetryRuleFeatureEnabled = useMemo(() => {
        return isFeatureActivated(ENTITY_FEATURE.PAYMENT_RETRY_RULE)
    }, [isFeatureActivated])

    const sectionToggleTooltipLabel = useMemo<string>(() => {
        return intl.formatMessage({
            id: 'app.payments.payment_orders.create.form.sections.retry_rule.toggle.disabled.tooltip'
        })
    }, [intl])

    const isRetryRuleInputDisabled = useMemo(() => {
        return isDisabled || (!selectedPaymentOrderType && !type) || (!selectedDirection && !direction)
    }, [type, direction])

    const retryRuleQueryFilter = useMemo<Partial<PaymentRetryRulesServiceFindAllQueryOptions>>(() => {
        return {
            'retry_conditions.payment_type': selectedPaymentOrderType || type,
            'retry_conditions.payment_direction': selectedDirection || direction
        }
    }, [type, direction])

    useEffect(() => {
        const retryRuleValue = getFieldProps(PAYMENT_RETRY_RULE_ID_NAME).value

        if (retryRuleValue && !isExpanded) {
            setFieldValue(PAYMENT_RETRY_RULE_ID_NAME, EMPTY_CHAR_SYMBOL, false)
        }
    }, [isExpanded])

    return (
        <>
            <If condition={isSectionDisplayed}>
                <FormSection
                    titleId="app.payments.payment_orders.create.form.sections.retry_rule.title"
                    title={
                        <Tooltip
                            key="toggle-retry-rule-section-tooltip"
                            shouldWrapChildren={true}
                            isDisabled={isRetryRuleFeatureEnabled}
                            label={sectionToggleTooltipLabel}
                            placement="top"
                            hasArrow={true}>
                            <Flex>
                                <Switch
                                    data-testid="toggle-retry-rule-section"
                                    size="sm"
                                    isDisabled={!isRetryRuleFeatureEnabled}
                                    isChecked={isExpanded}
                                    onChange={onToggleAutomatedRetrySection}>
                                    <FormattedMessage id="app.payments.payment_orders.create.form.sections.retry_rule.toggle.title" />
                                </Switch>
                            </Flex>
                        </Tooltip>
                    }>
                    <If condition={isExpanded}>
                        <RetryRuleInput
                            name={PAYMENT_RETRY_RULE_ID_NAME}
                            APIQueryParams={retryRuleQueryFilter}
                            isDisabled={isRetryRuleInputDisabled}
                        />
                    </If>
                </FormSection>
            </If>
            <If condition={!isSectionDisplayed}>
                <If condition={isRetryRuleFeatureEnabled}>
                    <RetryRuleInput
                        name={PAYMENT_RETRY_RULE_ID_NAME}
                        customLabelKey="app.common.form.input.automated_retry.label"
                        APIQueryParams={retryRuleQueryFilter}
                        isDisabled={isRetryRuleInputDisabled}
                    />
                </If>
            </If>
        </>
    )
}
