import { getCounterpartyAccountCommonRowCustomizers } from '@/pages/Common'
import { ApiObjectTypeSchema, Transaction } from '@webapps/numeral-ui-core'
import { EntityDetailRowCustomizers } from '@/components'

export function getTransactionCounterpartiesRowCustomizers(
    transaction?: Transaction
): EntityDetailRowCustomizers<Transaction> {
    return {
        internal_account: {
            nested: true,
            rows: ['related_objects', 'account_number'],
            rowCustomizers: getCounterpartyAccountCommonRowCustomizers(
                ApiObjectTypeSchema.enum.internal_account,
                transaction?.internal_account_id
            )
        },
        external_account: {
            nested: true,
            rows: ['related_objects', 'holder_name', 'account_number'],
            rowCustomizers: getCounterpartyAccountCommonRowCustomizers(
                ApiObjectTypeSchema.enum.external_account,
                transaction?.external_account_id
            )
        }
    }
}
