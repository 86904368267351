import {
    EntityDetailCellHolderAddress,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers
} from '@/components'
import { AccountHolderName, BankCode, ConnectedAccountsName, FormattedDateTime, StatusLabel } from '@/components/@misc'
import { accountNumberFormatter, whitespaceFormatter } from '@/utils/@formatters'
import { InternalAccount } from '@webapps/numeral-ui-core'
import { InternalAccountDetailsRelatedObjects } from './@components'
import { InternalAccountCustomRows } from './InternalAccountDetails.page.types'

export const INTERNAL_ACCOUNT_DETAILS_ROWS: EntityDetailRow<InternalAccount, InternalAccountCustomRows>[] = [
    'id',
    'created_at',
    'name',
    'type',
    'account_number',
    'bank_code',
    'account_holder_id',
    'holder_name',
    'holder_address',
    'creditor_identifier',
    'status',
    'connected_account_ids',
    'related_objects',
    'custom_fields',
    'metadata'
]

export const INTERNAL_ACCOUNT_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    InternalAccount,
    InternalAccountCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    account_number: {
        cell(item) {
            const formattedValue = accountNumberFormatter(item.account_number)
            return <EntityDetailCellTextWithCopyButton value={formattedValue} formatter={whitespaceFormatter} />
        }
    },
    bank_code: {
        cell(item) {
            return <BankCode value={item.bank_code} />
        }
    },
    account_holder_id: {
        cell(item) {
            return <AccountHolderName accountHolderId={item.account_holder_id} />
        }
    },
    holder_address: {
        cell(account) {
            return <EntityDetailCellHolderAddress value={account?.holder_address} />
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    connected_account_ids: {
        cell(item) {
            return <ConnectedAccountsName connectedAccountIds={item.connected_account_ids} />
        }
    },
    related_objects: {
        cell(item) {
            return <InternalAccountDetailsRelatedObjects internalAccount={item} />
        }
    },
    metadata: {
        nested: true
    }
}
