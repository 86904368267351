import {
    EntityDetailCellRelatedObjectsLinks,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers
} from '@/components'
import { ConnectedAccountName, FormattedDateTime, SepaReturnReasonDescription, StatusLabel } from '@/components/@misc'
import { DenyReason, Inquiry } from '@webapps/numeral-ui-core'
import { InquiryDetailsCustomRows } from './InquiryDetails.types'
import { prettyPrintCasedWords } from '@/utils'

export const INQUIRY_DETAILS_ROWS: EntityDetailRow<Inquiry, InquiryDetailsCustomRows>[] = [
    'id',
    'created_at',
    'connected_account_id',
    'status',
    'status_details',
    'related_request_type',
    'related_objects',
    'bank_data'
]

export const INQUIRY_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<Inquiry, InquiryDetailsCustomRows> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    status_details: {
        cell(item) {
            return <SepaReturnReasonDescription sepaReturnReason={item.status_details as DenyReason} />
        }
    },
    related_request_type: {
        cell(item) {
            return <>{prettyPrintCasedWords(item.related_request_type)}</>
        }
    },
    related_objects: {
        cell(item) {
            return (
                <EntityDetailCellRelatedObjectsLinks
                    relatedPaymentId={item.related_request_id}
                    relatedFileId={item.bank_data?.file_id}
                    relatedPaymentType={item.related_request_type}
                    showRelatedPaymentLink={true}
                    showRelatedClaimLink={true}
                />
            )
        }
    },
    bank_data: {
        nested: true
    }
}
