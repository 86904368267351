import { Accordion, AccordionButton, AccordionItem, Skeleton } from '@chakra-ui/react'

export const GroupAccordionLoading: React.FC = () => {
    return (
        <Accordion>
            <AccordionItem isDisabled={true} borderY="unset">
                <AccordionButton
                    display="flex"
                    padding="var(--numeral-ui-primary-spacing)"
                    justifyContent="space-between">
                    <Skeleton width="100%" height="var(--numeral-ui-primary-spacing)" />
                </AccordionButton>
            </AccordionItem>
        </Accordion>
    )
}
