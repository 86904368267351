export default {
    global: {
        ':root': {
            // globals:
            '--numeral-ui-primary-spacing': '16px',
            '--numeral-ui-primary-border-radius': '2px',
            // z-index:
            '--numeral-ui-splash-screen-z-index': 9,
            '--numeral-ui-main-layout-banner-z-index': 8,
            '--numeral-ui-main-layout-header-z-index': 2,
            '--numeral-ui-main-layout-content-z-index': 1,
            // dimensions / offsets:
            '--numeral-ui-main-layout-expanded-offset': '256px',
            '--numeral-ui-main-layout-collapsed-offset': '48px',
            '--numeral-ui-main-layout-banner-height': '44px',
            '--numeral-ui-main-layout-transition': 'transform cubic-bezier(0.25, 0.45, 0.45, 0.95) 100ms'
        },
        'html, body': {
            fontSize: '14px',
            overscrollBehaviorY: 'none'
        }
    }
}
