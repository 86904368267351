import {
    BaseHttpClientService,
    extractResponseData,
    List,
    Pagination,
    ServicesConfiguration,
    Uuid
} from '@webapps/numeral-ui-core'
import { Webhook } from './Webhooks.service.types'

export class WebhooksService extends BaseHttpClientService {
    get url() {
        return '/v1/webhooks'
    }

    async findAll(params: Partial<Pagination>, config?: ServicesConfiguration): Promise<List<Webhook>> {
        return this.httpClient
            .get<List<Webhook>>(this.url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    async findById(id: Uuid, config?: ServicesConfiguration): Promise<Webhook> {
        return this.httpClient.get<Webhook>(`${this.url}/${id}`, config).then(extractResponseData)
    }

    async createWebhook(data: Webhook, config?: ServicesConfiguration): Promise<Webhook> {
        return this.httpClient.post<Webhook>(this.url, data, config).then(extractResponseData)
    }

    async updateById(id: Uuid, data: Partial<Webhook>, config?: ServicesConfiguration): Promise<Webhook> {
        return this.httpClient
            .post<Webhook>(
                `${this.url}/${id}`,
                {
                    ...data
                },
                config
            )
            .then(extractResponseData)
    }

    async findAllTopicsAndTypes(config?: ServicesConfiguration) {
        return this.httpClient.get(`${this.url}/all_topics_and_types`, config).then(extractResponseData)
    }
}
