import { QueryFallback, RestrictedSection } from '@/components'
import {
    useMutationDenyByIdReturnRequest,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useQueryFindByIdReturnRequest
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { updateUUIDRelativeActionInPathname } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'
import { canAcceptOrDenyReturnRequestByStatus } from '../ReturnRequests.page.utils'
import { ReturnRequestDenyForm } from './@components'

export const ReturnRequestDenyPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.investigations.return_requests.deny.title' }),
        [intl]
    )
    const query = useQueryFindByIdReturnRequest(uuid)
    const mutation = useMutationDenyByIdReturnRequest(uuid, {
        onSuccess() {
            const { PAYMENTS } = relativePaths
            const path = updateUUIDRelativeActionInPathname(location.pathname, PAYMENTS.DETAILS)

            navigateWithLegalEntityID(path)
        }
    })
    const isDisabled = useMemo<boolean>(() => {
        const canAcceptOrDeny = canAcceptOrDenyReturnRequestByStatus(query.data?.status)
        const isLoading = query.isLoading || mutation.isPending
        return !canAcceptOrDeny || isLoading
    }, [query, mutation])

    return (
        <PageLayout className="ReturnRequestDeny">
            <PageHeaderLayout title={title} />
            <RestrictedSection
                feature={ENTITY_FEATURE.RETURN_REQUEST}
                permission={PERMISSION.INVESTIGATIONS_APPROVE_RETURN_REQUESTS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.return_request}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <ReturnRequestDenyForm
                        onSubmit={mutation.mutate}
                        loading={mutation.isPending}
                        disabled={isDisabled}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
