import { isFileBlob } from '@/utils'
import { fileValidator } from '@/utils/@validators'
import { Box, FormControl, FormLabel } from '@chakra-ui/react'
import classNames from 'classnames'
import { useField } from 'formik'
import React, { ChangeEvent, createRef, DragEventHandler, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    FileUploadInputBrowseLink,
    FileUploadInputStatusActive,
    FileUploadInputStatusError,
    FileUploadInputStatusProcessing,
    FileUploadInputStatusSuccess
} from './@components'
import { usePreventWindowDragOverAndDrop } from './@hooks'
import './FileUploadInput.scss'
import { FileReaderReadyState, FileUploadInputDataTransferKind, FileUploadInputProps } from './FileUploadInput.types'
import {
    acceptedFileInputExtensions,
    formatAcceptedExtensions,
    formatAcceptedSizeBytesToMB,
    getFileReaderInstanceWithEventHandlers
} from './FileUploadInput.utils'

export const FileUploadInput: React.FC<FileUploadInputProps> = ({
    name,
    value = '',
    isDisabled,
    customLabelKey,
    acceptedExtensions,
    acceptedSize,
    isRequired,
    validator = fileValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [isProcessing, setIsProcessing] = useState(false)
    const inputAcceptedExtensions = useMemo(() => acceptedFileInputExtensions(acceptedExtensions), [acceptedExtensions])
    const formattedAcceptedExtensions = useMemo(
        () => formatAcceptedExtensions(acceptedExtensions),
        [acceptedExtensions, intl]
    )
    const formattedAcceptedSize = useMemo(() => formatAcceptedSizeBytesToMB(acceptedSize), [acceptedSize])
    const [field, meta, helpers] = useField<File>({
        name,
        value,
        accept: inputAcceptedExtensions,
        validate: validator?.(
            intl,
            { isRequired },
            {
                acceptedExtensions,
                acceptedSize,
                formattedAcceptedSize,
                formattedAcceptedExtensions
            }
        )
    })
    const refInput = createRef<HTMLInputElement>()
    const labelKey = useMemo<string>(
        () => customLabelKey || `app.common.form.input.${name}.label`,
        [customLabelKey, name]
    )
    const hasError = useMemo(() => globalThis.Boolean(meta.error?.length), [meta.error])
    const isBlobValue = useMemo(() => isFileBlob(field?.value), [field?.value])
    const isSuccessful = useMemo(() => !isDisabled && isBlobValue && !hasError, [hasError, isBlobValue, isDisabled])
    const isActive = !isDisabled && !hasError && !isBlobValue
    const dropZoneContainerClassName = useMemo(() => {
        return classNames('FileUploadInput-Container', {
            Error: hasError,
            Active: isActive,
            Disabled: isDisabled,
            Success: isSuccessful && !isProcessing,
            Processing: isProcessing && !isSuccessful
        })
    }, [hasError, isProcessing, isActive, isDisabled, isSuccessful])
    const fileReaderEventHandlers = useMemo(() => {
        const errorMessage = intl.formatMessage({
            id: '"app.common.error.title"'
        })

        return {
            onload(fileBlob: File, fileReader: FileReader) {
                switch (fileReader.readyState) {
                    case FileReaderReadyState.DONE: {
                        helpers.setValue(fileBlob)
                        break
                    }

                    case FileReaderReadyState.EMPTY: {
                        helpers.setError(errorMessage)
                        break
                    }
                }
            },
            onloadstart() {
                setIsProcessing(true)
                helpers.setError(undefined)
            },
            onloadend() {
                setIsProcessing(false)
            }
        }
    }, [intl, helpers])
    const onDragOver = useCallback((event: DragEvent) => {
        event.preventDefault()
    }, [])
    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const files = (event.target as HTMLInputElement).files

            if (files) {
                const fileBlob: File | null = files?.item(0)
                getFileReaderInstanceWithEventHandlers(fileBlob, fileReaderEventHandlers)
            }
        },
        [fileReaderEventHandlers]
    )
    const onDrop = useCallback(
        (event: DragEvent) => {
            const items = event.dataTransfer?.items
            const files = event.dataTransfer?.files
            const itemsOrFiles = files || items

            event.preventDefault()

            if (itemsOrFiles) {
                ;[...itemsOrFiles].forEach((item) => {
                    const file = item as File
                    const dataTransferItem = item as DataTransferItem

                    if (dataTransferItem.kind === FileUploadInputDataTransferKind.FILE) {
                        const fileBlob = dataTransferItem.getAsFile()
                        getFileReaderInstanceWithEventHandlers(fileBlob, fileReaderEventHandlers)
                    }

                    if (isFileBlob(file)) {
                        getFileReaderInstanceWithEventHandlers(file, fileReaderEventHandlers)
                    }
                })
            }
        },
        [fileReaderEventHandlers]
    )
    const propsFileInput = {
        ...inputProps,
        hidden: true,
        multiple: false,
        onChange,
        ref: refInput,
        disabled: isDisabled,
        accept: inputAcceptedExtensions,
        type: FileUploadInputDataTransferKind.FILE,
        'data-testid': name
    }

    usePreventWindowDragOverAndDrop()

    return (
        <FormControl className="FileUploadInput" key={name} isInvalid={meta.touched && !!meta.error}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={labelKey} />
            </FormLabel>
            <Box
                className={dropZoneContainerClassName}
                onDragOver={onDragOver as unknown as DragEventHandler<HTMLDivElement>}
                onDrop={onDrop as unknown as DragEventHandler<HTMLDivElement>}
                minHeight="176px"
                gap="8px"
                padding="16px">
                {(() => {
                    const titleFontWeight = 500

                    switch (true) {
                        case hasError: {
                            const color = 'red.500'
                            const browserLink = <FileUploadInputBrowseLink color={color} refInput={refInput} />

                            return (
                                <FileUploadInputStatusError
                                    error={meta.error}
                                    color={color}
                                    fontWeight={titleFontWeight}
                                    browseLink={browserLink}
                                />
                            )
                        }

                        case isProcessing: {
                            const color = 'gray.500'
                            return <FileUploadInputStatusProcessing color={color} fontWeight={titleFontWeight} />
                        }

                        case isSuccessful: {
                            const color = 'green.500'
                            const browserLink = <FileUploadInputBrowseLink color={color} refInput={refInput} />

                            return (
                                <FileUploadInputStatusSuccess
                                    fileNameWithExtension={field?.value?.name}
                                    browseLink={browserLink}
                                    color={color}
                                    fontWeight={titleFontWeight}
                                />
                            )
                        }

                        case isDisabled:
                        case isActive: {
                            const color = 'gray.500'
                            const disabledLinkColor = isDisabled ? color : undefined
                            const disabledLinkOpacity = isDisabled ? 0.5 : undefined
                            const browserLink = (
                                <FileUploadInputBrowseLink color={disabledLinkColor} refInput={refInput} />
                            )

                            return (
                                <FileUploadInputStatusActive
                                    browseLink={browserLink}
                                    formattedAcceptedSize={formattedAcceptedSize}
                                    color={color}
                                    opacity={disabledLinkOpacity}
                                />
                            )
                        }
                    }
                })()}
                <input {...propsFileInput} />
            </Box>
        </FormControl>
    )
}
