import { useNavigateWithLegalEntityID } from '@/hooks'
import { Row } from '@tanstack/react-table'
import { isString } from 'lodash'
import { useCallback } from 'react'
import { tableRowNavigationURLBuilder } from './useRowTableNavigation.utils'
import { RelativeURLSchema } from './useTableRowNavigation.const'

export function useTableRowNavigation<T = void>(itemKey: keyof T, path?: string, isAppend = false) {
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    return useCallback(
        (row: Row<T>) => {
            if (!isString(itemKey)) {
                return
            }

            const propertyValue = row.original?.[itemKey]
            const url = tableRowNavigationURLBuilder(isAppend, propertyValue, path)
            const validationResult = RelativeURLSchema.safeParse(url)

            if (validationResult.success) {
                navigateWithLegalEntityID(url)
            }
        },
        [navigateWithLegalEntityID, itemKey, path, isAppend]
    )
}
