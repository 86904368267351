import { useNavigateWithLegalEntityID } from '@/hooks'
import { TabBarNavigationItem } from '@/layouts/@components'
import {
    navigationProviderDisabledRoutesFilter,
    navigationProviderIndexRoutesFilter,
    navigationProviderOutletExceptionRoutesFilter,
    NavigationRoute,
    shouldRenderOutlet,
    useNavigationRouteGuard
} from '@/providers'
import { combineFilters } from '@/utils'
import { uuidValidator } from '@/utils/@validators'
import { Box } from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import './NestedTabs.layout.scss'
import { NestedTabsLayoutProps } from './NestedTabs.layout.types'

export const NestedTabsLayout: React.FC<NestedTabsLayoutProps> = ({ routes, header }) => {
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const location = useLocation()
    const { uuid } = useParams()

    const uuidValidation = useMemo(() => uuidValidator(intl), [intl])
    const filteredRoutes = useMemo<ReactNode>(() => {
        if (shouldRenderOutlet(location.pathname)) {
            return null
        }

        const routeItemMapper = (route: NavigationRoute) => {
            const onClick = () => {
                if (!route.path) {
                    return
                }

                navigateWithLegalEntityID(route?.path, { replace: true })
            }
            return <TabBarNavigationItem route={route} onClick={onClick} key={route.path} />
        }

        const combinedFilter = combineFilters<NavigationRoute>(
            navigationProviderIndexRoutesFilter,
            navigationProviderDisabledRoutesFilter,
            navigationProviderOutletExceptionRoutesFilter
        )

        return routes?.filter(combinedFilter).map(routeItemMapper)
    }, [routes, location, navigateWithLegalEntityID])

    useNavigationRouteGuard(() => !uuidValidation(uuid))

    return (
        <Box className="NestedTabsLayout" data-testid="nested-tabs-layout">
            <Box className="NestedTabsLayout-Navigation">
                {header && <div className="NestedTabsLayout-Navigation-Header">{header}</div>}
                {filteredRoutes}
            </Box>
            <Outlet />
        </Box>
    )
}
