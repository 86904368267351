import { QueryFallback, RestrictedSection } from '@/components'
import {
    useMutationCreateReturn,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useQueryFindByIdIncomingPayment
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { isStatusReceived } from '@/pages/Payments/IncomingPayments/IncomingPayments.page.utils'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { IncomingPaymentReturnForm } from './@components'

export const IncomingPaymentReturnPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(
        () =>
            intl.formatMessage({
                id: 'app.payments.incoming_payments.return.title'
            }),
        [intl]
    )
    const query = useQueryFindByIdIncomingPayment(uuid)
    const mutation = useMutationCreateReturn({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const { PAYMENTS } = paths
            const { DETAILS } = relativePaths.PAYMENTS
            const path = [PAYMENTS.RETURNS, response.id, DETAILS].join('/')

            navigateWithLegalEntityID(path)
        }
    })
    const isDisabled = useMemo<boolean>(() => {
        return !isStatusReceived(query.data?.status) || query.isLoading || mutation.isPending
    }, [query, mutation])

    return (
        <PageLayout className="IncomingPaymentReturn">
            <PageHeaderLayout title={title} />
            <RestrictedSection
                feature={ENTITY_FEATURE.INCOMING_PAYMENT}
                permission={PERMISSION.PAYMENTS_CONFIRM_INCOMING_PAYMENTS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.incoming_payment}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <IncomingPaymentReturnForm
                        data={query.data}
                        onSubmit={mutation.mutate}
                        loading={mutation.isPending}
                        disabled={isDisabled}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
