import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams, PERMISSION, WebhookRules } from '@/services'
import { useQuery } from '@tanstack/react-query'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'

export function useQueryFindAllWebhookTopicsAndTypes(
    options?: Partial<UndefinedInitialDataOptions<WebhookRules, Error>>
) {
    const { webhooksService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.DEVELOPERS_VIEW_WEBHOOKS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<WebhookRules, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [webhooksService.url, { ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return webhooksService.findAllTopicsAndTypes({ signal, params: paramsWithUserAccess })
        }
    })
}
