import { EntityAction } from '@/components'
import {
    ConnectedAccountName,
    FormattedAmount,
    FormattedDate,
    If,
    Separator,
    SeparatorVariant
} from '@/components/@misc'
import { SPACE_SYMBOL } from '@/constants'
import { useNavigateWithLegalEntityID, useNavigationRoutes, usePermissions, useQueryFindByIdTransaction } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { BaseReconcileEntityHeaderProps } from '@/pages/Common'
import { updateUUIDRelativeActionInPathname } from '@/providers'
import { isEntityUnreconciled, isReconcilableTransactionObjectType, PERMISSION } from '@/services'
import { Transaction } from '@webapps/numeral-ui-core'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'
import { TransactionExcludeConfirmationModal } from '../TransactionExcludeConfirmationModal'

export type TransactionHeaderProps = BaseReconcileEntityHeaderProps

export const TransactionHeader: React.FC<TransactionHeaderProps> = ({ id, isReconciling, ...props }) => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const location = useLocation()
    const { paths, relativePaths } = useNavigationRoutes()
    const transactionId = uuid || id
    const [isExcludeModalOpen, setIsExcludeModalOpen] = useState(false)

    const query = useQueryFindByIdTransaction(transactionId)

    const title = useMemo<ReactNode>(() => {
        if (!query.data || !hasPermission(PERMISSION.ACCOUNTS_VIEW_TRANSACTIONS)) {
            return null
        }

        const displayDate = query.data.value_date
        const connectedAccountId = query.data.connected_account_id
        const showConnectedAccount = hasPermission(PERMISSION.SETTINGS_VIEW_CONNECTED_ACCOUNTS) && !!connectedAccountId
        const counterPartyHolderName = query.data.external_account.holder_name

        return (
            <>
                <If condition={isReconciling}>
                    <FormattedMessage id="app.reconcile.title" />
                    {SPACE_SYMBOL}
                </If>
                <If condition={!!query.data?.amount}>
                    <FormattedAmount amount={query.data.amount} currency={query.data.currency} />
                    {SPACE_SYMBOL}
                    {query.data.direction}
                </If>
                <If condition={!!counterPartyHolderName}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    {counterPartyHolderName}
                </If>
                <If condition={showConnectedAccount}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <ConnectedAccountName connectedAccountId={connectedAccountId} asPlainText={true} />
                </If>
                <If condition={!!displayDate}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <FormattedDate value={displayDate} />
                </If>
            </>
        )
    }, [isReconciling, query, hasPermission])
    const canExcludeTransaction = useMemo(() => {
        const hasConfigureReconciliationsPermission = hasPermission(
            PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS
        )
        const transaction: Transaction | undefined = query.data

        return (
            hasConfigureReconciliationsPermission &&
            isReconcilableTransactionObjectType(transaction) &&
            isEntityUnreconciled(transaction)
        )
    }, [query.data])
    const onCloseTransactionExcludeConfirmationModal = useCallback(() => {
        setIsExcludeModalOpen(false)
    }, [])
    const actions = useMemo<EntityAction[] | undefined>(() => {
        const actions = []

        if (isReconciling) {
            return
        }

        if (hasPermission(PERMISSION.ACCOUNTS_EDIT_TRANSACTIONS)) {
            actions.push({
                onClick() {
                    const path = updateUUIDRelativeActionInPathname(location.pathname, relativePaths.ACCOUNTS.EDIT)

                    navigateWithLegalEntityID(path)
                },
                children: intl.formatMessage({
                    id: 'app.common.actions.edit.label'
                })
            })
        }

        if (canExcludeTransaction) {
            actions.push({
                onClick() {
                    setIsExcludeModalOpen(true)
                },
                children: intl.formatMessage({
                    id: 'app.accounts.transactions.actions.exclude.label'
                })
            })
        }

        return actions
    }, [
        isReconciling,
        hasPermission,
        uuid,
        paths,
        relativePaths,
        navigateWithLegalEntityID,
        location,
        query,
        canExcludeTransaction
    ])

    return (
        <>
            <PageHeaderLayout {...props} title={title} isLoading={query.isLoading} actions={actions} />
            <If condition={canExcludeTransaction}>
                <TransactionExcludeConfirmationModal
                    uuid={transactionId}
                    isOpen={isExcludeModalOpen}
                    onClose={onCloseTransactionExcludeConfirmationModal}
                />
            </If>
        </>
    )
}
