import { FormSection } from '@/components'
import { CustomFieldInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { useQueryFindAllCustomFields } from '@/hooks'
import { isEmptyRecord, queryDataAggregation } from '@/utils'
import { Box, Flex, Skeleton, Switch, Text, Tooltip } from '@chakra-ui/react'
import { ApiObjectType, CustomField } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { chain, isEmpty } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CustomFieldsSectionProps } from './CustomFieldsSection.types'
import { getEmptyCustomFieldValueState } from './CustomFieldsSection.utils'

const CUSTOM_FIELDS_NAME = 'custom_fields'

export function CustomFieldsSection<T>({ objectType, isDisabled, isExpanded = false }: CustomFieldsSectionProps<T>) {
    const intl = useIntl()
    const { setFieldValue, getFieldProps } = useFormikContext<Partial<T>>()
    const query = useQueryFindAllCustomFields({
        object_type: objectType as ApiObjectType
    })
    const data = useMemo<CustomField[]>(() => queryDataAggregation(query.data), [query])
    const isCustomFieldSectionToggleEnabled = useMemo(() => data.length > 0, [data])

    const sectionToggleTooltipLabel = useMemo<string>(() => {
        return intl.formatMessage({
            id: 'app.common.form.sections.custom_fields.toggle.disabled.tooltip'
        })
    }, [intl])

    const [isSectionExpanded, setIsSectionExpanded] = useState(isExpanded)
    const onToggleCustomFieldsSection = useCallback(() => {
        setIsSectionExpanded((prevState) => !prevState)
    }, [])

    useEffect(() => {
        const customFieldsValue = getFieldProps(CUSTOM_FIELDS_NAME).value
        const hasCustomFieldsValue = !isEmptyRecord(customFieldsValue)

        if (hasCustomFieldsValue && !isSectionExpanded) {
            const emptyCustomFieldValueState = getEmptyCustomFieldValueState(data)

            setFieldValue(CUSTOM_FIELDS_NAME, emptyCustomFieldValueState, false)
        }
    }, [isSectionExpanded])

    return (
        <FormSection
            titleId="app.common.form.sections.custom_fields.title"
            title={
                <Tooltip
                    key="toggle-retry-rule-section-tooltip"
                    shouldWrapChildren={true}
                    isDisabled={isCustomFieldSectionToggleEnabled}
                    label={sectionToggleTooltipLabel}
                    placement="top"
                    hasArrow={true}>
                    <Flex>
                        <Switch
                            data-testid="toggle-retry-rule-section"
                            size="sm"
                            isDisabled={!isCustomFieldSectionToggleEnabled}
                            isChecked={isSectionExpanded}
                            onChange={onToggleCustomFieldsSection}>
                            <FormattedMessage id="app.common.form.sections.custom_fields.toggle.title" />
                        </Switch>
                    </Flex>
                </Tooltip>
            }>
            <If condition={isSectionExpanded}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <Skeleton width="100%" height="32px" />
                        }

                        case isEmpty(data):
                        case query?.isError: {
                            return (
                                <Text>
                                    <FormattedMessage id="app.common.form.sections.custom_fields.empty_content" />
                                </Text>
                            )
                        }

                        default: {
                            return chain(data)
                                .map((item, key) => {
                                    return (
                                        <Box key={key}>
                                            <CustomFieldInput customField={item} isDisabled={isDisabled} />
                                        </Box>
                                    )
                                })
                                .value()
                        }
                    }
                })()}
            </If>
        </FormSection>
    )
}
