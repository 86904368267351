import { chain, has } from 'lodash'
import { Nullable } from '@/types'
import { IntlShape } from 'react-intl'
import { SPACE_SYMBOL } from '@/constants'

/**
 * @description
 * - Deprecated event options (`ApiObjectType`) are removed from the main schema and no longer accessible.
 * - This utility function checks if any of the options has a "Deprecated" i18n resource associated with it.
 */
export function hasDeprecatedWebhookEventOptionLabelWithIntl(intl: IntlShape) {
    return function hasDeprecatedWebhookEventOptionLabel(value: string): boolean {
        return chain(intl)
            .invoke('formatMessage', {
                id: `app.developers.webhooks.form.rules.${value}.label`,
                defaultMessage: SPACE_SYMBOL
            })
            .trim()
            .size()
            .gt(0)
            .value()
    }
}

/**
 * @description
 * Returns a reordered array of keys based on the provided order,
 * appending any additional keys from the data object that are not in the order.
 *
 * @param orderedKeys - An array of keys defining the desired order.
 * @param data - The object containing the keys to be ordered.
 * @returns An array of keys ordered as per `orderedKeys`, with additional keys appended.
 */
export function reorderWebhookEventOptionsWithData<T extends string>(
    orderedKeys: T[],
    data?: Nullable<Record<string, unknown>>
): T[] {
    const dataKeys = chain(data)
        .keys()
        .filter((key) => !orderedKeys.includes(key as T))
        .value()

    return chain<readonly string[]>(orderedKeys)
        .filter((key: typeof orderedKeys) => has(data, key))
        .concat(dataKeys)
        .value() as T[]
}
