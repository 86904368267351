import { isFunction } from 'lodash'

/**
 * @description
 * - Small utility which combines multiple filters into a single one;
 * - Works with a single filter predicate, but asserts if length is not the expected one;
 * @usage
 * const exampleCombinedFilter = combineFilters<ConnectedAccount>(
 *     enabledConnectedAccountsFilter,
 *     createPaymentOrderConnectedAccountServicesFilter
 * )
 * //...
 * // Combined higher-order filter:
 * data.filter(exampleCombinedFilter)
 *
 * // Chained filter function yields the same result as the above:
 * data
 *  .filter(enabledConnectedAccountsFilter)
 *  .filter(createPaymentOrderConnectedAccountServicesFilter)
 */

type FilterFunction<T> = (value: T, index?: number, array?: T[]) => boolean
type Filters<T> = FilterFunction<T>[]

export function combineFilters<T = any>(...args: Filters<T>) {
    const { assert } = globalThis.console
    const hasValidFilters = args.every(isFunction)
    const hasValidNumbersOfFilters = args.length > 1

    assert(
        hasValidFilters && hasValidNumbersOfFilters,
        `The filters are of length one (1).\n
        Please use the filter predicate directly instead of this utility.`
    )

    return (item: T, index?: number, array?: T[]) => {
        return args?.reduce((acc, filter) => acc && filter?.(item, index, array), true)
    }
}
