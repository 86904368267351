import { navigationProviderFooterRoutesFilter, NavigationRoute } from '@/providers'
import { ENTITY_FEATURE, USER_ACCESS_CONFIGURATION_ENTITY_TYPE } from '@/services'
import { combineFilters } from '@/utils'
import { chain, partition } from 'lodash'
import { defaultMainNavigationRoutesFilter } from './MainNavigation.const'

export function getAccordionGroupActiveIndexByCurrentPathname(
    routes: NavigationRoute[],
    currentPathname: string
): number {
    const indexFinder = ({ path }: NavigationRoute) => (path ? currentPathname?.includes(`/${path}`) : false)
    const groupFilter = ({ configuration }: NavigationRoute) => configuration?.isGroup === true

    return chain(routes).filter(groupFilter).findIndex(indexFinder).value()
}

export function isAccordionGroupActive(currentIndex: number, realIndex: number, routes: NavigationRoute[]): boolean {
    const groupFilter = ({ configuration }: NavigationRoute) => configuration?.isGroup === true

    return chain(routes)
        .findIndex(groupFilter)
        .thru((foundIndex) => realIndex - foundIndex === currentIndex)
        .value()
}

/**
 * @description
 * Partition enabled main routes and enabled footer routes
 */
export function getPartitionedNavigationRoutes<T>(
    routes: NavigationRoute[],
    ...filterFunctions: ((item: NavigationRoute, index?: number) => boolean)[]
): [NavigationRoute[], NavigationRoute[]] {
    const defaultRoutesFilter =
        filterFunctions && filterFunctions?.length > 0
            ? combineFilters<NavigationRoute>(defaultMainNavigationRoutesFilter, ...filterFunctions)
            : defaultMainNavigationRoutesFilter

    const filteredRoutes = routes.filter(defaultRoutesFilter)

    return partition(filteredRoutes, navigationProviderFooterRoutesFilter)
}

/**
 * Generate a filter that filters out routes that don't match current legal entity type
 * @param currentLegalEntityType Current type of the legal entity
 * @returns true if the route isn't restricted to financial institution or if the current legal entity is a financial institution
 */
export function getLegalEntityTypeRoutesFilter(
    currentLegalEntityType?: USER_ACCESS_CONFIGURATION_ENTITY_TYPE
): (route: NavigationRoute) => boolean {
    return (route) => {
        return (
            !route.configuration?.isForFinancialInstitution ||
            currentLegalEntityType === USER_ACCESS_CONFIGURATION_ENTITY_TYPE.FINANCIAL_INSTITUTION
        )
    }
}

/**
 * Generate a filter that filters out routes that require a feature not currently active
 * @param activeFeatures List of active features for the current legal entity
 * @returns true if the route doesn't require a feature or if the feature required is part of the active features
 */
export function getLegalEntityFeatureRoutesFilter(
    activeFeatures?: ENTITY_FEATURE[]
): (route: NavigationRoute) => boolean {
    return ({ configuration }) => {
        return !configuration?.feature || !!activeFeatures?.includes(configuration?.feature)
    }
}

/**
 * @description
 * Check if the routePath is active
 * @returns true if the `currentPath` equals the `routePath` or is a child of the routePath
 */
export function isCurrentPathActive(currentPath: string, routePath?: string): boolean {
    if (!routePath) {
        return false
    }
    return currentPath.includes(routePath)
}
