import { CounterpartiesIcon } from '@/components/@icons'
import { ParentPageLayout } from '@/layouts'
import {
    AccountHolderDetailsPage,
    AccountHoldersPage,
    AuditTrailFilterByOption,
    AuditTrailPage,
    DirectDebitMandateCounterpartiesPage,
    DirectDebitMandateCreatePage,
    DirectDebitMandateDetailsPage,
    DirectDebitMandatesDetailsHeader,
    DirectDebitMandatesPage,
    EventsPage,
    ExternalAccountCreatePage,
    ExternalAccountDenyPage,
    ExternalAccountDetailsPage,
    ExternalAccountHeader,
    ExternalAccountsPage,
    ExternalAccountVerificationsPage,
    InternalAccountCreatePage,
    InternalAccountDetailsHeader,
    InternalAccountDetailsPage,
    InternalAccountsPage
} from '@/pages'
import { AccountHolderHeader } from '@/pages/Counterparties/AccountHolders/AccountHolderHeader'
import { ExternalAccountEditPage } from '@/pages/Counterparties/ExternalAccounts/ExternalAccountEdit'
import { getNestedRoutesWithTabLayout, NavigationRoute } from '@/providers'
import { ApiObjectType, ApiObjectTypeSchema } from '@webapps/numeral-ui-core'
import { Navigate } from 'react-router-dom'
import { NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS } from '../NavigationRoutesProvider.const'

function getCommonCounterpartiesRoutes(objectType: ApiObjectType) {
    const { COUNTERPARTIES } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS

    return [
        {
            title: 'app.counterparties.tabs.events.title',
            path: COUNTERPARTIES.EVENTS,
            element: <EventsPage />
        },
        {
            title: 'app.counterparties.tabs.audit_trail.title',
            path: COUNTERPARTIES.AUDIT_TRAIL,
            element: <AuditTrailPage filterBy={AuditTrailFilterByOption.OBJECT} />
        }
    ]
}

export function getCounterpartiesNavigationRoutes(): NavigationRoute {
    const { NEW, EDIT, UUID, COUNTERPARTIES, DENY } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS

    const accountHoldersRoutes = [
        {
            title: 'app.counterparties.tabs.details.title',
            path: COUNTERPARTIES.DETAILS,
            element: <AccountHolderDetailsPage />
        },
        ...getCommonCounterpartiesRoutes(ApiObjectTypeSchema.enum.account_holder)
    ]

    const internalAccountsRoutes = [
        {
            title: 'app.counterparties.tabs.details.title',
            path: COUNTERPARTIES.DETAILS,
            element: <InternalAccountDetailsPage />
        },
        ...getCommonCounterpartiesRoutes(ApiObjectTypeSchema.enum.internal_account)
    ]

    const externalAccountsRoutes = [
        {
            title: 'app.counterparties.tabs.details.title',
            path: COUNTERPARTIES.DETAILS,
            element: <ExternalAccountDetailsPage />
        },
        {
            title: 'app.counterparties.tabs.verifications.title',
            path: COUNTERPARTIES.VERIFICATIONS,
            element: <ExternalAccountVerificationsPage />
        },
        ...getCommonCounterpartiesRoutes(ApiObjectTypeSchema.enum.external_account)
    ]

    const directDebitMandatesRoutes = [
        {
            title: 'app.counterparties.tabs.details.title',
            path: COUNTERPARTIES.DETAILS,
            element: <DirectDebitMandateDetailsPage />
        },
        {
            title: 'app.counterparties.tabs.counterparties.title',
            path: COUNTERPARTIES.COUNTERPARTIES,
            element: <DirectDebitMandateCounterpartiesPage />
        },
        ...getCommonCounterpartiesRoutes(ApiObjectTypeSchema.enum.direct_debit_mandate)
    ]

    return {
        title: 'app.counterparties.title',
        path: 'counterparties',
        configuration: {
            isGroup: true
        },
        icon: <CounterpartiesIcon />,
        routes: [
            { index: true, element: <Navigate replace={true} to="account-holders" /> },
            {
                title: 'app.counterparties.account_holders.title',
                path: 'account-holders',
                element: <ParentPageLayout element={<AccountHoldersPage />} />,
                routes: [
                    // {
                    //     title: 'app.counterparties.external_accounts.create.title',
                    //     path: NEW,
                    //     element: <ExternalAccountCreatePage />
                    // },
                    // {
                    //     title: 'app.counterparties.external_accounts.edit.title',
                    //     path: EDIT,
                    //     element: <ExternalAccountEditPage />
                    // },
                    ...getNestedRoutesWithTabLayout(UUID, accountHoldersRoutes, {
                        header: <AccountHolderHeader />
                    })
                ]
            },
            {
                title: 'app.counterparties.internal_accounts.title',
                path: 'internal-accounts',
                element: <ParentPageLayout element={<InternalAccountsPage />} />,
                routes: [
                    {
                        title: 'app.counterparties.internal_accounts.create.title',
                        path: NEW,
                        element: <InternalAccountCreatePage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, internalAccountsRoutes, {
                        header: <InternalAccountDetailsHeader />
                    })
                ]
            },
            {
                title: 'app.counterparties.external_accounts.title',
                path: 'external-accounts',
                element: <ParentPageLayout element={<ExternalAccountsPage />} />,
                routes: [
                    {
                        title: 'app.counterparties.external_accounts.create.title',
                        path: NEW,
                        element: <ExternalAccountCreatePage />
                    },
                    {
                        title: 'app.counterparties.external_accounts.edit.title',
                        path: EDIT,
                        element: <ExternalAccountEditPage />
                    },
                    {
                        title: 'app.counterparties.external_accounts.deny.title',
                        path: DENY,
                        element: <ExternalAccountDenyPage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, externalAccountsRoutes, {
                        header: <ExternalAccountHeader />
                    })
                ]
            },
            {
                title: 'app.counterparties.direct_debit_mandates.title',
                path: 'direct-debit-mandates',
                element: <ParentPageLayout element={<DirectDebitMandatesPage />} />,
                routes: [
                    {
                        title: 'app.counterparties.direct_debit_mandates.create.title',
                        path: NEW,
                        element: <DirectDebitMandateCreatePage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, directDebitMandatesRoutes, {
                        header: <DirectDebitMandatesDetailsHeader />
                    })
                ]
            }
        ]
    }
}
