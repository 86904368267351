import { z } from 'zod'
import { ApiObjectTypeSchema, DateISOFormatSchema, UuidSchema, WildcardSchema } from '@webapps/numeral-ui-core'

export const WebhookRulesSchema = z.record(
    ApiObjectTypeSchema.or(WildcardSchema),
    z.array(z.string().or(WildcardSchema))
)

export const WebhookStatusSchema = z.enum(['enabled', 'disabled'])

export const WebhookSchema = z.object({
    id: UuidSchema.optional(),
    name: z.string().min(1),
    url: z.string().url(),
    enabled: z.boolean().optional(),
    rules: WebhookRulesSchema.optional().nullable(),
    created_at: DateISOFormatSchema.optional(),
    fifo: z.boolean().optional(),
    batching_size: z.number().min(1).max(100).optional()
})
