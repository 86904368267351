import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { ReturnRelatedPaymentType } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Select } from '@/components/@misc'
import { useCustomFormikField } from '@/hooks'

export const ReturnRequestRelatedPaymentTypeInput: React.FC<FormikInput> = ({ name, value, ...inputProps }) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField</* @todo Expose type */ typeof value>(name)
    const placeholder = useMemo(() => {
        return intl.formatMessage({ id: 'app.common.form.input.select.placeholder' })
    }, [intl])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Select<typeof value>
                {...inputProps}
                {...field}
                id={name}
                placeholder={placeholder}
                onChange={helpers.setValue}
                isInvalid={isInvalid}
                options={ReturnRelatedPaymentType.options}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
