import { useMutationUserInfo, useQueryFindCurrentUserInfo } from '@/hooks'
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { UserInfo } from '@webapps/numeral-ui-core'
import { noop, omit } from 'lodash'
import { useCallback, useMemo } from 'react'
import { If } from '../@misc'
import { UserInfoForm } from './@components'
import { USER_INFO_HIDDEN_KEYS } from './IncompleteUserInfoModal.const'
import { getUserInfoUpdatedFields, isUserInfoIncomplete } from './IncompleteUserInfoModal.utils'

export const IncompleteUserInfoModal: React.FC = () => {
    const queryUserInfo = useQueryFindCurrentUserInfo()
    const hasIncompleteUserInfo = useMemo(() => isUserInfoIncomplete(queryUserInfo.data), [queryUserInfo.data])

    const mutation = useMutationUserInfo()
    const initialUserInfo = useMemo(() => omit(queryUserInfo.data, USER_INFO_HIDDEN_KEYS), [queryUserInfo.data])

    const onUserInfoUpdate = useCallback(
        (data: Partial<UserInfo>) => {
            const updatedUserInfo = getUserInfoUpdatedFields<UserInfo>(queryUserInfo.data, data)

            if (Object.keys(updatedUserInfo).length > 0) {
                mutation.mutate(updatedUserInfo)
            }
        },
        [queryUserInfo, mutation]
    )

    return (
        <If condition={hasIncompleteUserInfo}>
            <Modal
                isOpen={hasIncompleteUserInfo}
                onClose={noop}
                isCentered={true}
                closeOnOverlayClick={false}
                size="xl">
                <ModalOverlay />
                <ModalContent padding="40px">
                    <UserInfoForm data={initialUserInfo} onSubmit={onUserInfoUpdate} isLoading={mutation.isPending} />
                </ModalContent>
            </Modal>
        </If>
    )
}
