import { QueryFallback, RestrictedSection, Table, useTableRowNavigation } from '@/components'
import { useQueryFindAllReconciliationRules } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { ReconciliationRule, ReconciliationRuleStatusSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import {
    RECONCILIATION_RULES_TABLE_COLUMN_CUSTOMIZERS,
    RECONCILIATION_RULES_TABLE_STATIC_STATE
} from './ReconciliationRules.page.const'
import { ReconciliationRulesCustomColumns } from './ReconciliationRules.page.type'

export const ReconciliationRulesPage: React.FC = () => {
    const query = useQueryFindAllReconciliationRules({ status: ReconciliationRuleStatusSchema.enum.active })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const onTableRowClick = useTableRowNavigation<ReconciliationRule>('id')

    return (
        <PageLayout className="ReconciliationRules">
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.SETTINGS_VIEW_RECONCILIATION_RULES}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.reconciliation_rule}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}>
                    <Table<ReconciliationRule, ReconciliationRulesCustomColumns>
                        data={data}
                        onRowClick={onTableRowClick}
                        columnCustomizers={RECONCILIATION_RULES_TABLE_COLUMN_CUSTOMIZERS}
                        state={RECONCILIATION_RULES_TABLE_STATIC_STATE}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
