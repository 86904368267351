import { useMemo } from 'react'
import { Text } from '@chakra-ui/react'
import { chain } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { APIError } from '@/types'
import { COLON_SYMBOL, DASH_SEPARATOR } from '@/constants'
import { AxiosError } from 'axios'
import { If } from '@/components/@misc/If'

interface APIValidationErrorNotificationBody<T> {
    messageId: string
    error: AxiosError<APIError<T>>
}

/**
 * @usage
 * Construct a message based on API response structure APIError<T>
 * @todo
 * Migrate file and apply in other flows (ongoing) to reduce duplication.
 */
export function APIValidationErrorNotificationBody<T>({ error, messageId }: APIValidationErrorNotificationBody<T>) {
    const errorResponseDataWrapper = useMemo(() => chain(error).get('response.data'), [error])
    const errorDetails = useMemo(() => errorResponseDataWrapper.get('details', []).value(), [error])
    const errorMessage = useMemo(() => errorResponseDataWrapper.get('message', error.message).value(), [error])
    const hasErrorDetails = useMemo(() => errorDetails.length > 0, [error])
    return (
        <>
            <Text>
                <FormattedMessage id={messageId} />
            </Text>
            <If condition={hasErrorDetails}>
                {errorDetails.map((detail, index: number) => {
                    const formattedField = globalThis.String(detail?.field)
                    const formattedReason = globalThis.String(detail?.reason)
                    const formattedDetail = `${DASH_SEPARATOR} ${formattedField}${COLON_SYMBOL} ${formattedReason}`

                    return (
                        <Text whiteSpace="normal" key={index}>
                            {formattedDetail}
                        </Text>
                    )
                })}
            </If>
            <If condition={!hasErrorDetails}>{errorMessage}</If>
        </>
    )
}
