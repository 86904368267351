import { isCustomFieldKeyValueDuplicated } from '@/services'
import { CustomField, CustomFieldTypesSchema, CustomFieldValue } from '@webapps/numeral-ui-core'
import { FieldValidator } from 'formik'
import { isEmpty } from 'lodash'
import { IntlShape } from 'react-intl'
import { FormikInputConfiguration } from '@/types'
import { EMPTY_CHAR_SYMBOL } from '@/constants'

export function getCustomFieldFormInitialValues(data?: Partial<CustomField>): Partial<CustomField> {
    return {
        key: EMPTY_CHAR_SYMBOL,
        name: EMPTY_CHAR_SYMBOL,
        object_types: [],
        values: [{ name: EMPTY_CHAR_SYMBOL, key: EMPTY_CHAR_SYMBOL }],
        type: CustomFieldTypesSchema.enum.select,
        ...data
    }
}

export function customFieldValuesValidator(intl: IntlShape, configuration?: FormikInputConfiguration): FieldValidator {
    return (value: CustomFieldValue[]): string | void => {
        function hasUniqueCustomFieldKeyValues(value: CustomFieldValue[], key: keyof CustomFieldValue = 'key') {
            return value && !value?.some((item) => isCustomFieldKeyValueDuplicated(value, key, item.key))
        }

        if (configuration?.isRequired && isEmpty(value)) {
            return intl.formatMessage({ id: 'app.common.form.validation.custom_field_values.required' })
        }

        if (configuration?.isRequired && !hasUniqueCustomFieldKeyValues(value)) {
            return intl.formatMessage({ id: 'app.common.form.validation.custom_field_values.unique_key.invalid' })
        }
    }
}
