import {
    useMutationUpdateByIdWebhook,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useQueryFindByIdWebhook
} from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

export const WebhookHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const query = useQueryFindByIdWebhook(uuid)
    const mutation = useMutationUpdateByIdWebhook(uuid)
    const { relativePaths } = useNavigationRoutes()

    const notFoundTitle = intl.formatMessage({
        id: 'app.common.not_found.title'
    })
    const title = useMemo(() => {
        return (query.isError && notFoundTitle) || query.data?.name || uuid
    }, [notFoundTitle, query, uuid])
    const actions = useMemo(() => {
        const isDisabled = !query.data?.enabled

        return [
            {
                onClick() {
                    const { EDIT } = relativePaths.DEVELOPERS
                    navigateWithLegalEntityID(EDIT)
                },
                children: intl.formatMessage({
                    id: 'app.developers.webhooks.edit.actions.edit.label'
                })
            },
            {
                onClick() {
                    mutation.mutate({
                        enabled: isDisabled
                    })
                },
                children: intl.formatMessage({
                    id: isDisabled
                        ? 'app.developers.webhooks.edit.actions.enable.label'
                        : 'app.developers.webhooks.edit.actions.disable.label'
                }),
                color: isDisabled ? undefined : 'red.500',
                isLoading: mutation.isPending,
                disabled: mutation.isPending
            }
        ]
    }, [intl, query, mutation, navigateWithLegalEntityID, relativePaths])

    return <PageHeaderLayout title={title} actions={actions} isLoading={query.isLoading} />
}
