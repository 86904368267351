import { FormControl, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PaymentType, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { isReconcilablePaymentObjectType } from '@/services'
import { FormikInput, Payment } from '@/types'
import { useMemo, useRef } from 'react'
import { useField } from 'formik'
import { anythingValidator } from '@/utils/@validators'

export const ReconcilablePaymentTypeInput: React.FC<FormikInput> = ({
    name,
    value,
    isDisabled,
    isRequired,
    validator = anythingValidator
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField({
        name,
        value,
        validate: validator?.(intl, { isRequired })
    })
    const reconcilablePaymentsFilter = useRef((object: PaymentType) => {
        return isReconcilablePaymentObjectType({ object } as Payment)
    }).current
    const filteredOptions = useMemo(() => {
        return PaymentTypeSchema.options.filter(reconcilablePaymentsFilter)
    }, [reconcilablePaymentsFilter])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id="app.reconcile.form.payment_type.label" />
            </FormLabel>
            <RadioGroup
                name={name}
                id={name}
                value={field.value}
                onChange={helpers.setValue}
                isDisabled={isDisabled}
                data-testid={name}>
                <Stack gap="16px" marginBottom="8px">
                    {filteredOptions.map((filteredOption, index) => (
                        <Radio value={filteredOption} key={index}>
                            <FormattedMessage id={`api.object.${filteredOption}`} />
                        </Radio>
                    ))}
                </Stack>
            </RadioGroup>
        </FormControl>
    )
}
