import {
    EntityDetailCellRelatedObjectsLinks,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    TableCellAccount
} from '@/components'
import {
    ConnectedAccountName,
    FormattedAmount,
    FormattedDate,
    FormattedDateTime,
    FormattedTextIntl,
    PaymentType,
    ReconciliationStatusLabel,
    SepaReturnReasonDescription,
    StatusLabel
} from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { prettyPrintCasedWords } from '@/utils'
import { Text } from '@chakra-ui/react'
import { PaymentOrder, SepaReturnReason } from '@webapps/numeral-ui-core'
import { PaymentOrderDetailsCustomRows } from './PaymentOrderDetails.types'

export const PAYMENT_ORDER_ENTITY_DETAIL_ROWS: EntityDetailRow<PaymentOrder, PaymentOrderDetailsCustomRows>[] = [
    'id',
    'created_at',
    'type',
    'treasury_option',
    'confidentiality_option',
    'fee_option',
    'amount',
    'connected_account_id',
    'originating_account',
    'receiving_account',
    'reference',
    'purpose',
    'requested_execution_date',
    'value_date',
    'status',
    'status_details',
    'reconciliation_status',
    'reconciled_amount',
    //'direct_debit_mandate' conditionally added via utility
    'related_objects',
    'custom_fields',
    'metadata',
    'bank_data',
    'idempotency_key'
]

export const PAYMENT_ORDER_ENTITY_DETAIL_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    PaymentOrder,
    PaymentOrderDetailsCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    type: {
        cell(item) {
            return <PaymentType type={item.type} direction={item.direction} isAbbreviated={true} />
        }
    },
    treasury_option: {
        cell(item) {
            return <FormattedTextIntl messageId={`api.payment.treasury_option.${item.treasury_option}`} />
        }
    },
    confidentiality_option: {
        cell(item) {
            return <FormattedTextIntl messageId={`api.payment.confidentiality_option.${item.confidentiality_option}`} />
        }
    },
    fee_option: {
        cell(item) {
            const formattedValue = prettyPrintCasedWords(item?.fee_option)
            return <Text>{formattedValue || EMPTY_VALUE_PLACEHOLDER}</Text>
        }
    },
    amount: {
        cell(item) {
            return <FormattedAmount amount={item.amount} currency={item.currency} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    originating_account: {
        cell(item) {
            return <TableCellAccount value={item.originating_account} />
        }
    },
    receiving_account: {
        cell(item) {
            return <TableCellAccount value={item.receiving_account} />
        }
    },
    value_date: {
        cell(item) {
            return <FormattedDate value={item.value_date} />
        }
    },
    requested_execution_date: {
        cell(item) {
            return <FormattedDate value={item?.requested_execution_date} />
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    status_details: {
        cell(item) {
            // Trying to match the status_details to a sepa return reason to display a description
            // SepaReturnReasonDescription will passthrough the status_detail if it is not a valid sepa return reason
            return <SepaReturnReasonDescription sepaReturnReason={item.status_details as SepaReturnReason} />
        }
    },
    reconciliation_status: {
        cell(item) {
            return <ReconciliationStatusLabel value={item.reconciliation_status} />
        }
    },
    reconciled_amount: {
        cell(item) {
            return <FormattedAmount amount={item.reconciled_amount} currency={item.currency} />
        }
    },
    related_objects: {
        cell(item) {
            return (
                <EntityDetailCellRelatedObjectsLinks
                    relatedPaymentId={item.id}
                    relatedPaymentType={item.object}
                    relatedFileId={item.bank_data?.file_id}
                    relatedPaymentFileId={item.payment_file_id}
                    showRelatedReturnsLink={true}
                    showRelatedReturnRequestsLink={true}
                    showRelatedClaimsLink={true}
                />
            )
        }
    },
    metadata: {
        nested: true
    },
    bank_data: {
        nested: true
    }
}
