import { PropsWithChildren, ReactNode, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ExternalLink, LoadingSpinner } from '../@misc'
import { EmptyState } from '../EmptyState'
import { QueryFallbackProps } from './QueryFallback.types'
import { prettyPrintCasedWords } from '@/utils'

export const QueryFallback: React.FC<PropsWithChildren<QueryFallbackProps>> = ({
    objectType,
    isLoading,
    isSuccess,
    isError,
    isFilteringOrSearching,
    isDataEmpty,
    customEmptyStateTitleKey,
    customEmptyStateDescriptionKey,
    customEmptyStateImage,
    learnMoreLinkUrl,
    emptyStateButtonProps,
    children
}) => {
    const intl = useIntl()
    const pluralizedObjectType = useMemo(() => {
        return intl?.formatMessage(
            { id: 'app.common.pluralization.label' },
            {
                count: 2,
                singular: prettyPrintCasedWords(objectType)
            }
        )
    }, [objectType])
    const emptyStateTitle = useMemo<ReactNode>(() => {
        if (!isDataEmpty) {
            return
        }

        if (isError) {
            return intl.formatMessage({ id: 'app.common.error.title' })
        }

        if (isSuccess && isFilteringOrSearching) {
            return intl.formatMessage(
                { id: 'app.common.query_fallback.empty_state.filtering_or_searching.title' },
                {
                    objectType: pluralizedObjectType.toLowerCase()
                }
            )
        }

        if (isSuccess && !isFilteringOrSearching) {
            return intl.formatMessage(
                { id: customEmptyStateTitleKey ?? 'app.common.query_fallback.empty_state.title' },
                {
                    objectType: pluralizedObjectType.toLowerCase()
                }
            )
        }
    }, [isError, isDataEmpty, isSuccess, isFilteringOrSearching, objectType, intl])

    const emptyStateDescription = useMemo<ReactNode>(() => {
        if (!isDataEmpty || isError) {
            return
        }

        if (isSuccess && isFilteringOrSearching) {
            return intl.formatMessage({
                id: 'app.common.query_fallback.empty_state.filtering_or_searching.description'
            })
        }

        if (isSuccess && !isFilteringOrSearching) {
            return (
                <>
                    <FormattedMessage
                        id={customEmptyStateDescriptionKey ?? 'app.common.query_fallback.empty_state.description'}
                        values={{ objectType: pluralizedObjectType }}
                    />
                    {learnMoreLinkUrl && (
                        <ExternalLink
                            marginLeft="4px"
                            labelKey="app.common.link.learn_more.label"
                            url={learnMoreLinkUrl}
                        />
                    )}
                </>
            )
        }
    }, [isDataEmpty, isError, isSuccess, isFilteringOrSearching, pluralizedObjectType, intl])

    const buttonProps = useMemo(() => {
        if (isSuccess && !isFilteringOrSearching) {
            return emptyStateButtonProps
        }
    }, [isSuccess, isFilteringOrSearching, emptyStateButtonProps])

    if (isLoading) {
        return <LoadingSpinner />
    }

    if (isDataEmpty) {
        return (
            <EmptyState
                title={emptyStateTitle}
                description={emptyStateDescription}
                imageSrc={customEmptyStateImage}
                propsButton={buttonProps}
            />
        )
    }

    return children
}
