import { Webhook, WEBHOOK_ALL_RULES, WEBHOOK_RULES_MODES } from '@/services'
import { isEqualWith } from 'lodash'
import { isEmptyRecord } from '@/utils'

/**
 * @todo
 * Add unit test
 */
export function areIndividualWebhookRulesEnabled(value: string): boolean {
    return value === WEBHOOK_RULES_MODES.INDIVIDUAL
}

/**
 * @todo
 * Add unit test
 */
export function getWebhookRulesModeInitialValue(data?: Webhook) {
    switch (true) {
        case isEqualWith(data?.rules, WEBHOOK_ALL_RULES):
        case isEmptyRecord(data?.rules): {
            return WEBHOOK_RULES_MODES.ALL
        }

        default: {
            return WEBHOOK_RULES_MODES.INDIVIDUAL
        }
    }
}
