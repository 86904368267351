import { FieldValidator } from 'formik'
import { IntlShape } from 'react-intl'
import { SafeParseReturnType, z } from 'zod'
import { asOptionalField, sanitiseValidatorValue } from '../validators.utils'
import { get, isEmpty } from 'lodash'
import { FormikInputConfiguration } from '@/types'

const DEFAULT_MIN_LENGTH_VALUE = 1

export function minLengthValidator(intl: IntlShape, configuration?: FormikInputConfiguration): FieldValidator {
    const minLengthValue = get(configuration, 'min', DEFAULT_MIN_LENGTH_VALUE)
    const MinValueLengthSchema = z.string().min(minLengthValue)

    return (value: any): string | void => {
        const sanitisedValue = sanitiseValidatorValue(value)
        let validationResult: SafeParseReturnType<typeof value, string | void>

        if (configuration?.isRequired) {
            validationResult = MinValueLengthSchema.safeParse(sanitisedValue)
        } else {
            validationResult = asOptionalField(MinValueLengthSchema).safeParse(sanitisedValue)
        }

        if (configuration?.isRequired && isEmpty(sanitisedValue)) {
            return intl.formatMessage({ id: 'app.common.form.validation.required' })
        }

        if (!validationResult.success) {
            return intl.formatMessage(
                { id: 'app.common.form.validation.minLength' },
                {
                    minLengthValue
                }
            )
        }
    }
}
