import { If, Select } from '@/components/@misc'
import { CURRENCIES } from '@/constants'
import { anythingValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { CurrencyCode } from '@webapps/numeral-ui-core'
import { useField } from 'formik'
import { isArray } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CurrencyInputProps } from './CurrencyInput.types'

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
    name,
    value,
    customCurrencyCodes,
    highlightedCurrencyCodes,
    customLabelKey,
    customLabelValues,
    isRequired,
    isLabelDisplayed = true,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField<CurrencyCode>({
        name,
        value,
        validate: validator?.(intl, { isRequired })
    })
    const currencyCodes = useMemo<CurrencyCode[]>(
        () => customCurrencyCodes || Object.keys(CURRENCIES).sort(),
        [customCurrencyCodes]
    )
    const nonHighlightedCurrencyCodes = useMemo<CurrencyCode[]>(
        () => currencyCodes.filter((code) => !highlightedCurrencyCodes?.includes(code)),
        [currencyCodes, highlightedCurrencyCodes]
    )
    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.${name}.label`
        return intl.formatMessage({ id: labelKey }, customLabelValues)
    }, [name, customLabelKey, intl, customLabelValues])
    const placeholder = useMemo<string>(
        () => intl.formatMessage({ id: 'app.common.form.input.select.placeholder' }),
        [intl]
    )
    const options = useMemo(() => {
        const result: CurrencyCode[] = []

        if (isArray(highlightedCurrencyCodes)) {
            result.push(...highlightedCurrencyCodes)
        }

        if (isArray(nonHighlightedCurrencyCodes)) {
            result.push(...nonHighlightedCurrencyCodes)
        }
        return result
    }, [highlightedCurrencyCodes, nonHighlightedCurrencyCodes])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <If condition={isLabelDisplayed}>
                <FormLabel htmlFor={name}>
                    <FormattedMessage id={label} />
                </FormLabel>
            </If>
            <Select<CurrencyCode>
                {...inputProps}
                {...field}
                id={name}
                isInvalid={isInvalid}
                isRequired={isRequired}
                options={options}
                placeholder={placeholder}
                onChange={helpers.setValue}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
