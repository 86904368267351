import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { UserInfo } from '@webapps/numeral-ui-core'
import { differenceWith, fromPairs, isEqual, pick, some, toPairs } from 'lodash'
import { USER_INFO_MANDATORY_KEYS } from './IncompleteUserInfoModal.const'

export function getUserInfoUpdatedFields<T>(userInfo?: Partial<T>, submittedUserInfo?: Partial<T>) {
    return fromPairs(differenceWith(toPairs(submittedUserInfo), toPairs(userInfo), isEqual)) as T
}

export function isUserInfoIncomplete(userInfo?: UserInfo): boolean {
    return some(pick(userInfo, USER_INFO_MANDATORY_KEYS), (value) => value === EMPTY_CHAR_SYMBOL)
}
